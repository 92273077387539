import React from "react";
import first_home from "../../assets/services/first-home.jpg";
import second_home from "../../assets/services/second-house.jpg";
import investment from "../../assets/services/house-investment.jpg";
import tw from "twin.macro";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

const Main = tw.div`relative`;
const Container = tw.div`mx-auto max-w-2xl py-24 px-4 sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8`;
const HeadingSection = tw.div`mx-auto max-w-3xl text-center`;
const Heading = tw.h1`text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl`;
const HeadingDescription = tw.p`mt-4 text-gray-500`;
const SubheadingSection = tw.div`border-b-2 border-gray-200 pb-4`;
const Subheading = tw.h2`text-2xl tracking-tight text-gray-900`;
const TwoColumn = tw.div`grid grid-cols-1 gap-y-20 gap-x-8 lg:grid-cols-12 mt-16`;
const TextColumn = tw.div`lg:col-span-7`;
const ImageColumn = tw.div`lg:col-span-5`;
const DescriptionSection = tw.p`pt-6 space-y-2`;
const Description = tw.p`text-base tracking-tight text-gray-600`;
const DescriptionDGray = tw.h3`border-l-4 border-[#ed1c24] text-base tracking-tight text-gray-900`;
const Image = tw.img`h-full w-full object-cover object-center`;
const Link = tw.a`hover:text-[#ed1c24] underline decoration-solid underline-offset-2`;
const ApplyLink = tw.a`flex text-[#ed1c24] mt-10 font-light underline decoration-solid underline-offset-2`;

const HomeLoanCN = () => {
  return (
    <Main>
      <head>
        <meta
          name="description"
          content="想要拥有您梦想中的房子？我们可以帮助您获得适合您的预算和财务目标的合适的房屋贷款。"
        />
        <meta property="og:site_name" content="住房贷款 - 百家信贷" />
        <meta property="og:title" content="住房贷款 - 百家信贷" />
        <meta
          property="og:description"
          content="想要拥有您梦想中的房子？我们可以帮助您获得适合您的预算和财务目标的合适的房屋贷款。"
        />
        <meta property="og:locale" content="zh-CN" />
        <meta name="twitter:title" content="住房贷款 - 百家信贷" />
        <meta
          name="twitter:description"
          content="想要拥有您梦想中的房子？我们可以帮助您获得适合您的预算和财务目标的合适的房屋贷款。"
        />
        <title>住房贷款 - 百家信贷</title>
        <link rel="canonical" href="https://bicare.co.nz/home-loans" />
      </head>
      <Container>
        <HeadingSection>
          <Heading>住房贷款</Heading>
          <HeadingDescription>
            想要拥有您梦想中的房子？我们可以帮助您获得适合您的预算和财务目标的合适的房屋贷款。我们专业的团队可以指导您完成整个过程，并确保您从头到尾都拥有无忧的体验。
          </HeadingDescription>
        </HeadingSection>
        <TwoColumn>
          {/*text*/}
          <TextColumn>
            <SubheadingSection>
              <Subheading>首次购房贷款</Subheading>
            </SubheadingSection>
            <DescriptionSection>
              <Description>
                恭喜您！
                终于来到了人生一个重要的阶段！从此不再需要面对房东和搬家。
                无论您现在是单身贵族，热恋中的情侣或者夫妻，还是幸福的小家庭。我们相信，您的人生即将展开新的篇章。
              </Description>
              <Description>拥有您的首套住房其实很简单!</Description>
              <Description>
                首先，您需要在新西兰拥有稳定的收入。工作收入，生意收入都可以。
              </Description>
              <Description>
                其次，您需要准备至少10%的购房款作为首付。当然，如果您可以将首付款提高到20%，那么您将会获得超低特惠利息以及大额现金奖励！为什么不呢？
              </Description>
              <Description>
                同时，新西兰政府也提供各种协助来帮助您拥有自己的首套租房。他们分别是：
              </Description>
              <DescriptionDGray>&ensp;First Home Loan</DescriptionDGray>
              <Description>您只需要 5% 的首付，即可拥有一套房产！</Description>
              <Description>您只需回答以下三个问题：</Description>
              <Description>您是新西兰的公民或是永久居民吗？</Description>
              <Description>您是首次购房者吗？</Description>
              <Description>
                您的税前收入不超过 $95000.00，
                或者家庭税前收入不超过$150000.00吗？
              </Description>
              <Description>
                如果都是 “Yes”,恭喜您，您符合要求，请尽管
                <Link href="/contact-us">联络我们</Link>。
              </Description>
              <DescriptionDGray>&ensp;First Home Partner</DescriptionDGray>
              <Description>
                您可能觉得first home
                loan的利息太高了。那么，为什么不考虑一下first home partner呢？
              </Description>
              <Description>
                同样只需要5%的首付。可以享受更便宜的利息。还贷毫无压力！
              </Description>
              <Description>
                房屋产权与政府共享。当您财务改善时，可以随时收回全部产权！
              </Description>
              <Description>
                我们乐意<Link href="/contact-us">全程协助</Link>！
              </Description>
              <DescriptionDGray>&ensp;First Home Grant</DescriptionDGray>
              <Description>
                买房缺首付?政府来帮助!最高$20000.00的补助金。不计息，不用还！
              </Description>
              <Description>是不是很棒？你只需满足以下三个条件：</Description>
              <Description>新西兰公民或者居民</Description>
              <Description>您参与了新西兰养老金计划（KiwiSaver）</Description>
              <Description>您过去坚持参加养老金储蓄从未终止</Description>
              <Description>
                如果答案都是 “是”，恭喜您，您符合要求，
                <Link href="/contact-us">联系我们</Link>
                ，我们将全力协助您申请贷款。
              </Description>
              <Description>
                百家温馨提醒您，当成为房主后，您也许增加以下这些可能您之前从未考虑的必要开销，分别是：
              </Description>
              <Description>水电网煤气费，房屋保险，房产地税。</Description>
              <Description>
                因此，我们建议您
                <Link href="/contact-us">联系百家信贷评估员</Link>
                做一次全方位评估，做到有备无患！
              </Description>
            </DescriptionSection>
          </TextColumn>
          {/*image*/}
          <ImageColumn>
            <div className="aspect-w-1 aspect-h-2 overflow-hidden rounded-lg">
              <Image
                src={first_home}
                alt="首套房贷款，首次购房，低首付购房，房屋贷款，贷款经理， first home loan, first home partner, first home grant, first home buyer"
                className="h-full w-full object-cover object-center"
                loading="lazy"
                title="首套房贷款，首次购房，低首付购房，房屋贷款，贷款经理"
              />
            </div>
          </ImageColumn>
        </TwoColumn>
        <TwoColumn>
          {/*image*/}
          <ImageColumn>
            <div className="aspect-w-4 aspect-h-3 overflow-hidden rounded-lg bg-gray-100">
              <img
                src={second_home}
                alt="二套房贷款，二套房，自住房升级，学区房，投资房，房屋贷款，贷款经理， second home loan, second home buyer"
                className="h-full w-full object-cover object-center"
                loading="lazy"
                title="二套房贷款，二套房，自住房升级，学区房，投资房，房屋贷款，贷款经理"
              />
            </div>
          </ImageColumn>
          {/*text*/}
          <TextColumn>
            <SubheadingSection>
              <Subheading>自住房升级贷款</Subheading>
            </SubheadingSection>
            <DescriptionSection>
              <Description>
                上班，购物，生活不方便？子女上学需要学区？目前的房子太旧？太老？
              </Description>
              <Description>
                是时候改善自己的居住环境了！多年打拼，你值得拥有！
              </Description>
              <Description>让我们帮你开展合理的规划吧！</Description>
              <Description>
                首先，您的收入是否稳定？请联系我们做一个快速简算。只需要10分钟，我们会告诉您大致的贷款金额。
              </Description>
              <Description>
                其次，购房首付款准备好了么？是您的储蓄？赠与？还是您目前房子的equity?
              </Description>
              <Description>另外，您是否需要卖掉目前的居所？</Description>
              <Description>
                最后，您可能对下一栋房产有些迷茫。地点，类型，价格都不太清楚。
              </Description>
              <Description>
                没关系，您只需要<Link href="contact-us">将地址发给我们</Link>
                ，我们将会把一份详细的房产报告可市场估价会发送至您的邮箱！
              </Description>
              <Description>这一切，作为百家的客户，都是免费的！</Description>
            </DescriptionSection>
          </TextColumn>
        </TwoColumn>
        <TwoColumn>
          {/*text*/}
          <TextColumn>
            <SubheadingSection>
              <Subheading>投资房贷款</Subheading>
            </SubheadingSection>
            <DescriptionSection>
              <Description>
                您是一位房产投资达人么？是时候让您的资产为您赚钱了！
              </Description>
              <Description>
                房产投资是每个人人生中重要的投资组成部分。他影响着您日常财务中的方方面面。比如税务抵扣，资产配置，养老计划以及现金流。
              </Description>
              <Description>
                然而购买到一套满意的投资房的确非常难。您需要考虑的不只有价格和地点，还有很多其他因素需要您留意，比如：该地区的空租周期，未来的维护费用，房型和结构和未来的升值空间。
              </Description>
              <Description>
                在新西兰，购买投资房贷款比例只有65%，如何花最少的钱得到最高的回报，这无时无刻不在考验着投资人的智慧。
              </Description>
              <Description>百家信贷可以让这一切不在纷乱。</Description>
              <Description>
                我们可以提供一份详细的报告可以在第一时间为您理清头绪，我们长期积累的强大的资源网也为您提供：法律，保险，会计，房屋管理和房屋检测等等一切系列周边需求的推荐服务。
              </Description>
              <Description>
                最重要的是， 你不需要再为贷款办理而头疼！
              </Description>
              <Description>
                <Link href="contact-us">把一切交给我们</Link>
                ，我们将竭诚为您服务！
              </Description>
            </DescriptionSection>
          </TextColumn>
          {/*image*/}
          <ImageColumn>
            <div className="aspect-w-10 aspect-h-9 overflow-hidden rounded-lg bg-gray-100">
              <img
                src={investment}
                alt="投资房，投资房贷款，投资房评估，投资房经理，投资房贷款经理，"
                className="h-full w-full object-cover object-center"
                loading="lazy"
                title="投资房，投资房贷款，投资房评估，投资房经理，投资房贷款经理"
              />
            </div>
          </ImageColumn>
        </TwoColumn>
        <ApplyLink href="/application-form">
          点击申请住房贷款
          <ChevronRightIcon className="w-6 h6 text-[#ed1c24]" />
        </ApplyLink>
      </Container>
    </Main>
  );
};
export default HomeLoanCN;
