import React from "react";
import first_home from "../../assets/services/first-home.jpg";
import first_home_1 from "../../assets/services/first-home1.jpg";
import second_home from "../../assets/services/second-house.jpg";
import investment from "../../assets/services/house-investment.jpg";
import tw from "twin.macro";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

const Main = tw.div`relative`;
const Container = tw.div`mx-auto max-w-2xl py-24 px-4 sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8`;
const HeadingSection = tw.div`mx-auto max-w-3xl text-center`;
const Heading = tw.h1`text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl`;
const HeadingDescription = tw.p`mt-4 text-gray-500`;
const SubheadingSection = tw.div`border-b-2 border-gray-200 pb-4`;
const Subheading = tw.h2`text-2xl tracking-tight text-gray-900`;
const TwoColumn = tw.div`grid grid-cols-1 gap-y-20 gap-x-8 lg:grid-cols-12 mt-16`;
const TextColumn = tw.div`lg:col-span-7`;
const ImageColumn = tw.div`lg:col-span-5`;
const DescriptionSection = tw.p`pt-6 space-y-2`;
const Description = tw.p`text-base tracking-tight text-gray-600`;
const DescriptionDGray = tw.h3`border-l-4 border-[#ed1c24] text-base tracking-tight text-gray-900`;
const Image = tw.img`h-full w-full object-cover object-center`;
const Link = tw.a`hover:text-[#ed1c24] underline decoration-solid underline-offset-2`;
const ApplyLink = tw.a`flex text-[#ed1c24] mt-10 font-light underline decoration-solid underline-offset-2`;
const HomeLoanCN = () => {
  return (
    <Main>
      <head>
        <meta
          name="description"
          content="Looking to buy your dream home? We can help you secure the right home loan that fits your budget and financial goals. "
        />
        <meta property="og:site_name" content="Home-Loans - Bicare Finance" />
        <meta property="og:title" content="Home-Loans - Bicare Finance" />
        <meta
          property="og:description"
          content="Looking to buy your dream home? We can help you secure the right home loan that fits your budget and financial goals. "
        />
        <meta property="og:locale" content="en-NZ" />
        <meta name="twitter:title" content="Home-Loans - Bicare Finance" />
        <meta
          name="twitter:description"
          content="Looking to buy your dream home? We can help you secure the right home loan that fits your budget and financial goals. "
        />
        <title>Home-Loans - Bicare Finance</title>
        <link rel="canonical" href="https://bicare.co.nz/home-loans" />
      </head>
      <Container>
        <HeadingSection>
          <Heading>Home Loans</Heading>
          <HeadingDescription>
            Looking to buy your dream home? We can help you secure the right
            home loan that fits your budget and financial goals. Our team of
            experts can guide you through the entire process and ensure that you
            have a hassle-free experience from start to finish.
          </HeadingDescription>
        </HeadingSection>
        <TwoColumn>
          {/*text*/}
          <TextColumn>
            <SubheadingSection>
              <Subheading>First Home Buyer</Subheading>
            </SubheadingSection>
            <DescriptionSection>
              <Description>
                Congratulations! You are about to take the first step towards.
                No more moving and seeking, you can finally have your own home!
                Whether you are a single person, a couple in love, or a happy
                small family, we believe that a new chapter in your life is
                about to begin. We are here to help you make this dream come
                true.
              </Description>
              <Description>
                It's actually very simple to own your first home!{" "}
              </Description>
              <Description>
                First, you need to proof a stable income in New Zealand.Your
                income from work or business will do.
              </Description>
              <Description>
                Secondly, you need to prepare at least 10% of the purchase price
                as a down payment.Of course, if you can increase the down
                payment to 20%, you will get super low interest rates and large
                cash rewards! Why not?
              </Description>
              <Description>
                In the meantime,the New Zealand government provides various
                assistance to help you own your first rental property. They are:
              </Description>
              <DescriptionDGray>&ensp;First Home Loan</DescriptionDGray>
              <Description>
                You only need a 5% down payment to own a property!
              </Description>
              <Description>
                You just need to answer the following three questions:
              </Description>
              <Description>
                Are you a citizen or permanent resident of New Zealand?
              </Description>
              <Description>Are you a first home buyer?</Description>
              <Description>
                Is your before-tax income less than $95,000.00 or your
                household's before-tax income less than $150,000.00?
              </Description>
              <Description>
                If the answer is "yes", congratulations! You are eligible for a
                first home loan. We are happy to help you apply for a loan.
                Please <Link href="/contact-us">contact us</Link> for more
                information.
              </Description>
              <DescriptionDGray>&ensp;First Home Partner</DescriptionDGray>
              <Description>
                You may think that the interest rate on the first home loan is
                too high. So why not consider the first home partner?
              </Description>
              <Description>
                You still only need a 5% down payment. You can enjoy lower
                interest rates and repay the loan without any pressure!
              </Description>
              <Description>
                The property rights are shared with the government. When your
                finances improve, you can retrieve all the property rights at
                any time!
              </Description>
              <Description>
                We are happy to <Link href="/contact-us">assist you</Link>{" "}
                throughout the process!
              </Description>
              <DescriptionDGray>&ensp;First Home Grant</DescriptionDGray>
              <Description>
                Need help with the down payment? The government is here to help!
                You can receive up to $20,000.00 in subsidy, interest-free and
                repayment-free!
              </Description>
              <Description>
                Isn't it great? You only need to meet the following three
                conditions:
              </Description>
              <Description>New Zealand citizen or resident.</Description>
              <Description>You have participated in the KiwiSaver.</Description>
              <Description>
                You have never terminated your participation in the retirement
                savings plan in the past.
              </Description>
              <Description>
                If the answer is "Yes" to all, congratulations, you are
                eligible. Please <Link href="/contact-us">contact us</Link>, and
                we will do our best to assist you in applying for the loan.
              </Description>
              <Description>
                We remind you that when you become a homeowner, you may have to
                incur some necessary expenses that you may not have considered
                before, including:
              </Description>
              <Description>
                water, electricity, gas bills, home insurance, and property
                rates.
              </Description>
              <Description>
                Therefore, we recommend that you{" "}
                <Link href="/contact-us">contact</Link> a Bicare credit evaluato
                to conduct a comprehensive evaluation to be well-prepared!
              </Description>
            </DescriptionSection>
          </TextColumn>
          {/*image*/}
          <ImageColumn>
            <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg">
              <Image
                src={first_home}
                alt="first home loan, first home partner, first home grant, first home buyer, first home buyer loan"
                className="h-full w-full object-cover object-center"
                loading="lazy"
                title="first home loan"
              />
            </div>
            <div className="aspect-w-3 aspect-h-5 overflow-hidden rounded-lg mt-8">
              <Image
                src={first_home_1}
                alt="first home loan, first home partner, first home grant, first home buyer, first home buyer loan，auckland"
                className="h-full w-full object-cover object-center"
                loading="lazy"
                title="first home buyer"
              />
            </div>
          </ImageColumn>
        </TwoColumn>
        <TwoColumn>
          {/*image*/}
          <ImageColumn>
            <div className="aspect-w-10 aspect-h-9 overflow-hidden rounded-lg bg-gray-100">
              <img
                src={second_home}
                alt="residential home loan,upsizing,school zone"
                className="h-full w-full object-cover object-center"
                loading="lazy"
                title="residential home loan,upsizing,school zone"
              />
            </div>
          </ImageColumn>
          {/*text*/}
          <TextColumn>
            <SubheadingSection>
              <Subheading>Thinking About Upsizing?</Subheading>
            </SubheadingSection>
            <DescriptionSection>
              <Description>
                Inconvenient for work, shopping, or daily life? Need to live in
                a certain school district for your children? Current house too
                old or outdated?
              </Description>
              <Description>
                It's time to improve your living environment! After years of
                hard work, you deserve it!
              </Description>
              <Description>Let us help you with a reasonable plan!</Description>
              <Description>
                First, is your income stable? Contact us for a quick
                calculation. In just 10 minutes, we will give you an estimate of
                the loan amount.。
              </Description>
              <Description>
                Second, have you prepared the down payment for the house
                purchase? Is it from your savings, a gift, or the equity from
                your current house?
              </Description>
              <Description>
                Besides, do you need to sell your current residence?
              </Description>
              <Description>
                Lastly, you may be unsure about the location, type, and price of
                your next property.
              </Description>
              <Description>
                No problem, just{" "}
                <Link href="contact-us">send us the address</Link> and we will
                send you a detailed property report and market valuation to your
                email!
              </Description>
              <Description>
                All of this, as a customer of Bicare, is free of charge!！
              </Description>
            </DescriptionSection>
          </TextColumn>
        </TwoColumn>
        <TwoColumn>
          {/*text*/}
          <TextColumn>
            <SubheadingSection>
              <Subheading>Investment Property Loan</Subheading>
            </SubheadingSection>
            <DescriptionSection>
              <Description>
                Are you a real estate investment expert? It's time to make your
                assets work for you!
              </Description>
              <Description>
                Real estate investment is an important part of everyone's
                investment portfolio. It affects all aspects of your daily
                finances, such as tax deductions, asset allocation, retirement
                planning, and cash flow.
              </Description>
              <Description>
                However, buying a satisfactory investment property is indeed
                very difficult. You need to consider not only the price and
                location but also many other factors, such as the vacancy period
                in the area, future maintenance costs, housing type and
                structure, and future appreciation potential.
              </Description>
              <Description>
                In New Zealand, the loan-to-value ratio for purchasing
                investment properties is only 65%. How to get the highest return
                with the least amount of money is always testing the wisdom of
                investors.
              </Description>
              <Description>Bicare can help you all with this!</Description>
              <Description>
                We can provide a detailed report to help you sort things out as
                soon as possible. Our powerful resource network accumulated over
                the years can also provide you with recommended services for all
                related needs, such as legal, insurance, accounting, property
                management, and property inspections.
              </Description>
              <Description>
                Most importantly, you don't have to worry about applying for a
                loan anymore! We will help you with the entire process, from the
                initial application to the final approval.
              </Description>
              <Description>
                <Link href="contact-us">Leave everything to us</Link>, and we
                will do our best to help you achieve your goals!
              </Description>
            </DescriptionSection>
          </TextColumn>
          {/*image*/}
          <ImageColumn>
            <div className="aspect-w-4 aspect-h-5 overflow-hidden rounded-lg bg-gray-100">
              <img
                src={investment}
                alt="investment,investment property,investment property loan"
                className="h-full w-full object-cover object-center"
                loading="lazy"
                title="investment,investment property,investment property loan"
              />
            </div>
          </ImageColumn>
        </TwoColumn>
        <ApplyLink href="/application-form">
          Apply home loan now
          <ChevronRightIcon className="w-6 h6 text-[#ed1c24]" />
        </ApplyLink>
      </Container>
    </Main>
  );
};
export default HomeLoanCN;
