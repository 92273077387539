import React from "react";
import asset from "../../assets/services/asset.jpg";
import tw from "twin.macro";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

const Main = tw.div`relative`;
const Container = tw.div`mx-auto max-w-2xl py-24 px-4 sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8`;
const HeadingSection = tw.div`mx-auto max-w-3xl text-center`;
const Heading = tw.h1`text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl`;
const SubheadingSection = tw.div`border-b-2 border-gray-200 pb-4`;
const Subheading = tw.h2`text-lg tracking-tight text-gray-900`;
const TwoColumn = tw.div`grid grid-cols-1 gap-y-20 gap-x-8 lg:grid-cols-12 mt-16`;
const TextColumn = tw.div`lg:col-span-7`;
const ImageColumn = tw.div`lg:col-span-5`;
const DescriptionSection = tw.p`pt-6 space-y-2`;
const Description = tw.p`text-base tracking-tight text-gray-600`;
const Image = tw.img`h-full w-full object-cover object-center`;
const Link = tw.a`hover:text-[#ed1c24] underline decoration-solid underline-offset-2`;
const ApplyLink = tw.a`flex text-[#ed1c24] mt-10 font-light underline decoration-solid underline-offset-2`;
const AssetFinanceCN = () => {
  return (
    <Main>
      <head>
        <meta
          name="description"
          content="您需要购买特种设备吗？我们的专业团队将为您提供最合适的设备贷款方案，帮助您实现设备采购的愿望。"
        />
        <meta property="og:site_name" content="资产融资 - 百家信贷" />
        <meta property="og:title" content="资产融资 - 百家信贷" />
        <meta
          property="og:description"
          content="您需要购买特种设备吗？我们的专业团队将为您提供最合适的设备贷款方案，帮助您实现设备采购的愿望。"
        />
        <meta property="og:locale" content="zh-CN" />
        <meta name="twitter:title" content="资产融资 - 百家信贷" />
        <meta
          name="twitter:description"
          content="您需要购买特种设备吗？我们的专业团队将为您提供最合适的设备贷款方案，帮助您实现设备采购的愿望。"
        />
        <title>资产融资 - 百家信贷</title>
        <link rel="canonical" href="https://bicare.co.nz/asset-finance" />
      </head>
      <Container>
        <HeadingSection>
          <Heading>资产融资</Heading>
        </HeadingSection>
        <TwoColumn>
          {/*image*/}
          <ImageColumn>
            <div className="aspect-w-5 aspect-h-4 overflow-hidden rounded-lg">
              <Image
                src={asset}
                alt="特种设备贷款，挖掘机，建筑工地设备，贷款经理，奥克兰，新西兰"
                className="h-full w-full object-cover object-center"
                loading="lazy"
                title="特种设备贷款"
              />
            </div>
          </ImageColumn>
          {/*text*/}
          <TextColumn>
            <SubheadingSection>
              <Subheading>您需要购买特种设备吗？</Subheading>
            </SubheadingSection>
            <DescriptionSection>
              <Description>
                特种设备对于许多行业来说是必不可少的。在房地产高速发展的时代，工地上离不开各式各样的特种工程车辆，这些大家伙会让您的效率得到大幅提升，
                您也可以通过他们为您赚钱。
              </Description>
              <Description>
                除了建筑和房地产行业，还有许多其他行业需要使用特种设备，例如采矿、制造、农业和物流等领域。这些设备可以帮助企业更高效地完成工作，并提高生产效率和质量。
              </Description>
              <Description>
                我们建议您先进行一些调查和研究，确定您所需设备的类型、品牌、规格和价格范围等方面的要求。一旦您了解了这些信息，您可以随时
                <Link href="/contact-us">联系我们</Link>
                ，我们的专业团队将为您提供最合适的设备贷款方案，帮助您实现设备采购的愿望。
              </Description>
              <Description>
                过去的几年中， 百家信贷为客户成功申请到了大量设备贷款，
                包括：卡车，
                挖机，吊车，水泥罐车以及巴士。品牌覆盖：Hino，SCANIA，MAN，Komatsu，Volvo
                等等。
              </Description>
              <Description>
                我们的贷款利率和还款条件都非常灵活，
                还款周期可拉伸至5年。新车贷款比例最高可达100%，能够满足您不同的需求。
              </Description>
            </DescriptionSection>
            <ApplyLink href="/application-form">
              点击申请资产融资
              <ChevronRightIcon className="w-6 h6 text-[#ed1c24]" />
            </ApplyLink>
          </TextColumn>
        </TwoColumn>
      </Container>
    </Main>
  );
};
export default AssetFinanceCN;
