import React, { useEffect } from "react";
import tw from "twin.macro";
import {
  HomeIcon,
  WrenchScrewdriverIcon,
  BuildingOffice2Icon,
  BanknotesIcon,
  // PuzzlePieceIcon,
  ArrowSmallRightIcon,
  ChatBubbleBottomCenterTextIcon,
} from "@heroicons/react/24/outline";

import AOS from "aos";
import "aos/dist/aos.css";
const Main = tw.div`relative bg-slate-50 mt-20`;
const MainContainer = tw.div`max-w-screen-xl mx-auto py-20`;
const HeadingContainer = tw.div`mb-4 lg:mb-12`;
const Heading = tw.h1`text-2xl lg:text-4xl font-bold text-center text-gray-900`;
const Subheading = tw.p`px-2 mt-12 text-base md:text-base lg:text-lg text-center text-gray-700 font-light tracking-wider`;
const ServiceContainer = tw.div`flex flex-wrap justify-center grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-10 lg:gap-10 md:gap-8 sm:gap-2`;
const Box = tw.div`max-w-sm m-4 bg-white rounded-3xl shadow-md py-8 px-6 border border-gray-200`;
const ServiceContent = tw.div`pt-5`;
const ServiceTitle = tw.h2`text-xl lg:text-xl mt-4 font-semibold tracking-wider text-gray-900`;
const ServiceDescription = tw.p`mt-4 tracking-wider leading-8 text-base text-gray-700`;
const ServiceLink = tw.div`flex text-[#ed1c24] mt-10 font-light underline`;
const HighlightedText = tw.span`text-[#ed1c24]`;

const service = [
  {
    title: "住房贷款",
    description:
      "通过我们量身定制的房屋贷款解决方案，获得您梦寐以求的家。无论您是首次购房者、寻求重新融资或者正在寻找您的永久居所，我们专业的贷款团队可以帮助您找到最适合您需求的贷款。",
    url: "/home-loans",
    icon: HomeIcon,
  },
  {
    title: "商业物业贷款",
    description:
      "希望将您的业务提升到一个新的层次吗？我们的商业贷款解决方案可以帮助您实现目标。我们提供竞争力强的利率、灵活的条件和个性化的服务，以帮助您实现成功。",
    url: "/commercial-loans",
    icon: BuildingOffice2Icon,
  },
  {
    title: "建房开发融资",
    description:
      "无论您是想要建造单栋住宅，联排还是策划阶段性的分期分期项目，我们经验丰富的专业团队都可以全程协助，帮助您量身打造满足您特定需求的贷款产品，让您把握良机，不再为资金而头疼。",
    url: "/construction-loans",
    icon: WrenchScrewdriverIcon,
  },
  {
    title: "资产融资",
    description:
      "通过我们的资产融资解决方案，您可以获得所需资金，投资于新设备、车辆或其他资产，助您成功。",
    url: "/asset-finance",
    icon: BanknotesIcon,
  },
  // {
  //   title: "KiwiSaver投资养老金",
  //   description:
  //     "寻找更明智的退休储蓄方式？准备掌控您的财务未来？与我们的KiwiSaver专家交谈，规划您的未来。",
  //   url: "/kiwisaver",
  //   icon: PuzzlePieceIcon,
  // },
  {
    title: "专业房产理财建议",
    description:
      "通过与我们的金融专家团队交流，探索投资于未来的力量。今天就与我们交谈，让我们帮助您规划更加美好的明天。",
    url: "/financial-advice",
    icon: ChatBubbleBottomCenterTextIcon,
  },
];

const ServicesCN = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <Main>
      <MainContainer>
        <HeadingContainer>
          <Heading>
            百家信贷，百姓身边的<HighlightedText>贷款专家</HighlightedText>
          </Heading>
          <Subheading>
            无论您需要房屋贷款、商业贷款、建房开发融资、资产融资还是再固定利息,
            我们都能快速为您理清头绪，找到方向！
          </Subheading>
        </HeadingContainer>
        <ServiceContainer>
          {service.map((data, index) => (
            <Box key={index} data-aos="fade-up">
              <div className="flex gap-4">
                <data.icon className="w-16 p-4 bg-[#ed1c24] text-white rounded-full" />
                <ServiceTitle>{data.title}</ServiceTitle>
              </div>
              <ServiceContent>
                <ServiceDescription>{data.description}</ServiceDescription>
              </ServiceContent>
              <ServiceLink>
                <a href={data.url}>了解更多</a>
                <ArrowSmallRightIcon className="w-5 ml-2" />
              </ServiceLink>
            </Box>
          ))}
        </ServiceContainer>
      </MainContainer>
    </Main>
  );
};
export default ServicesCN;
