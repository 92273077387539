import React from "react";
import error from "../assets/error/multitasking.json";
import Lottie from "lottie-react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import {
  ChatBubbleLeftEllipsisIcon,
  HomeIcon,
  CalculatorIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";

const links = [
  {
    title: "Home Loans",
    description:
      "Want to own your dream home? We can help you get the right home loan to suit your budget and financial goals.",
    icon: HomeIcon,
    url: "/home-loans",
  },
  {
    title: "Construction Loans",
    description:
      "Whether you're looking to build a single residential house or master planning a staged subdivision, our team of experienced professionals can help you find the right mortgage product to meet your specific needs",
    icon: CurrencyDollarIcon,
    url: "/apply",
  },
  {
    title: "PAYE Calculators",
    description:
      "Use our financial tools to calculate your take home pay, and work out your financial situations.",
    icon: CalculatorIcon,
    url: "/paye-calculator",
  },
  {
    title: "Contact Us",
    description:
      "We are here to help you. Chat with us today and let us help you plan for a brighter tomorrow.",
    icon: ChatBubbleLeftEllipsisIcon,
    url: "/contact-us",
  },
];

export default function Error() {
  return (
    <div className="bg-white mx-auto w-full max-w-7xl px-6 lg:px-8">
      {/*headers*/}
      <div className="mx-auto max-w-xl py-8">
        <div className="text-center">
          <p className="text-3xl font-semibold text-[#ed1c24] mt-24">404</p>
          <h1 className="mt-4 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            This page does not exist.
          </h1>
          <p className="mt-4 text-lg font-light text-gray-500">
            The page you are looking for could not be found.
          </p>
        </div>
      </div>
      <div className="mb-6 grid md:grid-cols-12 justify-center">
        <div className="mt-12 col-span-7">
          <h2 className="text-lg text-gray-700 font-normal">Popular pages</h2>
          <div
            role="list"
            className="mt-4 divide-y divide-gray-200 border-t border-b border-gray-200"
          >
            {links.map((link, linkIdx) => (
              <div
                key={linkIdx}
                className="relative flex items-start space-x-4 py-6"
              >
                <div className="flex-shrink-0">
                  <span className="flex h-12 w-12 items-center justify-center rounded-lg bg-[#fff1f2]">
                    <link.icon
                      className="h-6 w-6 text-[#ed1c24]"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="min-w-0 flex-1">
                  <h3 className="text-base font-normal text-gray-900 hover:text-[#ed1c24] hover:underline underline-offset-2">
                    <span className="rounded-sm">
                      <a href={link.url} className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true" />
                        {link.title}
                      </a>
                    </span>
                  </h3>
                  <p className="text-base font-light text-gray-500">
                    {link.description}
                  </p>
                </div>
                <div className="flex-shrink-0 self-center">
                  <ChevronRightIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="mt-8">
            <a
              href="/"
              className="text-base font-light text-gray-900 hover:text-[#ed1c24] text-decoration: underline"
            >
              Or go back home
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </div>
        </div>
        <Lottie
          className="col-span-5"
          animationData={error}
          loop={true}
          height={300}
          mwidth={300}
        />
      </div>
    </div>
  );
}
