import React, { useState } from "react";
import tw from "twin.macro";
import Lottie from "lottie-react";
import time from "../../assets/calculator/time.json";
import borrow from "../../assets/calculator/borrowing.jpg";
import paye from "../../assets/calculator/paye.jpg";
import rates from "../../assets/calculator/rates.jpg";

const Main = tw.div`relative`;
const Container = tw.div`mx-auto max-w-2xl py-24 px-4 sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8`;
const HeadingSection1 = tw.div`max-w-3xl`;
const HeadingSection2 = tw.div`max-w-3xl mt-4`;
const Heading = tw.h1`text-3xl font-bold tracking-tight text-gray-800`;
const Heading2 = tw.h2`flex flex-col text-3xl font-bold text-gray-800 mb-2`;
const HeadingDescription = tw.p`mt-4 text-gray-500`;
const TwoColumn = tw.div`grid grid-cols-1 gap-y-20 gap-x-8 lg:grid-cols-3`;
const TextColumn = tw.div`lg:col-span-2`;
const ImageColumn = tw.div`lg:col-span-1 h-full w-full object-cover object-center`;
const CalculatorSection = tw.div`mx-auto flex flex-col md:flex-row bg-gray-100 mt-8 shadow-xl rounded-2xl`;
const Calculator = tw.div`cursor-pointer flex flex-col border-r border-gray-200 md:w-2/5`;
const CalculatorRow1 = tw.div`relative mt-10 ml-10 mr-10`;
const CalculatorRow2 = tw.div`relative mt-5 ml-10 mr-10`;
const Label = tw.label`text-base text-gray-500`;
const Input = tw.input`w-full rounded-md bg-white rounded border border-gray-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out`;
const Button = tw.button`m-10 text-white bg-[#ed1c24] border-0 py-2 px-6  focus:outline-none rounded-lg text-base transform active:scale-x-90 active:scale-y-90 transition-transform`;
const ValueSection = tw.div`bg-white rounded-r-2xl md:w-3/5`;
const ValueContent = tw.div`flex flex-col justify-center m-5`;
const ValueHeading = tw.div`flex border-b justify-around bg-gray-100 rounded-tr-2xl text-gray-500 h-14 text-center`;
const ValueRow = tw.div`mt-3 mb-12 text-center text-4xl text-gray-800`;
const Value = tw.div`text-center text-8xl text-gray-800 decoration-2`;
const ValueDescription = tw.p`text-xs text-left font-light italic text-gray-500 mt-12 m-5`;
const MoreCalculators = tw.div`mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8`;
const MoreCalculatorsContainer = tw.div`aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-2xl shadow-xl bg-gray-100 group-hover:opacity-75 lg:aspect-none lg:h-80`;
const MoreCalculatorsImage = tw.img`object-cover object-center lg:h-full lg:w-full`;
const H3 = tw.h3`mt-4 text-base text-gray-700 text-center`;
const RepaymentCalculator = () => {
  const [balance, setBalance] = useState("");
  const [rate, setRate] = useState("");
  const [term, setTerm] = useState("");
  const [paymentM, setPaymentM] = useState("$0");
  const [paymentF, setPaymentF] = useState("$0");
  const [paymentW, setPaymentW] = useState("$0");
  const calculateM = (balance, rate, term) => {
    const n = term * 12;
    const r = rate / 100 / 12;
    const numerator = r * (1 + r) ** n;
    const denominator = (1 + r) ** n - 1;
    return parseFloat(balance * (numerator / denominator)).toFixed(0);
  };
  const calculateF = (balance, rate, term) => {
    const n = term * 26;
    const r = rate / 100 / 26;
    const numerator = r * (1 + r) ** n;
    const denominator = (1 + r) ** n - 1;
    return parseFloat(balance * (numerator / denominator)).toFixed(0);
  };
  const calculateW = (balance, rate, term) => {
    const n = term * 52;
    const r = rate / 100 / 52;
    const numerator = r * (1 + r) ** n;
    const denominator = (1 + r) ** n - 1;
    return parseFloat(balance * (numerator / denominator)).toFixed(0);
  };
  const handleClick = (e) => {
    e.preventDefault();

    const paymentW = calculateW(balance, rate, term);
    setPaymentW(`$${paymentW}`);

    const paymentF = calculateF(balance, rate, term);
    setPaymentF(`$${paymentF}`);

    const paymentM = calculateM(balance, rate, term);
    setPaymentM(`$${paymentM}`);
  };

  const data = [
    {
      label: "每周",
      value: "Weekly",
      desc: `${paymentW}`,
    },
    {
      label: "每两周",
      value: "Fortnightly",
      desc: `${paymentF}`,
    },
    {
      label: "每月",
      value: "Monthly",
      desc: `${paymentM}`,
    },
  ];

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const calculators = [
    {
      id: 1,
      name: "贷款计算器",
      href: "/borrowing-calculator",
      imageSrc: borrow,
      imageAlt: "借款计算器，我可以借多少钱？奥克兰抵押经纪人",
    },
    {
      id: 2,
      name: "PAYE新西兰工资税计算器",
      href: "/paye-calculator",
      imageSrc: paye,
      imageAlt: "我的税后工资是多少？奥克兰抵押经纪人",
    },
    {
      id: 3,
      name: "各银行贷款利息比较",
      href: "/rates-compare",
      imageSrc: rates,
      imageAlt: "比较利率，比较利率，奥克兰抵押经纪人",
    },
  ];

  return (
    <Main>
      <head>
        <meta
          name="description"
          content="估算您的等额本息按揭贷款可能需要支付的金额。"
        />
        <meta
          property="og:site_name"
          content="还款repayment计算器 - 百家信贷"
        />
        <meta property="og:title" content="还款repayment计算器 - 百家信贷" />
        <meta
          property="og:description"
          content="估算您的等额本息按揭贷款可能需要支付的金额。"
        />
        <meta property="og:locale" content="zh-CN" />
        <meta name="twitter:title" content="还款repayment计算器 - 百家信贷" />
        <meta
          name="twitter:description"
          content="估算您的等额本息按揭贷款可能需要支付的金额。"
        />
        <title>还款计算器 - 百家信贷</title>
        <link
          rel="canonical"
          href="https://bicare.co.nz/repayment-calculator"
        />
      </head>
      <Container>
        <HeadingSection1>
          <Heading>金融计算器 - 还款计算器</Heading>
          <HeadingDescription>
            估算您的等额本息按揭贷款可能需要支付的金额。
          </HeadingDescription>
        </HeadingSection1>
        <TwoColumn>
          <TextColumn>
            {/*calculator*/}
            <CalculatorSection>
              <Calculator>
                <CalculatorRow1>
                  <Label>贷款总金额：</Label>
                  <Input
                    type="number"
                    value={balance}
                    onChange={(e) => setBalance(e.target.value)}
                    min="0"
                    placeholder="$"
                  ></Input>
                </CalculatorRow1>
                <CalculatorRow2>
                  <Label>贷款利率：</Label>
                  <Input
                    value={rate}
                    onChange={(e) => setRate(e.target.value)}
                    type="number"
                    min="0"
                    step="0.01"
                    placeholder="%"
                  ></Input>
                </CalculatorRow2>
                <CalculatorRow2>
                  <Label>贷款期限（年）：</Label>
                  <Input
                    value={term}
                    onChange={(e) => setTerm(e.target.value)}
                    type="number"
                    min="0"
                    placeholder="0"
                  ></Input>
                </CalculatorRow2>
                <Button onClick={handleClick}>现在计算</Button>
              </Calculator>
              <ValueSection>
                <ValueHeading>
                  {data.map((label, idx) => {
                    return (
                      <div
                        key={idx}
                        className={`py-4 border-b-4 transition-colors duration-300 ${
                          idx === activeTabIndex
                            ? "border-[#ed1c24] border-b-2"
                            : "border-transparent hover:border-gray-400 hover:scale-105"
                        }`}
                        // Change the active tab on click.
                        onClick={() => setActiveTabIndex(idx)}
                      >
                        {label.label}
                      </div>
                    );
                  })}
                </ValueHeading>
                {/* Show active tab content. */}
                <ValueContent>
                  <ValueRow>您的预计还款额：</ValueRow>
                  <Value>{data[activeTabIndex].desc}</Value>
                </ValueContent>
                <ValueDescription>
                  此计算器仅供信息参考，不提供任何金融建议。我们建议您在选择金融产品之前，先与我们经验丰富的理财顾问就您的情况和目标进行沟通。
                </ValueDescription>
              </ValueSection>
            </CalculatorSection>
          </TextColumn>
          <ImageColumn>
            <Lottie
              animationData={time}
              loop={true}
              height={500}
              mwidth={500}
            />
          </ImageColumn>
        </TwoColumn>
        <HeadingSection2>
          <Heading2>更多金融计算器.</Heading2>
          <HeadingDescription>
            无论您需要使用抵押贷款计算器来计算您的借款能力、抵押贷款还款额或税后收入，我们都为您准备好了。
          </HeadingDescription>
        </HeadingSection2>
        <MoreCalculators>
          {calculators.map((calculator) => (
            <a
              key={calculator.id}
              href={calculator.href}
              className="group relative"
            >
              <MoreCalculatorsContainer>
                <MoreCalculatorsImage
                  src={calculator.imageSrc}
                  alt={calculator.imageAlt}
                ></MoreCalculatorsImage>
              </MoreCalculatorsContainer>
              <H3>{calculator.name}</H3>
            </a>
          ))}
        </MoreCalculators>
      </Container>
    </Main>
  );
};
export default RepaymentCalculator;
