import React from "react";
import Hero from "./Hero";
import FAQ from "./FAQ";
import Services from "./Services";
import WhyUs from "./WhyUs";
import Reviews from "./Reivews";
const Home = () => {
  return (
    <div>
      <head>
        <meta
          name="description"
          content="We offer a range of services to help you achieve your goals. Whether you need a home loan, commercial loan, asset financing, or refinancing we have you covered."
        />
        <meta
          property="og:site_name"
          content="Bicare Finance | Professional Mortgage Broker Based in NZ"
        />
        <meta
          property="og:title"
          content="Bicare Finance | Professional Mortgage Broker Based in NZ"
        />
        <meta
          property="og:description"
          content="We offer a range of services to help you achieve your goals. Whether you need a home loan, commercial loan, asset financing, or refinancing, we have you covered."
        />
        <meta property="og:locale" content="en-NZ" />
        <meta
          name="twitter:title"
          content="Bicare Finance | Professional Mortgage Broker Based in NZ"
        />
        <meta
          name="twitter:description"
          content="We offer a range of services to help you achieve your goals. Whether you need a home loan, commercial loan, asset financing, or refinancing, we have you covered."
        />
        <title>Bicare Finance - Professional Mortgage Broker Based in NZ</title>
        <link rel="canonical" href="https://bicare.co.nz" />
      </head>
      <Hero />
      <Services />
      <WhyUs />
      <Reviews />
      <FAQ />
    </div>
  );
};

export default Home;
