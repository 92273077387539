import React from "react";
import tw from "twin.macro";
import Table, { AvatarCell } from "../../components/Table";
import data from "./ratesData";
import repayment from "../../assets/calculator/repayment.jpg";
import borrow from "../../assets/calculator/borrowing.jpg";
import paye from "../../assets/calculator/paye.jpg";

const Main = tw.div`relative`;
const Container = tw.div`mx-auto max-w-2xl py-20 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8`;
const HeadingSection = tw.div`max-w-3xl mt-12`;
const Heading = tw.h1`flex flex-col text-3xl  font-bold text-gray-800 mb-2 mt-5`;
const Heading2 = tw.h2`flex flex-col text-3xl font-bold text-gray-800 mb-2`;
const HeadingDescription = tw.p`flex flex-col text-base font-light text-gray-500 mt-5`;
const HeadingSubDescription = tw.p`flex flex-col text-xs font-light italic text-gray-500 mt-5`;
const TableSection = tw.div`mt-6`;
const MoreCalculators = tw.div`mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8`;
const MoreCalculatorsContainer = tw.div`aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-2xl shadow-xl bg-gray-100 group-hover:opacity-75 lg:aspect-none lg:h-80`;
const MoreCalculatorsImage = tw.img`object-cover object-center lg:h-full lg:w-full`;
const H3 = tw.h3`mt-4 text-base text-gray-700 text-center`;

// Function to get formatted date
const getFormattedDate = () => {
  const today = new Date();
  const day = today.getDate();
  const month = today.toLocaleString("default", { month: "long" });
  const year = today.getFullYear();

  // Add suffix to the day
  const suffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return `${day}${suffix(day)} of ${month} ${year}`;
};
function RatesCompare() {
  const columns = [
    {
      Header: "Lender",
      // accessor: "lender",
      Cell: AvatarCell,
      imgAccessor: "img",
    },
    {
      Header: "Floating",
      accessor: "floating",
    },
    {
      Header: "1 year",
      accessor: "1-year",
    },
    {
      Header: "2 year",
      accessor: "2-year",
    },
    {
      Header: "3 year",
      accessor: "3-year",
    },
    {
      Header: "4 year",
      accessor: "4-year",
    },
    {
      Header: "5 year",
      accessor: "5-year",
    },
  ];

  const calculators = [
    {
      id: 1,
      name: "Repayment Calculator",
      href: "/repayment-calculator",
      imageSrc: repayment,
      imageAlt: "repayment calculator, auckland mortgage broker",
    },
    {
      id: 2,
      name: "Borrowing Calculator",
      href: "/borrowing-calculator",
      imageSrc: borrow,
      imageAlt:
        "borrowing calculator, how much can I borrow? auckland mortgage broker",
    },
    {
      id: 3,
      name: "PAYE Calculator",
      href: "/paye-calculator",
      imageSrc: paye,
      imageAlt:
        "paye calculator, how much will I take home after tax? auckland mortgage broker",
    },
  ];
  return (
    <Main>
      <head>
        <meta
          name="description"
          content="A great rate is a great place to start. Talk to our experts about the best options for you."
        />
        <meta property="og:site_name" content="Compare Interest Rates" />
        <meta property="og:title" content="Compare Interest Rates" />
        <meta
          property="og:description"
          content="A great rate is a great place to start. Talk to our experts about the best options for you."
        />
        <meta property="og:locale" content="en-NZ" />
        <meta name="twitter:title" content="Compare Interest Rates" />
        <meta
          name="twitter：description"
          content="A great rate is a great place to start. Talk to our experts about the best options for you."
        />
        <link rel="canonical" href="https://bicare.co.nz/rates-compare" />
        <title>Compare Interest Rates - Bicare Finance</title>
      </head>
      <Container>
        <Heading>Compare Home Loan Interest Rates</Heading>
        <HeadingDescription>
          A great rate is a great place to start. Talk to our experts about the
          best options for you.
        </HeadingDescription>
        <HeadingSubDescription>
          Rates data from https://www.interest.co.nz and accurate as at the{" "}
          {getFormattedDate()}.
        </HeadingSubDescription>
        <TableSection>
          <Table columns={columns} data={data} />
        </TableSection>
        <HeadingSection>
          <Heading2>More helpful calculators.</Heading2>
          <HeadingDescription>
            Whether you need a mortgage calculator to work out your borrowing
            power, mortgage repayments or after tax income, we have got your
            covered.
          </HeadingDescription>
        </HeadingSection>
        <MoreCalculators>
          {calculators.map((calculator) => (
            <a
              key={calculator.id}
              href={calculator.href}
              className="group relative"
            >
              <MoreCalculatorsContainer>
                <MoreCalculatorsImage
                  src={calculator.imageSrc}
                  alt={calculator.imageAlt}
                  title={calculator.name}
                  loading="lazy"
                ></MoreCalculatorsImage>
              </MoreCalculatorsContainer>
              <H3>{calculator.name}</H3>
            </a>
          ))}
        </MoreCalculators>
      </Container>
    </Main>
  );
}

export default RatesCompare;
