import React from "react";
import construction from "../../assets/services/construction.jpg";
import tw from "twin.macro";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

const Main = tw.div`relative`;
const Container = tw.div`mx-auto max-w-2xl py-24 px-4 sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8`;
const HeadingSection = tw.div`mx-auto max-w-3xl text-center`;
const Heading = tw.h1`text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl`;
const SubheadingSection = tw.div`border-b-2 border-gray-200 pb-4`;
const Subheading = tw.h2`text-2xl tracking-tight text-gray-900`;
const TwoColumn = tw.div`grid grid-cols-1 gap-y-20 gap-x-8 lg:grid-cols-12 mt-16`;
const TextColumn = tw.div`lg:col-span-7`;
const ImageColumn = tw.div`lg:col-span-5`;
const DescriptionSection = tw.p`pt-6 space-y-2`;
const Description = tw.p`text-base tracking-tight text-gray-600`;
const Image = tw.img`h-full w-full object-cover object-center`;
const Link = tw.a`hover:text-[#ed1c24] underline decoration-solid underline-offset-2`;
const ApplyLink = tw.a`flex text-[#ed1c24] mt-10 font-light underline decoration-solid underline-offset-2`;

const ConstructionLoans = () => {
  return (
    <Main>
      <head>
        <meta
          name="description"
          content="Do you need financial support for construction or development? Bicare can help you with it."
        />
        <meta
          property="og:site_name"
          content="Construction-Loans - Bicare Finance"
        />
        <meta
          property="og:title"
          content="Construction-Loans - Bicare Finance"
        />
        <meta
          property="og:description"
          content="Do you need financial support for construction or development? Bicare can help you with it."
        />
        <meta property="og:locale" content="en-NZ" />
        <meta
          name="twitter:title"
          content="Construction-Loans - Bicare Finance"
        />
        <meta
          name="twitter:description"
          content="Do you need financial support for construction or development? Bicare can help you with it."
        />
        <title>Construction-Loans - Bicare Finance</title>
        <link rel="canonical" href="https://bicare.co.nz/construction-loans" />
      </head>
      <Container>
        <HeadingSection>
          <Heading>Development & Construction Financing</Heading>
        </HeadingSection>
        <TwoColumn>
          {/*text*/}
          <TextColumn>
            <SubheadingSection>
              <Subheading>
                Do you need financial support for construction or development?
              </Subheading>
            </SubheadingSection>
            <DescriptionSection>
              <Description>
                If you are looking for financial support for construction or
                development projects, Bicare is your trusted partner.
              </Description>
              <Description>
                For many years, Bicare has been deeply involved in the
                construction and development market, establishing strong
                relationships with major local banks and their development
                departments, as well as maintaining close cooperation with more
                than 30 non-bank credit institutions.
              </Description>
              <Description>
                Over the past decade, we have helped hundreds of large, medium,
                and small construction and development projects in the local
                area obtain financing, earning a good reputation among
                developers and credit institutions.
              </Description>
              <Description>
                In addition to tailor-made loan products that suit your needs,
                we also provide recommended services for legal, QS, sales,
                materials, and labor that are required in the construction and
                development process.
              </Description>
              <Description>
                We provide different levels of solutions specifically designed
                for newbies, experienced developers, and experts in the
                development industry to meet the needs of different developers.
              </Description>
              <Description>
                Whether you are just starting out or in the midst of
                development, we welcome developers to
                <Link href="/contact-us"> contact us</Link> any time for a free
                consultation, and we will provide you with comprehensive
                services and support.
              </Description>
              <Description>
                It is worth mentioning that we have successfully processed the
                largest single development project valued at up to 35 million
                New Zealand dollars, so you can trust us to work with you.
              </Description>
            </DescriptionSection>
          </TextColumn>
          {/*image*/}
          <ImageColumn>
            <div className="aspect-w-3 aspect-h-4 overflow-hidden rounded-lg">
              <Image
                src={construction}
                alt="construction and development mortgage, development loan, subdivision, building, new zealand, auckland, real estate development, developer, land subdivision"
                className="h-full w-full object-cover object-center"
                loading="lazy"
                title="construction and development mortgage"
              />
            </div>
          </ImageColumn>
        </TwoColumn>
        <ApplyLink href="/application-form">
          Apply construction Loan now
          <ChevronRightIcon className="w-6 h6 text-[#ed1c24]" />
        </ApplyLink>
      </Container>
    </Main>
  );
};
export default ConstructionLoans;
