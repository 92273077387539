import tw from "twin.macro";
import styled from "styled-components";

export const Container = tw.div`relative`;
export const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-12`;
export const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
export const ImageColumn = tw(
  Column
)`md:w-5/12 flex-shrink-0 h-80 md:h-auto relative`;
export const TextColumn = styled(Column)(tw`md:w-7/12 mt-16 md:mt-0`);
export const ContentWithPaddingSm = tw.div`max-w-screen-xl mx-auto py-4 lg:py-8`;
export const ContentWithPaddingMd = tw.div`max-w-screen-md mx-auto py-20 lg:py-24`;
export const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
export const ContentWithPaddingLg = tw.div`max-w-screen-lg mx-auto py-20 lg:py-24`;
export const ContentWithVerticalPadding = tw.div`py-20 lg:py-24`;
export const Content2Xl = tw.div`max-w-screen-2xl mx-auto`;
