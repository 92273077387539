import React from "react";
import construction from "../../assets/services/construction.jpg";
import tw from "twin.macro";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

const Main = tw.div`relative`;
const Container = tw.div`mx-auto max-w-2xl py-24 px-4 sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8`;
const HeadingSection = tw.div`mx-auto max-w-3xl text-center`;
const Heading = tw.h1`text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl`;
const SubheadingSection = tw.div`border-b-2 border-gray-200 pb-4`;
const Subheading = tw.h2`text-2xl tracking-tight text-gray-900`;
const TwoColumn = tw.div`grid grid-cols-1 gap-y-20 gap-x-8 lg:grid-cols-12 mt-16`;
const TextColumn = tw.div`lg:col-span-7`;
const ImageColumn = tw.div`lg:col-span-5`;
const DescriptionSection = tw.p`pt-6 space-y-2`;
const Description = tw.p`text-base tracking-tight text-gray-600`;
const Image = tw.img`h-full w-full object-cover object-center`;
const Link = tw.a`hover:text-[#ed1c24] underline decoration-solid underline-offset-2`;
const ApplyLink = tw.a`flex text-[#ed1c24] mt-10 font-light underline decoration-solid underline-offset-2`;
const ConstructionLoansCN = () => {
  return (
    <Main>
      <head>
        <meta
          name="description"
          content="如果您正在寻找建筑或开发项目的金融支持，百家信贷是您值得信赖的合作伙伴。我们已经成功处理过的最大单笔开发项目市值高达3500万纽币，因此您可以放心地与我们合作。"
        />
        <meta property="og:site_name" content="建房开发融资 - 百家信贷" />
        <meta property="og:title" content="建房开发融资 - 百家信贷" />
        <meta
          property="og:description"
          content="如果您正在寻找建筑或开发项目的金融支持，百家信贷是您值得信赖的合作伙伴。我们已经成功处理过的最大单笔开发项目市值高达3500万纽币，因此您可以放心地与我们合作。"
        />
        <meta property="og:locale" content="zh-CN" />
        <meta name="twitter:title" content="建房开发融资 - 百家信贷" />
        <meta
          name="twitter:description"
          content="如果您正在寻找建筑或开发项目的金融支持，百家信贷是您值得信赖的合作伙伴。我们已经成功处理过的最大单笔开发项目市值高达3500万纽币，因此您可以放心地与我们合作。"
        />
        <title>建房开发融资 - 百家信贷</title>
        <link rel="canonical" href="https://bicare.co.nz/construction-loans" />
      </head>
      <Container>
        <HeadingSection>
          <Heading>建房开发融资</Heading>
        </HeadingSection>
        <TwoColumn>
          {/*text*/}
          <TextColumn>
            <SubheadingSection>
              <Subheading>您需要建筑或是开发的金融支持吗？</Subheading>
            </SubheadingSection>
            <DescriptionSection>
              <Description>
                如果您正在寻找建筑或开发项目的金融支持，百家信贷是您值得信赖的合作伙伴。
              </Description>
              <Description>
                多年来，百家信贷深耕建筑开发市场，与本地各大主流银行企业开发部建立了深厚的友谊，并与30多家非银行信贷机构保持着紧密的合作。
              </Description>
              <Description>
                在过去的十余年来，我们帮助了本地上百个大、中、小型建筑开发项目获得了融资，因此在众多开发商和信贷机构中赢得了良好的口碑。
              </Description>
              <Description>
                除了量身定制适合您的贷款产品外，我们还提供在建筑开发过程中所需的法律、QS、销售、材料和人工等推荐服务。
              </Description>
              <Description>
                我们专门为开发行业的新手、熟手和专家提供不同层级的解决方案，以满足不同开发商的需求。
              </Description>
              <Description>
                如果您是打算入行，或者正在开发中，我们都欢迎开发商朋友随时
                <Link href="/contact-us">与我们联系</Link>
                ，我们将竭诚为您提供全方位的服务和支持。
              </Description>
              <Description>
                值得一提的是，我们已经成功处理过的最大单笔开发项目市值高达3500万纽币，因此您可以放心地与我们合作。
              </Description>
            </DescriptionSection>
          </TextColumn>
          {/*image*/}
          <ImageColumn>
            <div className="aspect-w-5 aspect-h-4 overflow-hidden rounded-lg">
              <Image
                src={construction}
                alt="建房开发融资,开发融资，建房，新西兰，奥克兰，房地产开发，开发商，土地分割"
                className="h-full w-full object-cover object-center"
                loading="lazy"
                title="建房开发融资"
              />
            </div>
          </ImageColumn>
        </TwoColumn>
        <ApplyLink href="/application-form">
          点击申请建房开发融资
          <ChevronRightIcon className="w-6 h6 text-[#ed1c24]" />
        </ApplyLink>
      </Container>
    </Main>
  );
};
export default ConstructionLoansCN;
