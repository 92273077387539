import React, { useEffect } from "react";
import "aos/dist/aos.css";
import tw from "twin.macro";
import {
  HomeIcon,
  WrenchScrewdriverIcon,
  BuildingOffice2Icon,
  BanknotesIcon,
  // PuzzlePieceIcon,
  ArrowSmallRightIcon,
  ChatBubbleBottomCenterTextIcon,
} from "@heroicons/react/24/outline";
import AOS from "aos";

const Main = tw.div`relative bg-slate-50 mt-20`;
const MainContainer = tw.div`max-w-screen-xl mx-auto lg:py-20 sm:py-14`;
const HeadingContainer = tw.div`mb-4 lg:mb-20`;
const Heading = tw.h1`text-3xl sm:text-4xl lg:text-5xl text-gray-900 font-bold tracking-wide text-center`;
const Subheading = tw.p`px-2 text-base md:text-base lg:text-lg text-center text-gray-700 font-light mt-8 tracking-wider`;
const ServiceContainer = tw.div`flex flex-wrap justify-center grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-10 lg:gap-10 md:gap-8 sm:gap-2`;
const Box = tw.div`max-w-sm m-4 bg-white rounded-3xl shadow-md py-8 px-6 border border-gray-200`;
const ServiceContent = tw.div`pt-5`;
const ServiceTitle = tw.h2`text-xl lg:text-xl mt-4 font-semibold tracking-wider text-gray-900`;
const ServiceDescription = tw.p`mt-4 tracking-wider leading-8 text-base text-gray-700`;
const ServiceLink = tw.div`flex items-center text-[#ed1c24] mt-10 font-light underline`;
const HighlightedText = tw.span`text-[#ed1c24]`;

const service = [
  {
    title: "Home Loans",
    description:
      "Get the home of your dreams with our personalized Home Loan solutions. Whether you're a first-time homebuyer, looking to refinance, or upsizing, our team can help to find the right loan for your needs.",
    url: "/home-loans",
    icon: HomeIcon,
  },
  {
    title: "Commercial Loans",
    description:
      "Looking to take your business to the next level? Our Commercial Loan solutions can help. With competitive rates, flexible terms, and personalized service, we can provide the funding you need to achieve your goals.",
    url: "/commercial-loans",
    icon: BuildingOffice2Icon,
  },
  {
    title: "Construction Loans",
    description:
      "Whether you're looking to build a single residential house or master planning a staged subdivision, our team of experienced professionals can help you find the right mortgage product to meet your specific needs.",
    url: "/construction-loans",
    icon: WrenchScrewdriverIcon,
  },
  {
    title: "Asset Financing",
    description:
      "With our Asset Financing solutions, you can access the funds you need to invest in new equipment, vehicles, or other assets that will help you succeed.",
    url: "/asset-finance",
    icon: BanknotesIcon,
  },
  // {
  //   title: "KiwiSaver",
  //   description:
  //     "Looking for a smarter way to save for retirement? Ready to take control of your financial future? Chat to our KiwiSaver experts to get your future set.",
  //   url: "/insurance",
  //   icon: PuzzlePieceIcon,
  // },
  {
    title: "Financial Advice",
    description:
      "Discover the power of investing in your future with our team of financial experts. Chat with us today and let us help you plan for a brighter tomorrow.",
    url: "/financial-advice",
    icon: ChatBubbleBottomCenterTextIcon,
  },
];

const Services = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <Main>
      <MainContainer>
        <HeadingContainer>
          <Heading>
            Our Professional <HighlightedText>Services.</HighlightedText>
          </Heading>
          <Subheading>
            We offer a range of services to help you achieve your goals.
            <br />
            Whether you need a home loan, commercial loan, asset financing,
            refinancing, or insurance, we have you covered.
          </Subheading>
        </HeadingContainer>
        <ServiceContainer>
          {service.map((data, index) => (
            <Box key={index} data-aos="fade-up">
              <div className="flex gap-4">
                <data.icon className="w-16 p-4 bg-[#ed1c24] text-white rounded-full" />
                <ServiceTitle>{data.title}</ServiceTitle>
              </div>
              <ServiceContent>
                <ServiceDescription>{data.description}</ServiceDescription>
              </ServiceContent>
              <ServiceLink>
                <a href={data.url}>Learn More</a>
                <ArrowSmallRightIcon className="w-5 ml-2" />
              </ServiceLink>
            </Box>
          ))}
        </ServiceContainer>
      </MainContainer>
    </Main>
  );
};
export default Services;
