import React from "react";
import tw from "twin.macro";
import avatar1 from "../assets/team/arlen.png";
import avatar2 from "../assets/team/laowang.png";
import avatar3 from "../assets/team/claire.png";
import avatar4 from "../assets/team/roger.png";
import avatar5 from "../assets/team/bella.png";
import avatar6 from "../assets/team/alice.png";
import avatar7 from "../assets/team/grace.png";
import avatar8 from "../assets/team/eccles.png";
import avatar9 from "../assets/team/david.png";
import avatar10 from "../assets/team/eden.png";

const Container = tw.div`relative`;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto mb-8`;
const HighlightedText = tw.span`text-[#ed1c24]`;
const Heading = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center`;
const Subheading = tw.h3`text-gray-500 text-lg text-center font-light py-8 px-2`;
const TeamContainer = tw.div`flex flex-wrap justify-center grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-10 lg:gap-10 md:gap-8 sm:gap-2 lg:py-8 md:py-6 sm:py-4`;
const MemberContainer = tw.div`w-full max-w-sm bg-white border border-gray-200 rounded-2xl shadow`;
const MemberRow = tw.div`flex flex-col items-center py-6`;
const Image = tw.img`w-24 h-24 mb-4 rounded-full shadow-lg shadow-gray-700/50`;
const Name = tw.span`mb-1 text-xl font-medium text-gray-700 mb-2`;
const Position = tw.span`text-sm text-gray-700 mb-4`;
const Introduction = tw.span`px-6 text-sm text-gray-500 text-center`;
const Contacts = tw.div`flex mt-4 space-x-3 md:mt-6`;
const EmailBtn = tw.a`inline-flex items-center px-8 py-2 text-sm font-medium text-center text-white bg-[#ed1c24] rounded-xl hover:scale-105`;

const TeamCN = ({
  members = [
    {
      imageSrc: avatar1,
      name: "Arlen Wang",
      position: "创始人，总经理",
      introduction:
        "百家信贷总经理王健，小王。新西兰注册金融顾问FSP 307106，新西兰百家信贷公司创始人之一。奥克兰大学经济与金融双专业学士。于2005 年，加入Bank of New Zealand（新西兰银行）。在此后的八年时间里，王健先后就职于ASB Bank (奥克兰存款银行), The National Bank（国民银行）和 ANZ Bank（澳新银行）信贷部门。历任职员，信贷经理，高级信贷专员。2013年，王健创立百家信贷品牌。目前负责公司的管理，市场营销以及信贷业务评估。八年四家本地主流银行的工作经历让王健懂得怎样聆听客户的需求，同时也具备了敏锐的洞察力，尤其是在对市场和产品分析方面这可以让百家的客人得到一个量身定做的信贷产品。“我们要做百姓身边金融理财顾问！”",
      email: "mailto:arlen.wang@bicare.co.nz",
      title: "王健 (Arlen)",
    },
    {
      imageSrc: avatar2,
      name: "Wanyue Wang",
      position: "联合创始人",
      introduction:
        "大家叫我百家信贷老王！有着30年金融工作的履历，10年贷款顾问的经验。懂得钱的奥秘，理解客户的需求！熟悉新西兰各大银行及贷款机构的信贷政策，可以帮您量身斟酌未来的财务规划，为您解决贷款融资中的麻烦与困扰!更可以协助您树立良好的信用形象，从长期的视点，最大程度地享受银行融资的收益与便利！",
      email: "mailto:wanyue.wang@bicare.co.nz",
      title: "老王",
    },
    {
      imageSrc: avatar3,
      name: "Claire Cai",
      position: "联合创始人，运营总监",
      introduction:
        "Claire 1999年来到新西兰，于2003年毕业于奥克兰大学金融和会计专业。毕业后在众邦国际任外汇分析师，在此期间累计了大量的外汇股票分析的经验。在投资领域有一定的建树。于2006年加入BNZ，从事日常银行业务以及和贷款相关的工作。2013年和公司另两位创始人一起创立了百家信贷的品牌。",
      email: "mailto:admin@bicare.co.nz",
      title: "蔡雪皎 (Claire)",
    },
    {
      imageSrc: avatar4,
      name: "Roger Shen",
      position: "信贷经理",
      introduction:
        "沈楠，新西兰注册金融顾问，FSP。毕业于怀卡托大学的管理学院，荣获经济和金融一等荣誉学士学位。他的客户们通常把他形容成是一个友善,极具正能量，富有爱心，且诙谐幽默的专业人士。他的箴言是“努力帮助客户满足房屋贷款需求，从而使客户过得更好”。Roger曾供职于新西兰两大主流银行：新西兰银行（BNZ）以及澳大利亚新西兰银行(ANZ)。身为一位贷款顾问，他会用自己的专业经验为您提供最好的服务和适当的房屋贷款方面的选择。",
      email: "mailto:roger.shen@bicare.co.nz",
      title: "沈楠 (Roger)",
    },
    {
      imageSrc: avatar5,
      name: "Bella Qiang",
      position: "行政助理",
      introduction:
        "毕业于梅西大学，金融学学士，管理学硕士，曾供职于国内上市银行，金融租赁公司等，具有多年信贷，风险控制及内审经验。以真诚，严谨，专业的态度，为客户提供最优质的服务。",
      email: "mailto:bella.qiang@bicare.co.nz",
      title: "Bella Qiang",
    },
    {
      imageSrc: avatar6,
      name: "Alice Xu",
      position: "贷款经理，东区Team Lead",
      introduction:
        " 许冰，帮助首次购房的朋友拥有一个梦想的家，有效的房产投资贷款咨询协助您积累财富，提供快捷的应急方案在您遇到财务小坎坷的时候伸出援助之手， 10 年本地银行贷款经理， 16 年贷款经验， 6年的团队管理经验。专注于房屋贷款，土地开发及建筑贷款，商业物业贷款。",
      email: "mailto:alice.xu@bicare.co.nz",
      title: "许冰 (Alice)",
    },
    {
      imageSrc: avatar7,
      name: "Grace Wang",
      position: "行政助理",
      introduction:
        "有丰富的银行从业经验，熟悉银行操作和客户管理。为客户最大利益着想，与Financial Advisor 团队密切配合，共同为客户提供无缝连接的贷前、贷中、贷后跟踪管理，使贷款根据客户需要得以顺利到位。并密切关注银行金融业动态，因势利导，为客户需求提供最合适的解决方案。",
      email: "mailto:admin.east@bicare.co.nz",
      title: "Grace Wang",
    },
    {
      imageSrc: avatar8,
      name: "Eccles Chan",
      position: "贷款经理",
      introduction:
        "作为贷款的顾问专家，拥有来自市场上全面的贷款知识和资源。 从您产生拥有首套房的想法开始，或者购买第一套投资房，直到搜索市场并向您推荐最合适的贷款方案，我将与您一起走过这段旅程。在我 5 年的顾问经验中，与在业内最值得信赖的 Bicare Finance 团队的支持下，我与各行各业的许多快乐客户合作，通过房屋贷款，土地开发及建筑贷款， 商业物业贷款，实现了他们的目标。",
      email: "mailto:eccles.chan@bicare.co.nz",
      title: "Eccles Chan",
    },
    {
      imageSrc: avatar9,
      name: "David Wang",
      position: "贷款经理",
      introduction:
        "2001年来到新西兰 2015年开始从事贷款行业 多年里对新西兰的经济,房产有着深刻的认知.毕业于AUT大学管理系,积累了与当地洋人以及华人社区良好的沟通经验.累积了大量的房地产买卖,房屋建造等相关专业知识,为很多首次购房置业的朋友提供贷款建议.愿以真诚,幽默,严谨,专业的态度 为您服务。",
      email: "mailto:david.wang@bicare.co.nz",
      title: "David Wang",
    },
    {
      imageSrc: avatar10,
      name: "Eden Ross",
      position: "贷款经理, 丰盛湾大区",
      introduction:
        "毕业于梅西MBA管理硕士，新西兰注册金融贷款顾问FSP。新西兰近三十年大小城市生活工作经历和个人丰富贷款投资经验赋予我独道的投资眼光和贷款思路。无论你是首次住房买家，还是想开阔视野操作不同市场的长短期投资朋友们，相信我的专业知识和对不同城市的深度理解，都能给你提供最适合你的贷款方案。",
      email: "mailto:eden.ross@bicare.co.nz",
      title: "Eden Ross",
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <Heading>
          您值得信赖的<HighlightedText>精英团队</HighlightedText>
        </Heading>
        <Subheading className="text-gray-500 text-lg text-center font-light py-6 px-2">
          我们的团队由多名资深的专业人士组成，拥有丰富的银行工作经验。
          <br />
          我们熟悉各种类型的贷款产品和政策，可以为您提供专业的建议和定制的解决方案。
          <br />
          我们了解不同行业和企业的特点和需求，因此可以根据您的具体情况，为您提供最优的融资方案。
        </Subheading>
        <TeamContainer>
          {members.map((data, index) => (
            <MemberContainer key={index}>
              <MemberRow>
                <Image
                  src={data.imageSrc}
                  alt={data.name}
                  title={data.title}
                  loading="lazy"
                ></Image>
                <Name> {data.name} </Name>
                <Position> {data.position} </Position>
                <Introduction> {data.introduction} </Introduction>
                <Contacts>
                  <EmailBtn href={data.email}>发消息</EmailBtn>
                </Contacts>
              </MemberRow>
            </MemberContainer>
          ))}
        </TeamContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};

export default TeamCN;
