import React from "react";
import commercialen from "../../assets/services/commercial-en.jpg";
import tw from "twin.macro";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

const Main = tw.div`relative`;
const Container = tw.div`mx-auto max-w-2xl py-24 px-4 sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8`;
const HeadingSection = tw.div`mx-auto max-w-3xl text-center`;
const Heading = tw.h1`text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl`;
const HeadingDescription = tw.p`mt-4 text-gray-500`;
const SubheadingSection = tw.div`border-b-2 border-gray-200 pb-4`;
const Subheading = tw.h2`text-2xl tracking-tight text-gray-900`;
const TwoColumn = tw.div`grid grid-cols-1 gap-y-20 gap-x-8 lg:grid-cols-12 mt-16`;
const TextColumn = tw.div`lg:col-span-7`;
const ImageColumn = tw.div`lg:col-span-5`;
const DescriptionSection = tw.p`pt-6 space-y-2`;
const Description = tw.p`text-base tracking-tight text-gray-600`;
const Image = tw.img`h-full w-full object-cover object-center`;
const Link = tw.a`hover:text-[#ed1c24] underline decoration-solid underline-offset-2`;
const ApplyLink = tw.a`flex text-[#ed1c24] mt-10 font-light underline decoration-solid underline-offset-2`;

const CommercialLoan = () => {
  return (
    <Main>
      <head>
        <meta
          name="description"
          content="Bicare will provide full service to support you buying commercial properties "
        />
        <meta
          property="og:site_name"
          content="Commercial Property Loan - Bicare Finance"
        />
        <meta
          property="og:title"
          content="Commercial Property Loan - Bicare Finance"
        />
        <meta
          property="og:description"
          content="Bicare will provide full service to support you buying commercial properties"
        />
        <meta property="og:locale" content="en-NZ" />
        <meta
          name="twitter:title"
          content="Commercial Property Loan - Bicare Finance"
        />
        <meta
          name="twitter:description"
          content="Bicare will provide full service to support you buying commercial properties"
        />
        <title>Commercial Property Loan - Bicare Finance</title>
        <link rel="canonical" href="https://bicare.co.nz/commercial-loans" />
      </head>
      <Container>
        <HeadingSection>
          <Heading>Commercial Property Loan</Heading>
          <HeadingDescription></HeadingDescription>
        </HeadingSection>
        <TwoColumn>
          {/*text*/}
          <TextColumn>
            <SubheadingSection>
              <Subheading>
                Do you wish to establish a commercial property empire in New
                Zealand?{" "}
              </Subheading>
            </SubheadingSection>
            <DescriptionSection>
              <Description>
                This is an exciting time to be investing in commercial property
                in New Zealand. The country’s economy is booming, and the
                commercial property market is growing at a rapid pace.
              </Description>
              <Description>
                However, In the commercial property market in New Zealand,
                landlords are not always the weaker party. For example, in the
                vast majority of lease agreements in New Zealand, the tenant is
                responsible for a series of expenses such as maintenance,
                electricity, and insurance. However, this does not mean that you
                can rest easy.
              </Description>
              <Description>
                You need to be familiar with what type of property is suitable
                for you. Retail shops? Warehouses? Office buildings?
                Kindergartens? Or hotels and motels? Different properties have
                their own characteristics, which bring different rates of return
                and risks to investors.
              </Description>
              <Description>
                The first thing you need to do is to withdraw the experience you
                have gained in investing in residential properties because
                commercial properties have their own set of models in terms of
                pricing, investment, holding, and sales. For example, the loan
                term for commercial properties is 15 years, and the maximum
                loan-to-value ratio cannot exceed 65%. Isn't it different?
              </Description>
              <Description>
                In addition, the commercial property market is also very
                different from the residential property market. The commercial
                property market is more complicated and has more factors to
                consider. For example, the location of the property, the type of
                property, the tenant, the lease agreement, the property
                management, and the property management company. These factors
                will affect the value of the property and the return on
                investment.
              </Description>
              <Description>
                If you are confused, please feel free to{" "}
                <Link href="/contact-us">contact us</Link>, we can provide you
                with full service support from the beginning of intention to
                planning, from budgeting to loans, from delivery to management,
                covering every problem you may encounter without any dead ends.
              </Description>
            </DescriptionSection>
          </TextColumn>
          {/*image*/}
          <ImageColumn>
            <div className="aspect-w-7 aspect-h-10 overflow-hidden rounded-lg">
              <Image
                src={commercialen}
                alt="commercial loan, commercial loan new zealand, commercial loan auckland, business new zealand, business loan new zealand"
                className="h-full w-full object-cover object-center"
                loading="lazy"
                title="commercial property loan"
              />
            </div>
          </ImageColumn>
        </TwoColumn>
        <ApplyLink href="/application-form">
          Apply commercial loan now
          <ChevronRightIcon className="w-6 h6 text-[#ed1c24]" />
        </ApplyLink>
      </Container>
    </Main>
  );
};
export default CommercialLoan;
