import React, { useEffect } from "react";
import tw from "twin.macro";
import AOS from "aos";
import "aos/dist/aos.css";
import Lottie from "lottie-react";
import review from "../assets/review/review.json";
import { ReactComponent as StarIconBase } from "../assets/review/star.svg";

const HighlightedText = tw.span`text-[#ed1c24]`;
const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto mt-12`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-12`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ReviewColumn = tw(
  Column
)`flex flex-wrap justify-center grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-10 lg:gap-10 md:gap-8 sm:gap-2 px-6`;
const ReviewContainer = tw.div``;
const ReviewBlock = tw.div`flex flex-col gap-y-6 sm:gap-y-8`;
const Review = tw.div`relative rounded-2xl bg-white border border-gray-200 shadow-md p-6`;
const ReviewQuoteDeco = tw.div`absolute top-6 left-6 fill-slate-100`;
const ReviewContent = tw.p`text-base font-light tracking-tight text-gray-500`;
const ReviewAuthor = tw.div`relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6`;
const ReviewAuthorName = tw.div`font-light text-base text-gray-900`;
const ReviewAuthorRole = tw.div`mt-1 text-sm text-gray-500`;
const ImageColumn = tw(
  Column
)`md:w-1/3 flex-shrink-0 md:h-auto relative mt-12 md:mt-0`;
const HeadingContainer = tw.div`mb-4`;
const Heading = tw.h2`text-2xl sm:text-4xl lg:text-5xl text-gray-900 font-bold tracking-wide text-center`;
const Subheading = tw.h3`text-base md:text-base lg:text-lg text-center text-gray-700 font-light mt-8 tracking-wider`;
const StarIcon = tw(
  StarIconBase
)`inline-block w-5 h-5 text-orange-400 fill-current mr-1 last:mr-0`;

const testimonials = [
  [
    {
      content:
        "Would like to give a big thanks Arlen & to your company Bicare for your excellent service & support. Your focus on the small details are second to none which made my choice extremely easy. Your quick & transparent responses together with your very close connections with the banking industry saved my family both time & money & for this you have now a “forever” customer.",
      author: {
        name: "Iain Hilton",
        role: "CEO, Chester Pluming",
      },
    },
    {
      content:
        "在百家之前，我已近买了好几栋投资房，卖房子的时候总被银行强行还回贷款，原来的贷款经理告诉我这是正常的， 直到碰到了王健。他分析过后发现，我的抵押效率太低，存在着很严重的equity浪费情况。经过一年半的调整，我竟然在没还银行一分钱的基础上mortgage free出来两套房子包括我们的自住房。这太神奇了。",
      author: {
        name: " William",
        role: "Director, NZX Listing Firm",
      },
    },
  ],
  [
    {
      content:
        "我非常满意百家信贷的办事效率， 我推荐的客户他们总是第一时间就与客户取得联络。并且帮助客户尽快取得所需要的贷款。 我的客户在也没有因为贷款问题而错过自己喜欢的房产。 推荐客户给王健，我很放心。",
      author: {
        name: "以慰",
        role: "金牌中介",
      },
    },

    {
      content:
        "新西兰算是我的第二故乡，这里的蓝天白云给我带来灵感。我特别希望能在这片白云的故乡有一个属于自己的家。 百家信贷帮我完胜了梦想。 无论国内的工作多么忙，应酬多繁琐。 新西兰的家都可以让我获得宁静。 感谢百家信贷。",
      author: {
        name: "Lei",
        role: "导演，经纪人",
      },
    },
    {
      content:
        "我的所有卡车都是百家信贷的王健帮我做的， 现在每台卡车的活全部排满，供不应求。 王健不仅帮我拿到了90%的贷款，关键是他还为我提供不少财税好点子。这不禁让我拥有了车队，同时在每日运营上也省了不少钱。 太厉害了。",
      author: {
        name: "Leo",
        role: "NZ Solid Limited",
      },
    },
  ],
];

const ReviewsCN = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <Container>
      <Content>
        <HeadingContainer data-aos="fade-up">
          <Heading>
            我们深受客户<HighlightedText>喜爱与信任</HighlightedText>
          </Heading>
          <Subheading>
            了解我们的客户对我们的评价与反馈，阅读他们的贷款体验。
          </Subheading>
        </HeadingContainer>
        <TwoColumn>
          <ReviewColumn>
            {testimonials.map((column, columnIndex) => (
              <ReviewContainer key={columnIndex}>
                <ReviewBlock>
                  {column.map((testimonial, testimonialIndex) => (
                    <ReviewContainer key={testimonialIndex} data-aos="zoom-in">
                      <Review>
                        <ReviewQuoteDeco>
                          <svg aria-hidden="true" width={105} height={78}>
                            <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z" />
                          </svg>
                        </ReviewQuoteDeco>
                        <blockquote className="relative">
                          <ReviewContent>{testimonial.content}</ReviewContent>
                        </blockquote>
                        <ReviewAuthor>
                          <ReviewContainer>
                            <ReviewAuthorName>
                              {testimonial.author.name}
                            </ReviewAuthorName>
                            <ReviewAuthorRole>
                              {testimonial.author.role}
                            </ReviewAuthorRole>
                          </ReviewContainer>
                          <ReviewContainer>
                            <StarIcon />
                            <StarIcon />
                            <StarIcon />
                            <StarIcon />
                            <StarIcon />
                          </ReviewContainer>
                        </ReviewAuthor>
                      </Review>
                    </ReviewContainer>
                  ))}
                </ReviewBlock>
              </ReviewContainer>
            ))}
          </ReviewColumn>
          <ImageColumn>
            <Lottie
              className="w-full h-full"
              animationData={review}
              loop={true}
              mheight={600}
              mwidth={600}
            />
          </ImageColumn>
        </TwoColumn>
      </Content>
    </Container>
  );
};

export default ReviewsCN;
