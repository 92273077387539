import React, { useState } from "react";
import { PopupButton } from "react-calendly";
import booking from "../assets/about/book.jpg";

const BookingCN = () => {
  const [selectedUser, setSelectedUser] = useState("");

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };
  const getPopupButtonProps = () => {
    if (selectedUser === "") {
      return {
        className: "text-white underline underline-offset-4 cursor-not-allowed",
        url: "/",
        rootElement: document.getElementById("root"),
        text: "点击在线预约!",
      };
    } else if (selectedUser === "wanyue") {
      return {
        className: "text-white hover:scale-110 underline underline-offset-4",
        url: "https://calendly.com/wanyue-wang?hide_gdpr_banner=1&text_color=4b4747&primary_color=ed1c24",
        rootElement: document.getElementById("root"),
        text: "点击在线预约!",
      };
    } else if (selectedUser === "arlen") {
      return {
        className: "text-white hover:scale-110 underline underline-offset-4",
        url: "https://calendly.com/arlen-wang-1?hide_gdpr_banner=1&text_color=4b4747&primary_color=ed1c24",
        rootElement: document.getElementById("root"),
        text: "点击在线预约!",
      };
    } else if (selectedUser === "claire") {
      return {
        className: "text-white hover:scale-110 underline underline-offset-4",
        url: "https://calendly.com/claire_cai?hide_gdpr_banner=1&text_color=4b4747&primary_color=ed1c24",
        rootElement: document.getElementById("root"),
        text: "点击在线预约!",
      };
    } else if (selectedUser === "david") {
      return {
        className: "text-white hover:scale-110 underline underline-offset-4",
        url: "https://calendly.com/nzdwang?hide_gdpr_banner=1&text_color=4b4747&primary_color=ed1c24",
        rootElement: document.getElementById("root"),
        text: "点击在线预约!",
      };
    } else if (selectedUser === "eccles") {
      return {
        className: "text-white hover:scale-110 underline underline-offset-4",
        url: "https://calendly.com/eccles-chan?hide_gdpr_banner=1&text_color=4b4747&primary_color=ed1c24",
        rootElement: document.getElementById("root"),
        text: "点击在线预约!",
      };
    } else if (selectedUser === "annatta") {
      return {
        className: "text-white hover:scale-110 underline underline-offset-4",
        url: "https://calendly.com/annatta-yi?hide_gdpr_banner=1&text_color=4b4747&primary_color=ed1c24",
        rootElement: document.getElementById("root"),
        text: "点击在线预约!",
      };
    } else if (selectedUser === "alice") {
      return {
        className: "text-white hover:scale-110 underline underline-offset-4",
        url: "https://calendly.com/alice-xu-2?hide_gdpr_banner=1&text_color=4b4747&primary_color=ed1c24",
        rootElement: document.getElementById("root"),
        text: "点击在线预约!",
      };
    } else if (selectedUser === "roger") {
      return {
        className: "text-white hover:scale-110 underline underline-offset-4",
        url: "https://calendly.com/roger_shen?hide_gdpr_banner=1&text_color=4b4747&primary_color=ed1c24",
        rootElement: document.getElementById("root"),
        text: "点击在线预约!",
      };
    }
    return null;
  };
  const popupButtonProps = getPopupButtonProps();

  return (
    <div className="bg-white">
      <head>
        <meta
          name="description"
          content="在线预约 - 与百家信贷贷款经理更进一步交谈"
        />
        <meta
          property="og:site_name"
          content="在线预约与贷款经理交谈 - 百家信贷"
        />
        <meta property="og:title" content="在线预约与贷款经理交谈 - 百家信贷" />
        <meta
          property="og:description"
          content="在线预约 - 与百家信贷贷款经理更进一步交谈"
        />
        <meta property="og:locale" content="zh-CN" />
        <meta
          name="twitter:title"
          content="在线预约与贷款经理交谈 - 百家信贷"
        />
        <meta
          name="twitter:description"
          content="在线预约 - 与百家信贷贷款经理更进一步交谈"
        />
        <title>在线预约与贷款经理交谈 - 百家信贷</title>
        <link rel="canonical" href="https://bicare.co.nz/booking" />
      </head>
      <div className="relative bg-gray-900">
        <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
          <img
            src={booking}
            alt="百家信贷在线预约与贷款经理交流"
            className="h-full w-full object-cover object-center"
          />
        </div>
        <div
          aria-hidden="true"
          className="absolute inset-0 bg-gray-900 opacity-70"
        />

        <div className="relative mx-auto flex max-w-3xl flex-col items-center py-32 px-6 text-center sm:py-64 lg:px-0">
          <h1 className="text-4xl font-bold tracking-tight text-white lg:text-5xl">
            与贷款经理更进一步交谈
          </h1>
          <p className="mt-8 text-xl text-white">
            无论您是想进行贷款咨询还是寻求理财建议，我们都提供了一种方便简单的在线预约方式。您可以在这里选择您的时间，然后我们会在您选择的时间段内与您联系。
          </p>
          <p className="mt-6 text-white text-base">
            除特殊约定外，我们的服务完全免费！
          </p>
          <div>
            <select
              className="inline-flex mt-8 text-center justify-center rounded-lg py-3 px-10 text-md hover:scale-105 bg-white text-gray-900"
              id="user-select"
              value={selectedUser}
              onChange={handleUserChange}
            >
              <option value="">请选择一名贷款经理</option>
              <option value="alice">Alice Xu</option>
              <option value="annatta">Annatta Yi</option>
              <option value="arlen">Arlen Wang</option>
              <option value="claire">Claire Cai</option>
              <option value="david">David Wang</option>
              <option value="eccles">Eccles Chan</option>
              <option value="roger">Roger Shen</option>
              <option value="wanyue">Wanyue Wang</option>
            </select>
            <div className="mt-6">
              {popupButtonProps && <PopupButton {...popupButtonProps} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingCN;
