import React, { useState } from "react";
import tw from "twin.macro";
import Lottie from "lottie-react";
import key from "../../assets/calculator/key.json";
import repayment from "../../assets/calculator/repayment.jpg";
import paye from "../../assets/calculator/paye.jpg";
import rates from "../../assets/calculator/rates.jpg";

const Main = tw.div`relative`;
const Container = tw.div`mx-auto max-w-2xl py-24 px-4 sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8`;
const HeadingSection1 = tw.div`max-w-3xl`;
const HeadingSection2 = tw.div`max-w-3xl mt-16`;
const Heading = tw.h1`text-3xl font-bold tracking-tight text-gray-800`;
const Heading2 = tw.h2`text-3xl font-bold tracking-tight text-gray-800`;
const HeadingDescription = tw.p`mt-4 text-gray-500`;
const TwoColumn = tw.div`grid grid-cols-1 gap-y-20 gap-x-8 lg:grid-cols-3`;
const TextColumn = tw.div`lg:col-span-2`;
const ImageColumn = tw.div`lg:col-span-1 h-full w-full object-cover object-center`;
const CalculatorSection = tw.div`mx-auto flex flex-col md:flex-row bg-gray-100 mt-8 shadow-xl rounded-2xl`;
const Calculator = tw.div`cursor-pointer flex flex-col border-r border-gray-200 md:w-2/5`;
const CalculatorRow1 = tw.div`relative mt-10 ml-10 mr-10`;
const CalculatorRow2 = tw.div`relative mt-5 ml-10 mr-10`;
const Label = tw.label`text-base text-gray-500`;
const Select = tw.select`w-full rounded-md bg-white rounded border border-gray-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out`;
const Button = tw.button`m-10 text-white bg-[#ed1c24] border-0 py-2 px-6 focus:outline-none rounded-lg text-base transform active:scale-x-90 active:scale-y-90 transition-transform`;
const Input = tw.input`w-full rounded-md bg-white rounded border border-gray-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out`;
const ValueSection = tw.div`bg-white rounded-r-2xl md:w-3/5`;
const ValueRow = tw.div`flex border-b bg-gray-100 rounded-tr-2xl text-gray-700 text-lg h-14 items-center justify-around`;
const Value = tw.div`flex justify-around py-32 text-8xl overflow-x-scroll`;
const ValueDescription = tw.p`text-xs font-light italic text-gray-500 m-5`;
const MoreCalculators = tw.div`mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8`;
const MoreCalculatorsContainer = tw.div`aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-2xl shadow-xl bg-gray-100 group-hover:opacity-75 lg:aspect-none lg:h-80`;
const MoreCalculatorsImage = tw.img`object-cover object-center lg:h-full lg:w-full`;
const H3 = tw.h3`mt-4 text-base text-gray-700 text-center`;

const BorrowingCalculatorCN = () => {
  const [type, setType] = useState("");
  const [income, setIncome] = useState("$0");
  const [dependent, setDependent] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [mortgage, setMortgage] = useState("$0");
  const calculate = (type, income, dependent, vehicle) => {
    if (type === "individual") {
      return parseFloat(
        income * 6.4 - dependent * 40000 - vehicle * 20000
      ).toFixed(0);
    } else if (type === "joint") {
      return parseFloat(
        income * 5.3 - dependent * 40000 - vehicle * 20000
      ).toFixed(0);
    }
  };

  const calculators = [
    {
      id: 1,
      name: "还款计算器",
      href: "/repayment-calculator",
      imageSrc: repayment,
      imageAlt: "还款计算器，月供，奥克兰抵押经纪人",
    },
    {
      id: 2,
      name: "PAYE新西兰工资税计算器",
      href: "/paye-calculator",
      imageSrc: paye,
      imageAlt: "我的税后工资是多少？奥克兰抵押经纪人",
    },
    {
      id: 3,
      name: "各银行贷款利息比较",
      href: "/rates-compare",
      imageSrc: rates,
      imageAlt: "比较利率，比较利率，奥克兰抵押经纪人",
    },
  ];
  const handleClick = (e) => {
    e.preventDefault();
    const mortgage = calculate(type, income, dependent, vehicle);
    setMortgage(`$${mortgage}`);
  };

  return (
    <Main>
      <head>
        <meta
          name="description"
          content="只需输入几项简单的信息，我们可以根据您目前的财务状况，估算您可以借多少贷款"
        />
        <meta property="og:site_name" content="贷款计算器 - 百家信贷" />
        <meta property="og:title" content="贷款计算器 - 百家信贷" />
        <meta
          property="og:description"
          content="只需输入几项简单的信息，我们可以根据您目前的财务状况，估算您可以借多少贷款"
        />
        <meta property="og:locale" content="zh-CN" />
        <meta name="twitter:title" content="贷款计算器 - 百家信贷" />
        <meta
          name="twitter:description"
          content="只需输入几项简单的信息，我们可以根据您目前的财务状况，估算您可以借多少贷款。"
        />
        <title>贷款计算器 - 百家信贷</title>
        <link
          rel="canonical"
          href="https://bicare.co.nz/borrowing-calculator"
        />
      </head>
      <Container>
        <HeadingSection1>
          <Heading>金融计算器 - 贷款计算器</Heading>
          <HeadingDescription>
            只需输入几项简单的信息，我们可以根据您目前的财务状况，估算您可以借多少贷款。
          </HeadingDescription>
        </HeadingSection1>
        <TwoColumn>
          <TextColumn>
            <CalculatorSection>
              <Calculator>
                <CalculatorRow1>
                  <Label>您的申请类型：</Label>
                  <Select
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="select">请选择一项</option>
                    <option value="individual">个人</option>
                    <option value="joint">联名</option>
                  </Select>
                </CalculatorRow1>
                <CalculatorRow2>
                  <Label>家庭税前年收入：</Label>
                  <Input
                    type="number"
                    value={income}
                    onChange={(e) => setIncome(e.target.value)}
                    min="0"
                    placeholder="$"
                  ></Input>
                </CalculatorRow2>
                <CalculatorRow2>
                  <Label>18岁以下未成年子女：</Label>
                  <Input
                    value={dependent}
                    onChange={(e) => setDependent(e.target.value)}
                    type="number"
                    min="0"
                    placeholder="0"
                  ></Input>
                </CalculatorRow2>
                <CalculatorRow2>
                  <Label>车辆：</Label>
                  <Input
                    value={vehicle}
                    onChange={(e) => setVehicle(e.target.value)}
                    type="number"
                    min="0"
                    placeholder="0"
                  ></Input>
                </CalculatorRow2>
                <Button onClick={handleClick}>现在计算</Button>
              </Calculator>
              <ValueSection>
                <ValueRow>我们估计您最多可以借到：</ValueRow>
                <Value>{mortgage}</Value>
                <ValueDescription>
                  此计算器仅供信息参考，不提供任何金融建议。我们建议您在选择金融产品之前，先与我们经验丰富的理财顾问就您的情况和目标进行沟通。
                </ValueDescription>
              </ValueSection>
            </CalculatorSection>
          </TextColumn>
          <ImageColumn>
            <Lottie animationData={key} loop={true} height={600} mwidth={600} />
          </ImageColumn>
        </TwoColumn>
        <HeadingSection2>
          <Heading2>更多金融计算器.</Heading2>
          <HeadingDescription>
            无论您需要使用抵押贷款计算器来计算您的借款能力、抵押贷款还款额或税后收入，我们都为您准备好了。
          </HeadingDescription>
        </HeadingSection2>
        <MoreCalculators>
          {calculators.map((calculator) => (
            <a
              key={calculator.id}
              href={calculator.href}
              className="group relative"
            >
              <MoreCalculatorsContainer>
                <MoreCalculatorsImage
                  src={calculator.imageSrc}
                  alt={calculator.imageAlt}
                  title={calculator.name}
                  loading="lazy"
                ></MoreCalculatorsImage>
              </MoreCalculatorsContainer>
              <H3>{calculator.name}</H3>
            </a>
          ))}
        </MoreCalculators>
      </Container>
    </Main>
  );
};
export default BorrowingCalculatorCN;
