import React, { useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "../components/Headings.js";
import Lottie from "lottie-react";
import money from "../assets/whyus/money";
import AOS from "aos";
import "aos/dist/aos.css";
const Container = tw.div`relative bg-slate-50`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-12`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 md:h-auto relative`;
const TextColumn = styled(Column)(tw`md:w-7/12 mt-10 md:mt-0`);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-2xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight px-2`;
const Description = tw.p`px-2 py-6 text-center md:text-left text-base md:text-base lg:text-lg font-light leading-relaxed text-gray-500`;
const Statistics = tw.div`grid grid-cols-3 gap-10 items-center sm:block text-center md:text-left mt-4 px-2`;

const Statistic = tw.div`text-center sm:inline-block sm:mr-12 last:mr-0 py-2`;
const Value = tw.div`font-semibold text-lg sm:text-xl lg:text-2xl text-[#ed1c24]`;
const Key = tw.div`font-light text-gray-500`;
const HighlightedText = tw.span`text-[#ed1c24]`;
const defaultStatistics = [
  {
    key: "Happy Clients",
    value: "3282+",
  },
  {
    key: "Projects",
    value: "10891+",
  },
  {
    key: "Awards",
    value: "56+",
  },
];
const description1 =
  "We have been assisting Kiwi with their loan needs since 2012. We understand that managing your finances can be overwhelming and time-consuming, that's why we offer a simple and streamlined solution.";
const description2 =
  "Our experienced brokers work with a variety of lenders to find the best rates and terms for your unique situation.";
let statistics = null;
const heading1 = "Why You Should";
const heading2 = "Choose Us.";

const WhyUs = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  if (!statistics) statistics = defaultStatistics;

  return (
    <Container>
      <TwoColumn data-aos="fade-up">
        <ImageColumn>
          <Lottie
            animationData={money}
            loop={true}
            mheight={500}
            mwidth={300}
          />
        </ImageColumn>
        <TextColumn>
          <TextContent>
            <Heading>{heading1}</Heading>
            <Heading>
              <HighlightedText>{heading2}</HighlightedText>
            </Heading>
            <Description>
              {description1}
              <br />
              {description2}
            </Description>
            <Statistics>
              {statistics.map((statistic, index) => (
                <Statistic key={index}>
                  <Value>{statistic.value}</Value>
                  <Key>{statistic.key}</Key>
                </Statistic>
              ))}
            </Statistics>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};

export default WhyUs;
