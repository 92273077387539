import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components/macro";
import { Container, ContentWithPaddingSm } from "../components/Layouts.js";
import { SectionHeading } from "../components/Headings.js";
import { SectionDescription } from "../components/Typography.js";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
const HeadingContainer = tw.div`flex flex-col items-center`;
const Description = tw(
  SectionDescription
)`mx-auto font-light text-base text-center text-gray-500 py-4 px-2`;
const FaqsContainer = tw.div`px-2 sm:mt-8 sm:mb-12 w-full flex-1 lg:flex justify-between items-start max-w-screen-2xl mx-auto`;
const FaqsColumn = tw.div`w-full lg:max-w-7xl lg:mr-8 last:mr-0`;
const FAQs = tw.div`select-none cursor-pointer border-b border-stone-300 hover:border-[#ed1c24] transition-colors duration-300 py-6`;
const Question = tw.div`flex justify-between items-center px-2`;
const QuestionText = tw.div`text-base sm:text-lg font-medium text-gray-900 tracking-wide`;
const QuestionToggleIcon = styled(motion.span)`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Answer = tw(
  motion.div
)`hidden text-base font-light leading-7 text-gray-500 mt-4`;
const HighlightedText = tw.span`text-[#ed1c24]`;

const FAQCN = ({
  faqs = [
    {
      question: "你们可以帮助我申请住房贷款吗？",
      answer:
        "当然可以！我们提供多种房屋贷款选择，以适应您独特的需求和预算。我们的专业贷款人员可以帮助您完成申请流程，找到适合您的贷款。今天就与我们联系，开始您的购房之旅吧。",
    },
    {
      question: "我能获得投资物业贷款吗？",
      answer:
        "可以的，我们提供多种投资物业的贷款选择。无论您是想购买单个出租物业还是多个投资物业，我们的专业贷款人员都可以帮助您完成申请流程，找到适合您的贷款。与我们取得联系，探索您的选择吧。",
    },
    {
      question: "我能借多少房屋贷款额度？",
      answer:
        "您可以借多少钱取决于多个因素，包括您的收入、信用评分、债务收入比和您要购买或再融资的财产价值。我们的专业贷款人员可以帮助您评估您的财务状况，并确定您可以借多少钱。请与我们取得联系，了解更多有关您的借款选择。",
    },
    {
      question: "再次固定利息时我应该考虑什么？",
      answer:
        "我们将一对一帮助您重新固定利息，并在再固定利息日期接近时提醒您。我们的专业贷款人员可以帮助您探索选择，找到减少月付款、降低利率和改善您的整体财务状况的方法。",
    },
    {
      question: "如果我遇到财务困难，无法支付我的抵押贷款怎么办？",
      answer:
        "如果您遇到财务困难，重要的是尽快与我们联系。我们可以与您合作探索诸如宽限期、短期纾困贷款、贷款修改或再融资等选项，帮助您守护财产，避免被迫执行抵押贷款。我们的团队将帮助您应对这些困难情况，找到适合您的解决方案。",
    },
    // {
    //   question: "您能帮我选择适合我的KiwiSaver提供商吗？",
    //   answer:
    //     "当然可以。我们的专家团队可以与您合作，评估您的投资目标、风险承受能力和其他因素，帮助您选择适合您需求的KiwiSaver提供商。我们会考虑诸如费用、投资选项和不同提供商的历史记录等因素，以及您认为重要的任何其他考虑因素。",
    // },
    {
      question: "你们如何帮助我处理商业贷款？",
      answer:
        "我们的专家团队可以与您合作评估您的商业贷款需求，帮助您选择适合您业务的贷款产品。我们可以帮助您比较不同的贷款选项，代表您与贷款人谈判，并确保您获得最优惠的条件和利率。我们还将提供贷款过程中的持续支持，帮助您有效管理贷款，并确保您的业务保持在正确的轨道上。",
    },
    {
      question: "我可以寻求什么样的财务建议？",
      answer:
        "我们的金融专家团队可以为您提供一系列针对您个人需求量身定制的财务建议。我们可以帮助您进行预算和储蓄，投资和退休计划等各方面的问题。我们将与您合作评估您的财务目标，并制定定制计划，帮助您实现这些目标。",
    },
  ],
}) => {
  const faqCol1 = [];
  const faqCol2 = [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  faqs.map((faq, index) => {
    const renderedFaq = (
      <FAQs key={index} onClick={() => toggleQuestion(index)}>
        <Question>
          <QuestionText>{faq.question}</QuestionText>
          <QuestionToggleIcon
            variants={{
              collapsed: { rotate: 0 },
              open: { rotate: -180 },
            }}
            initial="collapsed"
            animate={activeQuestionIndex === index ? "open" : "collapsed"}
            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ChevronDownIcon />
          </QuestionToggleIcon>
        </Question>
        <Answer
          variants={{
            open: {
              opacity: 1,
              height: "auto",
              marginTop: "16px",
              display: "block",
            },
            collapsed: {
              opacity: 0,
              height: 0,
              marginTop: "0px",
              display: "none",
            },
          }}
          initial="collapsed"
          animate={activeQuestionIndex === index ? "open" : "collapsed"}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {faq.answer}
        </Answer>
      </FAQs>
    );

    if (index % 2 === 0) faqCol1.push(renderedFaq);
    else faqCol2.push(renderedFaq);
    return null;
  });
  return (
    <Container>
      <ContentWithPaddingSm>
        <HeadingContainer>
          <SectionHeading>
            常见问题<HighlightedText>解答</HighlightedText>
          </SectionHeading>
          <Description>
            没有找到您想知道的的答案？
            <br />
            <a
              href="/contact-us"
              className="font-light text-decoration: underline text-[#ed1c24]"
            >
              请联系我们的专业团队
            </a>
          </Description>
        </HeadingContainer>
        <FaqsContainer>
          <FaqsColumn>{faqCol1}</FaqsColumn>
          <FaqsColumn>{faqCol2}</FaqsColumn>
        </FaqsContainer>
      </ContentWithPaddingSm>
    </Container>
  );
};

export default FAQCN;
