import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QR from "../assets/logo/QR.jpg";

import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";

const offices = [
  {
    id: 1,
    city: "奥克兰东区",
    address: ["1 Millhouse Drive", "Northpark, Auckland 2013"],
  },
];

const toastSuccess = () => {
  toast.success("邮件已发送,我们将尽快联系您。", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    theme: "light",
    toastId: "notifyToastSuccess",
  });
};
const toastFailure = () => {
  toast.error("邮件发送失败, 请再试一次。", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    theme: "light",
    toastId: "notifyToastFailure",
  });
};
const ContactCN = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    user_first_name: "",
    user_last_name: "",
    user_email: "",
    user_phone: "",
    message: "",
  });
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const [sending, setSending] = useState(false);
  const sendEmail = async (e) => {
    e.preventDefault();
    setSending(true); // Set sending status to true

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          toastSuccess();
          // clear the form
          setFormData({
            user_first_name: "",
            user_last_name: "",
            user_email: "",
            user_phone: "",
            subject: "",
            message: "",
          });
          console.log(result.status);
          setSending(false); // Set sending status back to false
        },
        (error) => {
          toastFailure();
          console.log(error.text);
          setSending(false); // Set sending status back to false
        }
      );
  };

  let buttonText = sending ? "发送中，请稍等..." : "发送邮件";

  return (
    <div className="bg-white">
      <head>
        <meta
          name="description"
          content="无论您有什么样的疑问，请尽管与我们联系。你仅需给我们发一个消息, 把困难交给我们替您解决。"
        />
        <meta property="og:site_name" content="联系我们 - 百家信贷" />
        <meta property="og:title" content="联系我们 - 百家信贷" />
        <meta
          property="og:description"
          content="无论您有什么样的疑问，请尽管与我们联系。你仅需给我们发一个消息, 把困难交给我们替您解决。"
        />
        <meta property="og:locale" content="zh-CN" />
        <meta name="twitter:title" content="联系我们 - 百家信贷" />
        <meta
          name="twitter:description"
          content="无论您有什么样的疑问，请尽管与我们联系。你仅需给我们发一个消息, 把困难交给我们替您解决。"
        />
        <title>联系我们 - 百家信贷</title>
        <link rel="canonical" href="https://bicare.co.nz/contact-us" />
      </head>
      <main className="overflow-hidden">
        {/* Header */}
        <div className="py-2 bg-gray-50">
          <div className="relative mx-auto max-w-7xl pl-4 pr-8 sm:px-6 lg:px-8 mt-24 bg-gray-50 ">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
              联系我们
            </h1>
            <p className="mt-6 max-w-3xl text-lg font-light text-gray-500">
              你仅需给我们发一个消息, 把困难交给我们替您解决。
            </p>
          </div>
        </div>
        {/* Contact section */}
        <section
          className="relative bg-gray-50 py-12"
          aria-labelledby="contact-heading"
        >
          <div className="absolute w-full" aria-hidden="true" />
          {/* Decorative dot pattern */}
          <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
            <svg
              className="absolute top-0 right-0 z-0 -translate-y-16 translate-x-1/2 transform sm:translate-x-1/4 md:-translate-y-24 lg:-translate-y-72"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              />
            </svg>
          </div>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="relative bg-white shadow-xl">
              <div className="grid grid-cols-1 lg:grid-cols-3">
                {/* Contact information */}
                <div className="relative overflow-hidden bg-gradient-to-t to-[#c60f16] from-[#ff9ea2] py-10 px-6 sm:px-10 xl:p-12">
                  {/* Decorative angle backgrounds */}
                  <div
                    className="pointer-events-none absolute inset-0 sm:hidden"
                    aria-hidden="true"
                  >
                    <svg
                      className="absolute inset-0 h-full w-full"
                      width={343}
                      height={388}
                      viewBox="0 0 343 388"
                      fill="none"
                      preserveAspectRatio="xMidYMid slice"
                    >
                      <path
                        d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                        fill="url(#linear1)"
                        fillOpacity=".1"
                      />
                      <defs>
                        <linearGradient
                          id="linear1"
                          x1="254.553"
                          y1="107.554"
                          x2="961.66"
                          y2="814.66"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#fff" />
                          <stop offset={1} stopColor="#fff" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div
                    className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 sm:block lg:hidden"
                    aria-hidden="true"
                  >
                    <svg
                      className="absolute inset-0 h-full w-full"
                      width={359}
                      height={339}
                      viewBox="0 0 359 339"
                      fill="none"
                      preserveAspectRatio="xMidYMid slice"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                        fill="url(#linear2)"
                        fillOpacity=".1"
                      />
                      <defs>
                        <linearGradient
                          id="linear2"
                          x1="192.553"
                          y1="28.553"
                          x2="899.66"
                          y2="735.66"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#fff" />
                          <stop offset={1} stopColor="#fff" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div
                    className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 lg:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="absolute inset-0 h-full w-full"
                      width={160}
                      height={678}
                      viewBox="0 0 160 678"
                      fill="none"
                      preserveAspectRatio="xMidYMid slice"
                    >
                      <path
                        d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                        fill="url(#linear3)"
                        fillOpacity=".1"
                      />
                      <defs>
                        <linearGradient
                          id="linear3"
                          x1="192.553"
                          y1="325.553"
                          x2="899.66"
                          y2="1032.66"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#fff" />
                          <stop offset={1} stopColor="#fff" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  {/*<h2 className="text-xl font-light text-white">联系信息</h2>*/}
                  <p className="max-w-3xl text-base font-light text-white">
                    无论您需要贷款、咨询，还是想提供对百家信贷的反馈，我们都欢迎您通过我们的网页联系我们。我们将倾听您的需求，并尽力提供帮助。
                    <br />
                    <br />
                    在此感谢您选择百家信贷！
                  </p>
                  <dl className="mt-8 space-y-6 font-light">
                    <dt>
                      <span className="sr-only">Phone number</span>
                    </dt>
                    <dd className="flex text-base text-white">
                      <PhoneIcon
                        className="h-6 w-6 flex-shrink-0 text-white"
                        aria-hidden="true"
                      />
                      <a className="ml-3" href="tel:096251234">
                        09 625 1234
                      </a>
                    </dd>
                    <dt>
                      <span className="sr-only">Email</span>
                    </dt>
                    <dd className="flex text-base text-white">
                      <EnvelopeIcon
                        className="h-6 w-6 flex-shrink-0 text-white"
                        aria-hidden="true"
                      />
                      <a className="ml-3" href="mailto:info@bicare.co.nz">
                        info@bicare.co.nz
                      </a>
                    </dd>
                  </dl>
                  <div className="mt-8 w-44 h-44">
                    <img
                      src={QR}
                      alt="百家信贷微信公众号QR Code"
                      title="百家信贷微信公众号"
                      loading="lazy"
                    />
                  </div>
                </div>
                {/* Contact form */}
                <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                  <h3 className="text-xl font-light text-gray-900">
                    给我们发消息
                  </h3>
                  <br />
                  <h4 className="text-xs font-light italic text-gray-500">
                    * 必填
                  </h4>
                  <form
                    ref={form}
                    onSubmit={sendEmail}
                    className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                  >
                    <div>
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-light text-gray-900"
                      >
                        名字*
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="user_first_name"
                          id="first-name"
                          autoComplete="given-name"
                          value={formData.user_first_name}
                          onChange={handleInputChange}
                          disabled={sending}
                          className="block w-full rounded-md border-gray-200 border py-3 px-4 text-gray-900 font-light shadow-sm"
                          required
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-light text-gray-900"
                      >
                        姓氏
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="user_last_name"
                          id="last-name"
                          autoComplete="family-name"
                          value={formData.user_last_name}
                          onChange={handleInputChange}
                          disabled={sending}
                          className="block w-full rounded-md border-gray-200 border py-3 px-4 font-light text-gray-900 shadow-sm"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-light text-gray-900"
                      >
                        电子邮件地址*
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="user_email"
                          type="email"
                          autoComplete="email"
                          value={formData.user_email}
                          onChange={handleInputChange}
                          disabled={sending}
                          className="block w-full rounded-md border-gray-200 border py-3 px-4 text-gray-900 font-light shadow-sm"
                          required
                        />
                      </div>
                    </div>
                    <div>
                      <div className="flex justify-between">
                        <label
                          htmlFor="phone"
                          className="block text-sm font-light text-gray-900"
                        >
                          电话号码*
                        </label>
                      </div>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="user_phone"
                          id="phone"
                          autoComplete="tel"
                          value={formData.user_phone}
                          onChange={handleInputChange}
                          disabled={sending}
                          className="block w-full rounded-md border-gray-200 font-light border py-3 px-4 text-gray-900 shadow-sm"
                          required
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="subject"
                        className="block text-sm font-light text-gray-900"
                      >
                        有什么我们可以帮助您的? *
                      </label>
                      <div className="mt-1">
                        <select
                          value={formData.subject}
                          name="subject"
                          id="subject"
                          className="block w-full rounded-md border-gray-200 border py-3 px-4 font-light text-gray-900 shadow-sm"
                        >
                          <option value="">请选择一项</option>
                          <option value="一般咨询">一般咨询</option>
                          <option value="我想申请首套房贷款">
                            我想申请首套房贷款
                          </option>
                          <option value="我想申请投资房贷款">
                            我想申请投资房贷款
                          </option>
                          <option value="我想申请refix">
                            我想申请再固定利息（refix/ refinance）
                          </option>
                          <option value="我想top up">
                            我想增加贷款（top up）
                          </option>
                          <option value="我想买建地">我想买建地</option>
                          <option value="我想自建房">我想自建房</option>
                          <option value="我想购买商业地产">
                            我想购买商业地产
                          </option>
                          <option value="我想购买生意">
                            我想收购企业，购买生意
                          </option>
                          <option value="我要申请资产融资">
                            我要申请资产融资
                          </option>
                          {/*<option value="我想要了解KiwiSaver">*/}
                          {/*  我想要了解KiwiSaver*/}
                          {/*</option>*/}
                          <option value="我需要专业的财务建议">
                            我需要专业的财务建议
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="flex justify-between">
                        <label
                          htmlFor="message"
                          className="block text-sm font-light text-gray-900"
                        >
                          更多信息
                        </label>
                      </div>
                      <div className="mt-1">
                        <textarea
                          id="message"
                          name="message"
                          rows={4}
                          maxLength={140}
                          className="block w-full rounded-md border-gray-200 border py-3 px-4 font-light text-gray-900 shadow-sm"
                          value={formData.message}
                          onChange={handleInputChange}
                          disabled={sending}
                        />
                      </div>
                    </div>
                    <div className="mt-1">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                        size={"small"}
                        onChange={sendEmail}
                      />
                    </div>
                    <div className="mt-1 flex justify-end">
                      <input
                        type="submit"
                        value={buttonText}
                        disabled={sending}
                        onClick={() => this.setState({ fade: true })}
                        onAnimationEnd={() => this.setState({ fade: false })}
                        className="place-items-end  rounded-3xl h-12 border border-transparent bg-[#ed1c24] px-8 py-3 text-base font-medium text-white hover:scale-105 shadow-sm sm:w-auto"
                      ></input>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Contact grid offices */}
        <section aria-labelledby="offices-heading">
          <div className="mx-auto max-w-7xl py-12 px-6 sm:py-16 lg:px-8">
            <h2
              id="offices-heading"
              className="text-3xl font-bold tracking-tight text-gray-900"
            >
              办公地址
            </h2>
            <p className="mt-6 max-w-7xl font-light text text-gray-500">
              我们欢迎您随时预约时间与我们面谈您的贷款需求。
            </p>
            <div className="mt-10 grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-4">
              {offices.map((office) => (
                <div key={office.id}>
                  <h3 className="text-base font-medium text-gray-700">
                    {office.city}
                  </h3>
                  <p className="mt-2 text-base font-light text-gray-500">
                    {office.address.map((line) => (
                      <span key={line} className="block">
                        {line}
                      </span>
                    ))}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
      <ToastContainer />
    </div>
  );
};

export default ContactCN;
