import React from "react";
import tw from "twin.macro";
import { ReactTyped } from "react-typed";
import Lottie from "lottie-react";
import hero from "../assets/hero/hero";
import { ArrowSmallRightIcon } from "@heroicons/react/24/outline";
const Main = tw.div`relative`;
const Container = tw.div`mx-auto max-w-screen-xl max-h-screen items-center grid md:grid-cols-2 py-24`;
const TextContainer = tw.div`flex flex-col mx-auto justify-center mt-4`;
const TextContent = tw.div`md:text-7xl sm:text-6xl text-4xl font-bold py-4 text-gray-900 lg:text-left text-center`;
const TextContent1 = tw.div`md:text-5xl sm:text-4xl text-xl font-bold py-4 text-gray-900 lg:text-left text-center`;
const ButtonRow = tw.div`mt-5 flex space-x-6 lg:justify-start sm:justify-center`;
const Button = tw.button`bg-[#ed1c24] text-white w-36 py-4 rounded-3xl hover:scale-105`;
const ButtonWithIcon = tw.button`text-gray-900 w-36 py-4 rounded-3xl hover:scale-105 flex`;
const ImageContainer = tw.div`mx-auto overflow-hidden`;
const Hero = () => {
  return (
    <Main>
      <Container>
        <TextContainer>
          <TextContent>Loans Made Easy.</TextContent>
          <TextContent1>Reliable financial services</TextContent1>
          <TextContent1>
            for
            <ReactTyped
              className="md:text-5md sm:text-4xl text-xl font-light md:pl-4 pl-2 text-gray-900"
              strings={[
                "HOME LOANS",
                "COMMERCIAL LOANS",
                "CONSTRUCTION LOANS",
                "ASSET FINANCING",
                "REFINANCING",
                // "KIWISAVER",
              ]}
              typeSpeed={60}
              backSpeed={50}
              loop
            ></ReactTyped>
          </TextContent1>
          <ButtonRow>
            <Button>
              <a href="/contact-us">Get in Touch</a>
            </Button>
            <ButtonWithIcon>
              <a href="/booking">Book A Chat</a>
              <ArrowSmallRightIcon className="w-5 ml-2" />
            </ButtonWithIcon>
          </ButtonRow>
        </TextContainer>
        <ImageContainer>
          <Lottie animationData={hero} loop={true} height={600} mwidth={600} />
        </ImageContainer>
      </Container>
    </Main>
  );
};

export default Hero;
