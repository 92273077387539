import React from "react";
import { Container, ContentWithPaddingXl } from "../components/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "../components/Headings";

const Content = tw(ContentWithPaddingXl)`px-6`;
const HeadingRow = tw.div`flex mt-10`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Bold = tw.span`font-bold font-sans`;
const Text = styled.div`
  ${tw`text-base font-light text-gray-900`}
  p {
    ${tw`mt-2 font-light leading-loose`}
  }
  h1 {
    ${tw`text-3xl lg:text-2xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl lg:text-xl font-bold mt-10`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-2 list-disc font-light`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const Disclosure = ({
  headingText = "Financial Advice Provider Disclosure Statement",
}) => {
  return (
    <Container>
      <Content>
        <HeadingRow>
          <Heading>{headingText}</Heading>
        </HeadingRow>
        <Text>
          <h1>Disclosure</h1>
          <p>
            Here is some general information about our business to help you
            decide if you want to seek our advice.
          </p>
          <p>Company Name: BICARE FINANCE LTD</p>
          <p>FSP Number: 636249</p>
          <p>Phone Number: 09 625 1234</p>
          <p>Email Address: info@bicare.co.nz</p>
          <h1>Licensing information</h1>
          <p>
            We operate as a Financial Advice Provider under a current licence
            issued by the Financial Markets Authority in the name of NZ
            Financial Services Group Limited (FSP286965)
          </p>
          <p>
            There are no conditions attached to this licence on the advice that
            may be given.
          </p>
          <h1>Our advice and Product Providers</h1>
          <p>We provide advice to our clients Home Loan products.</p>
          <h1>Commission</h1>
          <p>
            On settlement of <Bold>a loan ,</Bold> we usually receive commission
            from the applicable product provider. The commission is generally of
            an upfront nature but may also include a renewal or trail
            commission.{" "}
            <Bold>
              We also receive a ﬁxed rate roll over fee from some product
              providers if we assist in reﬁnancing your loan.
            </Bold>
          </p>
          <p>
            This commission is used to remunerate the ﬁnancial adviser that
            provides the advice, and to pay the expenses associated with running
            our business including any rent, staff costs and IT resources. From
            this commission we also pay NZ Financial Services Group Limited for
            services they provide to us in connection with our authorisation
            under their licence from the Financial Markets Authority.
          </p>
          <p>
            We take steps to ensure that the receipt of commissions does not
            inﬂuence the advice we give to you and that our advisers prioritise
            your interests by recommending the best product for your purpose
            regardless of the type and amount of commission we or they may
            receive.
          </p>
          <p>We do this by:</p>
          <ul>
            <li>
              Ensuring our advisers follow an advice process that ensures they
              understand your needs and goals and that their recommendations on
              insurance cover meets those needs and goals.
            </li>
            <li>
              Ensuring our advisers receive regular training on how to manage
              the conﬂicts of interest.
            </li>
            <li>
              Providing you with a schedule showing commission amounts and types
              by product provider. This schedule is contained within each
              Financial Adviser’s personalised Disclosure Guide. A Financial
              Adviser will provide you with more information about commissions
              during the advice process.
            </li>
          </ul>
          <h1>Fees and Expenses</h1>
          <p>
            Generally we don’t charge you any fee for the advice and
            transactional solutions that we provide to you. This is possible
            because, on settlement of a <Bold>mortgage,</Bold> we usually
            receive commission from the applicable product provider as described
            above. There are two exceptions to this general position which are
            explained below.
          </p>
          <p>We may charge you a one-off fee in the following situations:</p>
          <p>
            a) No commission: If you request that we provide services in
            relation to a product or service and we do not receive a commission.
            Any such fee would be agreed and authorized by you in writing before
            we complete the services, and would be based on an estimate of the
            time spent providing the advice.
          </p>
          <p>
            This may arise in the rare event that you request that we provide
            Services in relation to either a product that is offered by a
            provider that we do not hold an accreditation with, or a product
            that is outside our usual arrangements with our product providers.
          </p>
          <p>
            b) Repayment of commission: If a product or service provider
            requires that we repay commission within 28 months of settlement of
            your mortgage or issuance of your risk insurance policy. Any such
            fee would be no more than $3,000 (plus GST) and would be calculated
            based on a rate of $250 (plus GST) per hour of the ﬁnancial
            adviser’s time spent providing services to you in connection with
            the applicable mortgage or insurance. The fee charged will not
            exceed the amount of commission clawed back from the lender.
          </p>
          <p>
            Should we need to charge you a fee, you will be invoiced and will be
            given 30 days to make payment.
          </p>
          <h1>Conﬂicts of Interest</h1>
          <p>
            If there are any conﬂicts of interest apart from commission that
            could potentially inﬂuence the advice that we give, these will be
            shown in your Financial Adviser’s Disclosure Guide
          </p>
          <h1>Complaints</h1>
          <p>
            If you have a complaint about our service you need to tell us about
            it. You can contact our internal complaints service by phoning us on{" "}
            <Bold>09 625 1234</Bold> or emailing us at{" "}
            <Bold>info@bicare.co.nz</Bold> with the heading Complaint – Bicare
            Finance Ltd.
          </p>
          <p>
            Please set out the nature of your complaint, and the resolution you
            are seeking. We will acknowledge receipt of this within 24 hours. We
            will then record your complaint in our Complaints Register and
            notify our License Holder. We may want to meet with you to better
            understand your issues. We will then investigate your complaint and
            provide a response to you within 7 working days of receiving your
            complaint. If we need more time to investigate your complaint, we’ll
            let you know.
          </p>
          <p>
            If we cannot agree on a resolution you can refer your complaint to
            our external dispute resolution service. This service is independent
            and will cost you nothing and will assist us to resolve things with
            you.
          </p>
          <p>Details of this service are:</p>
          <p>Financial Services Complaints Limited Phone: 0800 347 257</p>
          <p>Email: complaints@fscl.org.nz</p>
          <h1>Our Duties</h1>
          <p>
            Anyone within our business giving advice is bound by and supports
            the duties set out in the Financial Markets Conduct Act 2013.
          </p>
          <p>These duties are:</p>
          <ul>
            <li>
              Meet the standards of competence, knowledge, and skill and the
              standards of ethical behaviour, conduct, and client care set out
              in the Code of Professional Conduct for Financial Advice
              Providers.
            </li>
            <li>Give priority to your interests.</li>
            <li>Exercise care, diligence, and skill.</li>
          </ul>
        </Text>
      </Content>
    </Container>
  );
};

export default Disclosure;
