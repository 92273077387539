import React from "react";
import advice from "../../assets/services/advice.jpg";
import tw from "twin.macro";

const Main = tw.div`relative`;
const Container = tw.div`mx-auto max-w-2xl py-24 px-4 sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8`;
const HeadingSection = tw.div`mx-auto max-w-3xl text-center`;
const Heading = tw.h1`text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl`;
const SubheadingSection = tw.div`border-b-2 border-gray-200 pb-4`;
const Subheading = tw.h2`text-lg tracking-tight text-gray-900`;
const TwoColumn = tw.div`grid grid-cols-1 gap-y-20 gap-x-8 lg:grid-cols-12 mt-16`;
const TextColumn = tw.div`lg:col-span-7`;
const ImageColumn = tw.div`lg:col-span-5`;
const DescriptionSection = tw.p`pt-6 space-y-2`;
const Description = tw.p`text-base tracking-tight text-gray-600`;
const Image = tw.img`h-full w-full object-cover object-center`;
const Link = tw.a`hover:text-[#ed1c24] underline decoration-solid underline-offset-2`;

const FinancialAdvice = () => {
  return (
    <Main>
      <head>
        <meta
          name="description"
          content="我们的团队成员均持有相关的投资牌照，能够为客户提供专业的投资建议和服务。我们可以帮助您评估潜在的投资机会，并提供针对不同投资类型和风险水平的合适的贷款选项。"
        />
        <meta property="og:site_name" content="专业房产理财建议 - 百家信贷" />
        <meta property="og:title" content="专业房产理财建议 - 百家信贷" />
        <meta
          property="og:description"
          content="我们的团队成员均持有相关的投资牌照，能够为客户提供专业的投资建议和服务。我们可以帮助您评估潜在的投资机会，并提供针对不同投资类型和风险水平的合适的贷款选项。"
        />
        <meta property="og:locale" content="zh-CN" />
        <meta name="twitter:title" content="专业房产理财建议 - 百家信贷" />
        <meta
          name="twitter:description"
          content="我们的团队成员均持有相关的投资牌照，能够为客户提供专业的投资建议和服务。我们可以帮助您评估潜在的投资机会，并提供针对不同投资类型和风险水平的合适的贷款选项。"
        />
        <title>专业房产理财建议 - 百家信贷</title>
        <link rel="canonical" href="https://bicare.co.nz/financial-advice" />
      </head>
      <Container>
        <HeadingSection>
          <Heading>专业房产理财建议</Heading>
        </HeadingSection>
        <TwoColumn>
          {/*image*/}
          <ImageColumn>
            <div className="aspect-w-7 aspect-h-5 overflow-hidden rounded-lg">
              <Image
                src={advice}
                alt="专业的贷款机构，金融理财建议，房产理财建议，奥克兰，新西兰，百家信贷"
                className="h-full w-full object-cover object-center"
                loading="lazy"
                title="金融理财建议"
              />
            </div>
          </ImageColumn>
          {/*text*/}
          <TextColumn>
            <SubheadingSection>
              <Subheading>您需要专业的房产理财建议吗？</Subheading>
            </SubheadingSection>
            <DescriptionSection>
              <Description>
                百家信贷作为一家专业的贷款机构，不仅具有多年的房产理财经验和广泛的合作伙伴网络，还提供全面的金融服务，以帮助客户在各个领域取得成功。
              </Description>
              <Description>
                我们的专业房产理财建议包括但不限于以下几点：首先，了解您的投资目标和风险承受能力，以制定最佳的投资策略。其次，我们将根据当前的市场趋势和未来的增长前景，为您提供有针对性的投资建议。
              </Description>
              <Description>
                我们的团队成员均持有相关的投资牌照，能够为客户提供专业的投资建议和服务。我们可以帮助您评估潜在的投资机会，并提供针对不同投资类型和风险水平的合适的贷款选项。
              </Description>
              <Description>
                此外，我们还为客户提供全面的房地产市场研究和分析，以确保您做出的投资决策是明智和可行的。我们还可以帮助您了解当地的法律法规，以确保您的投资符合法律规定。
              </Description>
              <Description>
                如果您需要更多的更有针对性的理财建议，请
                <Link href="/contact-us">随时联系</Link>
                我们的团队成员。
              </Description>
            </DescriptionSection>
          </TextColumn>
        </TwoColumn>
      </Container>
    </Main>
  );
};
export default FinancialAdvice;
