import React from "react";
import Table, { AvatarCell } from "../../components/Table";
import data from "./ratesData";
import tw from "twin.macro";
import repayment from "../../assets/calculator/repayment.jpg";
import borrow from "../../assets/calculator/borrowing.jpg";
import paye from "../../assets/calculator/paye.jpg";

const Main = tw.div`relative`;
const Container = tw.div`mx-auto max-w-2xl py-20 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8`;
const HeadingSection = tw.div`max-w-3xl mt-12`;
const Heading = tw.h1`flex flex-col text-3xl  font-bold text-gray-800 mb-2 mt-5`;
const Heading2 = tw.h2`flex flex-col text-3xl font-bold text-gray-800 mb-2`;
const HeadingDescription = tw.p`flex flex-col text-base font-light text-gray-500 mt-5`;
const HeadingSubDescription = tw.p`flex flex-col text-xs font-light italic text-gray-500 mt-5`;
const TableSection = tw.div`mt-6`;
const MoreCalculators = tw.div`mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8`;
const MoreCalculatorsContainer = tw.div`aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-2xl shadow-xl bg-gray-100 group-hover:opacity-75 lg:aspect-none lg:h-80`;
const MoreCalculatorsImage = tw.img`object-cover object-center lg:h-full lg:w-full`;
const H3 = tw.h3`mt-4 text-base text-gray-700 text-center`;

const getFormattedDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1; // Months are zero-indexed in JavaScript
  const day = today.getDate();

  return `${year}年${month}月${day}日`;
};
function RatesCompare() {
  const columns = [
    {
      Header: "借款机构",
      // accessor: "lender",
      Cell: AvatarCell,
      imgAccessor: "img",
    },
    {
      Header: "浮动利率",
      accessor: "floating",
    },
    {
      Header: "1年",
      accessor: "1-year",
    },
    {
      Header: "2年",
      accessor: "2-year",
    },
    {
      Header: "3年",
      accessor: "3-year",
    },
    {
      Header: "4年",
      accessor: "4-year",
    },
    {
      Header: "5年",
      accessor: "5-year",
    },
  ];

  const calculators = [
    {
      id: 1,
      name: "还款计算器",
      href: "/repayment-calculator",
      imageSrc: repayment,
      imageAlt: "还款计算器，月供，奥克兰抵押经纪人",
    },
    {
      id: 2,
      name: "贷款计算器",
      href: "/borrowing-calculator",
      imageSrc: borrow,
      imageAlt: "借款计算器，我可以借多少钱？奥克兰抵押经纪人",
    },
    {
      id: 3,
      name: "PAYE新西兰工资税计算器",
      href: "/paye-calculator",
      imageSrc: paye,
      imageAlt: "我的税后工资是多少？奥克兰抵押经纪人",
    },
  ];

  return (
    <Main>
      <head>
        <meta
          name="description"
          content="比较各大银行的利息，选择最优惠的利率会让您减少利息费用，从而为您节省更多的资金，并且在贷款期限内更容易还清债务。"
        />
        <meta property="og:site_name" content="各银行贷款利息比较" />
        <meta property="og:title" content="各银行贷款利息比较" />
        <meta
          property="og:description"
          content="比较各大银行的利息，选择最优惠的利率会让您减少利息费用，从而为您节省更多的资金，并且在贷款期限内更容易还清债务。"
        />
        <meta property="og:locale" content="zh-CN" />
        <meta name="twitter:title" content="各银行贷款利息比较" />
        <meta
          name="twitter:description"
          content="比较各大银行的利息，选择最优惠的利率会让您减少利息费用，从而为您节省更多的资金，并且在贷款期限内更容易还清债务。"
        />
        <link rel="canonical" href="https://bicare.co.nz/rates-compare" />
        <title>各银行贷款利息比较 - 百家信贷</title>
      </head>
      <Container>
        <Heading>金融计算器 - 各银行房贷利息比较</Heading>
        <HeadingDescription>
          选择最优惠的利率会让您减少利息费用，从而为您节省更多的资金，并且在贷款期限内更容易还清债务。
        </HeadingDescription>
        <HeadingSubDescription>
          数据来自https://www.interest.co.nz，利率准确截至{getFormattedDate()}。
        </HeadingSubDescription>
        <TableSection>
          <Table columns={columns} data={data} />
        </TableSection>
        <HeadingSection>
          <Heading2>更多金融计算器.</Heading2>
          <HeadingDescription>
            无论您需要使用抵押贷款计算器来计算您的借款能力、抵押贷款还款额或税后收入，我们都为您准备好了。
          </HeadingDescription>
        </HeadingSection>
        <MoreCalculators>
          {calculators.map((calculator) => (
            <a
              key={calculator.id}
              href={calculator.href}
              className="group relative"
            >
              <MoreCalculatorsContainer>
                <MoreCalculatorsImage
                  src={calculator.imageSrc}
                  alt={calculator.imageAlt}
                  title={calculator.name}
                  loading="lazy"
                ></MoreCalculatorsImage>
              </MoreCalculatorsContainer>
              <H3>{calculator.name}</H3>
            </a>
          ))}
        </MoreCalculators>
      </Container>
    </Main>
  );
}

export default RatesCompare;
