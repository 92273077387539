import React from "react";
import error from "../assets/error/multitasking.json";
import Lottie from "lottie-react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import {
  ChatBubbleLeftEllipsisIcon,
  HomeIcon,
  CalculatorIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";

const links = [
  {
    title: "住房贷款",
    description:
      "想要拥有您梦想中的房子？我们可以帮助您获得适合您的预算和财务目标的合适的房屋贷款。",
    icon: HomeIcon,
    url: "/home-loans",
  },

  {
    title: "建房开发融资",
    description:
      "如果您是打算入行，或者正在开发中，我们都将竭诚为开发商朋友提供全方位的服务和支持。",
    icon: CurrencyDollarIcon,
    url: "/apply",
  },
  {
    title: "PAYE计算器",
    description: "利用我们的金融工具查看税后工资，帮您计划投资，管理债务。",
    icon: CalculatorIcon,
    url: "/paye-calculator",
  },
  {
    title: "联系我们",
    description: "如果您需要任何帮助或者有任何疑问，请随时联系我们。",
    icon: ChatBubbleLeftEllipsisIcon,
    url: "/contact-us",
  },
];

export default function Error() {
  return (
    <div className="bg-white mx-auto w-full max-w-7xl px-6 lg:px-8">
      {/*headers*/}
      <div className="mx-auto max-w-xl py-8">
        <div className="text-center">
          <p className="text-3xl font-semibold text-[#ed1c24] mt-24">404</p>
          <h1 className="mt-4 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            抱歉，这个页面不存在。
          </h1>
          <p className="mt-4 text-lg font-light text-gray-500">
            您访问的页面出错了，或者已经被删除了。
          </p>
        </div>
      </div>
      <div className="mb-6 grid md:grid-cols-12 justify-center">
        <div className="mt-12 col-span-7">
          <h2 className="text-lg text-gray-700 font-normal">常用页面</h2>
          <div
            role="list"
            className="mt-4 divide-y divide-gray-200 border-t border-b border-gray-200"
          >
            {links.map((link, linkIdx) => (
              <div
                key={linkIdx}
                className="relative flex items-start space-x-4 py-6"
              >
                <div className="flex-shrink-0">
                  <span className="flex h-12 w-12 items-center justify-center rounded-lg bg-[#fff1f2]">
                    <link.icon
                      className="h-6 w-6 text-[#ed1c24]"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="min-w-0 flex-1">
                  <h3 className="text-base font-normal text-gray-900 hover:text-[#ed1c24] hover:underline underline-offset-2">
                    <span className="rounded-sm">
                      <a href={link.url} className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true" />
                        {link.title}
                      </a>
                    </span>
                  </h3>
                  <p className="text-base font-light text-gray-500">
                    {link.description}
                  </p>
                </div>
                <div className="flex-shrink-0 self-center">
                  <ChevronRightIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="mt-8">
            <a
              href="/"
              className="text-base font-light text-gray-900 hover:text-[#ed1c24] text-decoration: underline"
            >
              回主页
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </div>
        </div>
        <Lottie
          className="col-span-5"
          animationData={error}
          loop={true}
          height={300}
          mwidth={300}
        />
      </div>
    </div>
  );
}
