import React from "react";
import pic from "../assets/form/form.json";
import tw from "twin.macro";
import Lottie from "lottie-react";
import applicationForm from "../assets/form/BicareLoanApplicationForm.pdf";
import { DocumentTextIcon } from "@heroicons/react/24/outline";

const Main = tw.div`relative`;
const Container = tw.div`mx-auto max-w-2xl py-24 px-4 sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8`;
const HeadingSection = tw.div`mx-auto max-w-3xl text-center`;
const Heading = tw.h1`text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl`;
const Subheading = tw.h2`text-base tracking-tight text-gray-600 mt-4`;
const TwoColumn = tw.div`grid grid-cols-1 gap-y-20 gap-x-8 lg:grid-cols-12 mt-16`;
const TextColumn = tw.div`lg:col-span-7`;
const ImageColumn = tw.div`lg:col-span-5`;
const ListBox = tw.div`mt-4 divide-y divide-gray-200 border-t border-b border-gray-200`;
const List = tw.div`relative flex items-start space-x-4 py-6`;
const Link = tw.a`text-lg text-gray-900 hover:text-[#ed1c24]`;
const IconDiv = tw.div`flex-shrink-0`;
const Icon = tw(DocumentTextIcon)`h-6 w-6 text-[#ed1c24]`;
const TextDiv = tw.div`min-w-0 flex-1`;
const Description = tw.div`mt-2 text-sm text-gray-500`;
const description =
  "感谢您选择百家信贷作为您服务！为了进一步处理您的贷款申请，我们需要您填写我们的贷款申请表。请下载并填写附加的贷款申请表格，该表格包含了您需要提供的所有必要信息。请注意，填写申请表格时，请务必填写完整并准确地提供所需信息，以加快贷款处理过程并提高您贷款获批的机率。一旦您完成填写申请表格，您可以联系我们，我们将会在最短时间内与您联系，让您了解您的申请状态以及可能需要额外的信息。";

const items = [
  {
    id: 1,
    src: applicationForm,
    title: "百家信贷贷款申请表",
    description: "请您下载并填写表格,如有任何疑问，请随时联系我们。",
  },
  // {
  //   id: 2,
  //   src: applicationForm,
  //   title: "百家信贷另外一个文件",
  //   description: "另外一个文件另外一个文件另外一个文件",
  // },
];
const FormCN = () => {
  return (
    <Main>
      <head>
        <meta
          name="description"
          content="为了进一步处理您的贷款申请，我们需要您填写我们的贷款申请表，并在填写完成后与我们联系"
        />
        <meta property="og:site_name" content="贷款申请表 - 百家信贷" />
        <meta property="og:title" content="贷款申请表 - 百家信贷" />
        <meta
          property="og:description"
          content="为了进一步处理您的贷款申请，我们需要您填写我们的贷款申请表，并在填写完成后与我们联系"
        />
        <meta property="og:locale" content="zh-CN" />
        <meta name="twitter:title" content="贷款申请表 - 百家信贷" />
        <meta
          name="twitter:description"
          content="为了进一步处理您的贷款申请，我们需要您填写我们的贷款申请表，并在填写完成后与我们联系。"
        />
        <title>贷款申请表 - 百家信贷</title>
        <link rel="canonical" href="https://bicare.co.nz/application-form" />
      </head>
      <Container>
        <HeadingSection>
          <Heading>贷款申请表</Heading>
          <Subheading>{description}</Subheading>
        </HeadingSection>
        <TwoColumn>
          {/*text*/}
          <TextColumn>
            <ListBox role="list">
              {items.map((item) => (
                <List key={items.id}>
                  <IconDiv>
                    <Icon />
                  </IconDiv>
                  <TextDiv>
                    <div>
                      <Link target="_blank" href={item.src}>
                        {item.title}
                      </Link>
                    </div>
                    <Description>{item.description}</Description>
                  </TextDiv>
                </List>
              ))}
            </ListBox>
          </TextColumn>
          {/*image*/}
          <ImageColumn>
            <Lottie
              className="object-cover object-center"
              animationData={pic}
              loop={true}
              height={400}
              mwidth={400}
            />
          </ImageColumn>
        </TwoColumn>
      </Container>
    </Main>
  );
};
export default FormCN;
