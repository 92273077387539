import React from "react";
import tw from "twin.macro";
import award from "../assets/company/award.jpg";
import office1 from "../assets/company/office1.jpg";
import office2 from "../assets/company/office2.jpg";
const Main = tw.div`relative`;
const Container = tw.div`mx-auto max-w-2xl py-20 px-4 sm:py-28 sm:px-6 lg:max-w-7xl lg:px-8`;
const TwoColumn = tw.div`grid grid-cols-1 gap-y-20 gap-x-8 lg:grid-cols-12 mt-16`;
const TextColumn = tw.div`lg:col-span-7`;
const ImageColumn = tw.div`lg:col-span-5`;
const HighlightedText = tw.span`text-[#ed1c24]`;
const Company = () => {
  return (
    <Main>
      <Container>
        <TwoColumn>
          <TextColumn>
            <h1 className="text-6xl font-bold px-6 mt-2">
              About <HighlightedText>Bicare</HighlightedText>
            </h1>
            <h2 className="text-gray-500 text-lg py-10 mx-auto px-6">
              <p>We're part of NZFSG and offer a range of mortgage services.</p>
              <br />
              <p>
                Our experienced loan specialists can help you with residential,
                commercial, construction, and farm loans.
              </p>
              <br />
              <p>
                As an independent intermediary, we can represent your interests
                and tailor our services to meet your needs.
              </p>
              <br />
              <p>
                We have a wide network of partners, including major banks like
                ANZ, ASB, Westpac, and BNZ.
              </p>
              <br />
              <p>
                We analyze your financial situation and loan requirements and
                help you choose the best solution, without pushing any specific
                financial products.
              </p>
              <br />
              <p>
                We have fast communication with banks and loan providers,
                allowing us to quickly obtain special offers and discounts for
                our clients. Our services are free, except for special
                agreements.
              </p>
            </h2>
          </TextColumn>
          <ImageColumn>
            <div className="aspect-w-9 aspect-h-5 overflow-hidden rounded-lg bg-gray-100">
              <img
                src={office1}
                alt="Bicare, trusted mortgage broker in auckland new zealand."
                className="h-full w-full object-cover object-center"
                title="Bicare trusted mortgage broker in auckland new zealand."
                loading="lazy"
              />
            </div>
            <div className="mt-4 grid grid-cols-7 gap-4 sm:mt-6 sm:gap-6 lg:mt-8 lg:gap-8">
              <div className="aspect-w-3 aspect-h-4 col-span-4 overflow-hidden rounded-lg bg-gray-100">
                <img
                  src={award}
                  alt="Bicare award winning mortgage broker in auckland new zealand."
                  className="h-full w-full object-cover object-center"
                  title="Bicare award winning mortgage broker in auckland new zealand."
                  loading="lazy"
                />
              </div>
              <div className="aspect-w-1 aspect-h-2 col-span-3 overflow-hidden rounded-lg bg-gray-100">
                <img
                  src={office2}
                  alt="Bicare north shore mortgage broker in auckland new zealand."
                  className="h-full w-full object-cover object-center"
                  title="Bicare north shore mortgage broker in auckland new zealand."
                  loading="lazy"
                />
              </div>
            </div>
          </ImageColumn>
        </TwoColumn>
      </Container>
    </Main>
  );
};

export default Company;
