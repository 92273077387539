import React, { Fragment } from "react";
import { Popover, Disclosure, Transition } from "@headlessui/react";

import {
  WrenchScrewdriverIcon,
  BanknotesIcon,
  Bars3Icon,
  BuildingOffice2Icon,
  ChevronUpIcon,
  ChatBubbleBottomCenterTextIcon,
  HomeIcon,
  ChevronDownIcon,
  XMarkIcon,
  CalendarDaysIcon,
  CurrencyDollarIcon,
  ReceiptPercentIcon,
  CalculatorIcon,
  PhoneIcon,
  CalendarIcon,
  // PuzzlePieceIcon,
} from "@heroicons/react/24/outline";
import LogoCN from "../assets/logo/logo-cn.png";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const services = [
  {
    name: "住房贷款",
    description:
      "我们为首次购房者和投资者提供房屋贷款服务，提供个性化的服务和定制的解决方案，帮助您实现拥有房产的梦想。",
    href: "/home-loans",
    icon: HomeIcon,
  },
  {
    name: "商业物业贷款",
    description:
      "我们的商业贷款方案提供有竞争力的利率、灵活的还款期限和个性化的服务，以满足您的财务需求，帮助您实现商业目标。",
    href: "/commercial-loans",
    icon: BuildingOffice2Icon,
  },
  {
    name: "建房开发融资",
    description:
      "无论你是打算入行，还是正在开发，我们都将为您提供1对1服务，帮您量身打造最合适的贷款产品。",
    href: "/construction-loans",
    icon: WrenchScrewdriverIcon,
  },
  {
    name: "资产融资",
    description:
      "我们提供多种资产融资解决方案，帮助您获得所需资金，以助您的业务成功发展。",
    href: "/asset-finance",
    icon: BanknotesIcon,
  },
  // {
  //   name: "KiwiSaver投资养老金",
  //   description:
  //     "我们了解KiwiSaver市场的趋势和最佳实践，可以帮助您制定合适的退休储蓄计划。",
  //   href: "/kiwisaver",
  //   icon: PuzzlePieceIcon,
  // },
  {
    name: "专业房产理财建议",
    description:
      "我们的团队拥有丰富的经验和专业知识，可以帮助您制定合适的投资策略和计划.",
    href: "/financial-advice",
    icon: ChatBubbleBottomCenterTextIcon,
  },
];
const calculators = [
  {
    name: "还款计算器",
    description:
      "通过输入贷款金额、利率和还款期限等信息，您可以得出每月需要还款的金额和贷款总利息。",
    href: "/repayment-calculator",
    icon: CalendarDaysIcon,
  },
  {
    name: "贷款计算器",
    description: "通过输入收入，日常花销，轻松地计算出您的大致贷款额度。",
    href: "/borrowing-calculator",
    icon: CalculatorIcon,
  },
  {
    name: "PAYE新西兰工资税计算器",
    description:
      "只需输入您的工资总额、税前扣除项和其他相关信息，我们的计算器就会自动计算出您每月实际可支配的工资。",
    href: "/paye-calculator",
    icon: CurrencyDollarIcon,
  },
  {
    name: "各银行贷款利息比较",
    description:
      "我们提供各大银行的利息比较服务，以帮助您做出最明智的贷款选择。",
    href: "/rates-compare",
    icon: ReceiptPercentIcon,
  },
];
function NavbarCN(props) {
  return (
    <Disclosure
      as="nav"
      className="w-full bg-white bg-opacity-95 border-b-0 border-gray-70 backdrop-filter fixed z-10"
    >
      {({ open }) => (
        <>
          <div className="px-2 sm:px-6 lg:px-8 border-b border-gray-200">
            <div className="relative flex h-20 justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button
                  className="inline-flex items-center justify-center rounded-md p-2 rounded-lg p-2 hover:bg-gray-200"
                  aria-label="MobileMenu"
                >
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                {/*logo*/}
                <div className="flex flex-shrink-0 items-center">
                  <a href="/" aria-label="bicare">
                    <img
                      className="bloc/k h-20 w-20 lg:hidden"
                      title="百家信贷新西兰贷款经纪公司"
                      src={LogoCN}
                      alt="百家信贷logo，新西兰贷款经纪公司"
                      loading="lazy"
                    />
                  </a>
                  <a href="/" aria-label="bicare">
                    <img
                      className="hidden h-20 w-20 lg:block"
                      title="百家信贷新西兰贷款经纪公司"
                      src={LogoCN}
                      alt="百家信贷logo，新西兰贷款经纪公司"
                      loading="lazy"
                    />
                  </a>
                </div>
                {/*navlinks*/}
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8 ">
                  {/*services popover*/}
                  <Popover.Group
                    as="div"
                    className="font-light inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-md text-gray-900 hover:text-[#ed1c24]"
                  >
                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button
                            className={classNames(
                              open ? "text-stone-800" : "text-stone-800",
                              "group inline-flex items-center rounded-md border-none text-base focus:outline-none focus:scale-105 hover:text-[#ed1c24]"
                            )}
                          >
                            <span>服务项目</span>
                            <ChevronDownIcon
                              className={classNames(
                                open ? "text-gray-500" : "text-gray-400",
                                "ml-1 h-5 w-5 group-hover:text-[#ed1c24]"
                              )}
                              aria-hidden="true"
                            />
                          </Popover.Button>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute z-10 -ml-4 mt-6 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-16 lg:-translate-x-1/2">
                              <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                  {services.map((item) => (
                                    <a
                                      key={item.name}
                                      href={item.href}
                                      className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-100"
                                    >
                                      <item.icon
                                        className="h-12 w-12 items-center flex-shrink-0 border-8 border-[#fff1f2] bg-[#fff1f2] rounded-lg text-[#ed1c24] text-base font-light"
                                        aria-hidden="true"
                                      />
                                      <div className="ml-4">
                                        <p className="text-base font-normal text-gray-900">
                                          {item.name}
                                        </p>
                                        <p className="mt-1 text-sm text-gray-500">
                                          {item.description}
                                        </p>
                                      </div>
                                    </a>
                                  ))}
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </Popover.Group>
                  <a
                    href="/application-form"
                    className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-md font-light text-gray-900 hover:text-[#ed1c24] hover:scale-105"
                  >
                    贷款申请表
                  </a>
                  <Popover.Group
                    as="div"
                    className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-md font-light text-gray-900 hover:text-[#ed1c24]"
                  >
                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button
                            className={classNames(
                              open ? "text-stone-800" : "text-stone-800",
                              "group inline-flex items-center rounded-md border-none text-base focus:outline-none focus:scale-105 hover:text-[#ed1c24] hover:scale-105"
                            )}
                          >
                            <span>金融计算器</span>
                            <ChevronDownIcon
                              className={classNames(
                                open ? "text-gray-500" : "text-gray-400",
                                "ml-1 h-5 w-5 group-hover:text-[#ed1c24]"
                              )}
                              aria-hidden="true"
                            />
                          </Popover.Button>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute z-10 -ml-4 mt-6 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                              <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                  {calculators.map((item) => (
                                    <a
                                      key={item.name}
                                      href={item.href}
                                      className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-100"
                                    >
                                      <item.icon
                                        className="h-12 w-12 items-center flex-shrink-0 border-8 border-[#fff1f2] bg-[#fff1f2] rounded-lg text-[#ed1c24] text-base font-light"
                                        aria-hidden="true"
                                      />
                                      <div className="ml-4">
                                        <p className="text-base font-normal text-gray-900">
                                          {item.name}
                                        </p>
                                        <p className="mt-1 text-sm text-gray-500">
                                          {item.description}
                                        </p>
                                      </div>
                                    </a>
                                  ))}
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </Popover.Group>
                  <a
                    href="/about-us"
                    className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-md font-light text-gray-900 hover:text-[#ed1c24] hover:scale-105"
                  >
                    关于我们
                  </a>
                </div>
              </div>

              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/*language selector*/}
                <div>
                  <button
                    className="mr-3 lg:block inline-flex items-center"
                    onClick={() => props.handleClick("en")}
                  >
                    English
                  </button>
                </div>
                <a
                  href="/contact-us"
                  className="hidden lg:block inline-flex items-center border-transparent text-md font-light text-white rounded-3xl bg-[#ed1c24] px-4 py-2 hover:scale-105"
                >
                  联系我们
                </a>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="sm:hidden bg-white">
            <Disclosure className="sm:hidden bg-gray-100">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between items-center border-l-4 border-l-transparent border-b border-gray-200 py-2 pl-3 pr-4 text-base font-light text-gray-900">
                    服务项目
                    {open ? (
                      <ChevronUpIcon
                        className="ml-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    ) : (
                      <ChevronDownIcon
                        className="ml-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                  <Disclosure.Panel className="pl-6 pt-2 pb-4 text-sm border-b border-gray-200">
                    <Disclosure.Button
                      as="a"
                      href="/home-loans"
                      className="flex w-full justify-between items-center border-l-2 border-[#ed1c24] py-2 pl-6 pr-4 text-base font-light text-gray-900"
                    >
                      住房贷款
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="/commercial-loans"
                      className="flex w-full justify-between items-center border-l-2 border-[#ed1c24] py-2 pl-6 pr-4 text-base font-light text-gray-900"
                    >
                      商业物业贷款
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="/construction-loans"
                      className="flex w-full justify-between items-center border-l-2 border-[#ed1c24] py-2 pl-6 pr-4 text-base font-light text-gray-900"
                    >
                      建房开发融资
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="/asset-finance"
                      className="flex w-full justify-between items-center border-l-2 border-[#ed1c24] py-2 pl-6 pr-4 text-base font-light text-gray-900"
                    >
                      资产融资
                    </Disclosure.Button>
                    {/*<Disclosure.Button*/}
                    {/*  as="a"*/}
                    {/*  href="/kiwisaver"*/}
                    {/*  className="flex w-full justify-between items-center border-l-2 border-[#ed1c24] py-2 pl-6 pr-4 text-base font-light text-gray-900"*/}
                    {/*>*/}
                    {/*  KiwiSaver投资养老金*/}
                    {/*</Disclosure.Button>*/}
                    <Disclosure.Button
                      as="a"
                      href="/financial-advice"
                      className="flex w-full justify-between items-center border-l-2 border-[#ed1c24] py-2 pl-6 pr-4 text-base font-light text-gray-900"
                    >
                      专业房产理财建议
                    </Disclosure.Button>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            {/*Financial Calculators*/}
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between items-center border-l-4 border-l-transparent border-b border-gray-200 py-2 pl-3 pr-4 text-base font-light text-gray-900">
                    金融计算器
                    {open ? (
                      <ChevronUpIcon
                        className="ml-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    ) : (
                      <ChevronDownIcon
                        className="ml-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                  <Disclosure.Panel className="pl-6 pt-2 pb-4 text-sm border-b border-gray-200">
                    <Disclosure.Button
                      as="a"
                      href="/repayment-calculator"
                      className="flex w-full justify-between items-center border-l-2 border-[#ed1c24] py-2 pl-6 pr-4 text-base font-light text-gray-900"
                    >
                      还款计算器
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="/borrowing-calculator"
                      className="flex w-full justify-between items-center border-l-2 border-[#ed1c24] py-2 pl-6 pr-4 text-base font-light text-gray-900"
                    >
                      贷款计算器
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="/paye-calculator"
                      className="flex w-full justify-between items-center border-l-2 border-[#ed1c24] py-2 pl-6 pr-4 text-base font-light text-gray-900"
                    >
                      PAYE新西兰工资税计算器
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="/rates-compare"
                      className="flex w-full justify-between items-center border-l-2 border-[#ed1c24] py-2 pl-6 pr-4 text-base font-light text-gray-900"
                    >
                      各银行贷款利息比较
                    </Disclosure.Button>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure.Button
              as="a"
              href="/application-form"
              className="flex w-full justify-between items-center border-l-4 border-l-transparent border-b border-gray-200 py-2 pl-3 pr-4 text-base font-light text-gray-900"
            >
              贷款申请表
            </Disclosure.Button>

            <Disclosure.Button
              as="a"
              href="/about-us"
              className="flex w-full justify-between items-center border-l-4 border-l-transparent py-2 pl-3 pr-4 text-base font-light text-gray-900"
            >
              关于我们
            </Disclosure.Button>

            <div className="justify-start grid grid-cols-2 border border-gray-200 bg-slate-100">
              <Disclosure.Button
                as="a"
                href="/booking"
                className="flex w-auto justify-start items-center py-2 pl-2 pr-4 text-base font-light text-gray-900"
              >
                <CalendarIcon className="text-gray-400 w-6 h-6 m-1" />
                预约通话
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/contact-us"
                className="flex w-full justify-center items-center border-l border-t-transparent border-l-gray-200 py-2 pl-3 pr-4 text-base font-light text-gray-900"
              >
                <PhoneIcon className="text-gray-400 w-6 h-6 m-2" />
                联系我们
              </Disclosure.Button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
export default NavbarCN;
