import React, { useState } from "react";
import tw from "twin.macro";
import Lottie from "lottie-react";
import key from "../../assets/calculator/key.json";
import repayment from "../../assets/calculator/repayment.jpg";
import paye from "../../assets/calculator/paye.jpg";
import rates from "../../assets/calculator/rates.jpg";

const Main = tw.div`relative`;
const Container = tw.div`mx-auto max-w-2xl py-24 px-4 sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8`;
const HeadingSection1 = tw.div`max-w-3xl`;
const HeadingSection2 = tw.div`max-w-3xl mt-16`;
const Heading = tw.h1`text-3xl font-bold tracking-tight text-gray-800`;
const Heading2 = tw.h2`text-3xl font-bold tracking-tight text-gray-800`;
const HeadingDescription = tw.p`mt-4 text-gray-500`;
const TwoColumn = tw.div`grid grid-cols-1 gap-y-20 gap-x-8 lg:grid-cols-3`;
const TextColumn = tw.div`lg:col-span-2`;
const ImageColumn = tw.div`lg:col-span-1 h-full w-full object-cover object-center`;
const CalculatorSection = tw.div`mx-auto flex flex-col md:flex-row bg-gray-100 mt-8 shadow-xl rounded-2xl`;
const Calculator = tw.div`cursor-pointer flex flex-col border-r border-gray-200 md:w-2/5`;
const CalculatorRow1 = tw.div`relative mt-10 ml-10 mr-10`;
const CalculatorRow2 = tw.div`relative mt-5 ml-10 mr-10`;
const Label = tw.label`text-base text-gray-500`;
const Select = tw.select`w-full rounded-md bg-white rounded border border-gray-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out`;
const Button = tw.button`m-10 text-white bg-[#ed1c24] border-0 py-2 px-6 focus:outline-none rounded-lg text-base transform active:scale-x-90 active:scale-y-90 transition-transform`;
const Input = tw.input`w-full rounded-md bg-white rounded border border-gray-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out`;
const ValueSection = tw.div`bg-white rounded-r-2xl md:w-3/5`;
const ValueRow = tw.div`flex border-b bg-gray-100 rounded-tr-2xl text-gray-700 text-lg h-14 items-center justify-around`;
const Value = tw.div`flex justify-around py-32 text-8xl overflow-x-scroll`;
const ValueDescription = tw.p`text-xs font-light italic text-gray-500 m-5 mt-10`;
const MoreCalculators = tw.div`mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8`;
const MoreCalculatorsContainer = tw.div`aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-2xl shadow-xl bg-gray-100 group-hover:opacity-75 lg:aspect-none lg:h-80`;
const MoreCalculatorsImage = tw.img`object-cover object-center lg:h-full lg:w-full`;
const H3 = tw.h3`mt-4 text-base text-gray-700 text-center`;

const BorrowingCalculator = () => {
  const [type, setType] = useState("");
  const [income, setIncome] = useState("$0");
  const [dependent, setDependent] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [mortgage, setMortgage] = useState("$0");
  const calculate = (type, income, dependent, vehicle) => {
    if (type === "individual") {
      return parseFloat(
        income * 6.4 - dependent * 40000 - vehicle * 20000
      ).toFixed(0);
    } else if (type === "joint") {
      return parseFloat(
        income * 5.3 - dependent * 40000 - vehicle * 20000
      ).toFixed(0);
    }
  };

  const calculators = [
    {
      id: 1,
      name: "Repayment Calculator",
      href: "/repayment-calculator",
      imageSrc: repayment,
      imageAlt: "repayment calculator, auckland mortgage broker",
    },
    {
      id: 2,
      name: "PAYE Calculator",
      href: "/paye-calculator",
      imageSrc: paye,
      imageAlt:
        "paye calculator, how much will I take home after tax? auckland mortgage broker",
    },
    {
      id: 3,
      name: "Compare Rates",
      href: "/rates-compare",
      imageSrc: rates,
      imageAlt:
        "compare rates, compare interest rates, auckland mortgage broker",
    },
  ];
  const handleClick = (e) => {
    e.preventDefault();
    const mortgage = calculate(type, income, dependent, vehicle);
    setMortgage(`$${mortgage}`);
  };

  return (
    <Main>
      <head>
        <meta
          name="description"
          content="Estimate how much you could be borrow based on your current financial situation."
        />
        <meta
          property="og:site_name"
          content="Borrowing Calculator - Bicare Finance"
        />
        <meta
          property="og:title"
          content="Borrowing Calculator - Bicare Finance"
        />
        <meta
          property="og:description"
          content="Estimate how much you could be borrow based on your current financial situation."
        />
        <meta property="og:locale" content="en-NZ" />
        <meta
          name="twitter:title"
          content="Borrowing Calculator - Bicare Finance"
        />
        <meta
          name="twitter:description"
          content="Estimate how much you could be borrow based on your current financial situation."
        />
        <title>Borrowing Calculator - Bicare Finance</title>
        <link
          rel="canonical"
          href="https://bicare.co.nz/borrowing-calculator"
        />
      </head>
      <Container>
        <HeadingSection1>
          <Heading>Borrowing Calculator</Heading>
          <HeadingDescription>
            Estimate how much you could be borrow based on your current
            financial situation.
          </HeadingDescription>
        </HeadingSection1>
        <TwoColumn>
          <TextColumn>
            <CalculatorSection>
              <Calculator>
                <CalculatorRow1>
                  <Label>Your application type:</Label>
                  <Select
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="select">Please select one</option>
                    <option value="individual">Individual</option>
                    <option value="joint">Joint</option>
                  </Select>
                </CalculatorRow1>
                <CalculatorRow2>
                  <Label>Annual household income before tax:</Label>
                  <Input
                    type="number"
                    value={income}
                    onChange={(e) => setIncome(e.target.value)}
                    min="0"
                    placeholder="$"
                  ></Input>
                </CalculatorRow2>
                <CalculatorRow2>
                  <Label>Dependant children under 18 years of age:</Label>
                  <Input
                    value={dependent}
                    onChange={(e) => setDependent(e.target.value)}
                    type="number"
                    min="0"
                    placeholder="0"
                  ></Input>
                </CalculatorRow2>
                <CalculatorRow2>
                  <Label>Vehicles:</Label>
                  <Input
                    value={vehicle}
                    onChange={(e) => setVehicle(e.target.value)}
                    type="number"
                    min="0"
                    placeholder="0"
                  ></Input>
                </CalculatorRow2>
                <Button onClick={handleClick}>Calculate</Button>
              </Calculator>
              <ValueSection>
                <ValueRow>We estimate you could borrow up to:</ValueRow>
                <Value>{mortgage}</Value>
                <ValueDescription>
                  This calculator is for information purposes only and does not
                  provide financial advice. We recommend you to talk to one of
                  our experienced financial advisors about your situation and
                  goals before getting a financial product.
                </ValueDescription>
              </ValueSection>
            </CalculatorSection>
          </TextColumn>
          <ImageColumn>
            <Lottie animationData={key} loop={true} height={600} mwidth={600} />
          </ImageColumn>
        </TwoColumn>
        <HeadingSection2>
          <Heading2>More helpful calculators.</Heading2>
          <HeadingDescription>
            Whether you need a mortgage calculator to work out your borrowing
            power, mortgage repayments or after tax income, we have got your
            covered.
          </HeadingDescription>
        </HeadingSection2>
        <MoreCalculators>
          {calculators.map((calculator) => (
            <a
              key={calculator.id}
              href={calculator.href}
              className="group relative"
            >
              <MoreCalculatorsContainer>
                <MoreCalculatorsImage
                  src={calculator.imageSrc}
                  alt={calculator.imageAlt}
                  title={calculator.name}
                  loading="lazy"
                ></MoreCalculatorsImage>
              </MoreCalculatorsContainer>
              <H3>{calculator.name}</H3>
            </a>
          ))}
        </MoreCalculators>
      </Container>
    </Main>
  );
};
export default BorrowingCalculator;
