import React from "react";
import HeroCN from "./HeroCN";
import FAQCN from "./FAQCN";
import ServicesCN from "./ServicesCN";
import ReivewsCN from "./ReivewsCN";
import WhyUsCN from "./WhyUsCN";
const Home = () => {
  return (
    <div>
      <head>
        <meta
          name="description"
          content="我们提供各类民房，商用，农庄，建筑以及开发贷款服务，并与本地各大银行及信贷机构保持着良好的关系。"
        />
        <meta
          property="og:site_name"
          content="百家信贷 - 新西兰百姓身边的贷款专家！"
        />
        <meta
          property="og:title"
          content="百家信贷 - 新西兰百姓身边的贷款专家！"
        />
        <meta
          property="og:description"
          content="我们提供各类民房，商用，农庄，建筑以及开发贷款服务，并与本地各大银行及信贷机构保持着良好的关系。"
        />
        <meta property="og:locale" content="zh-CN" />
        <meta
          name="twitter:title"
          content="百家信贷 - 新西兰百姓身边的贷款专家！"
        />
        <meta
          name="twitter:description"
          content="我们提供各类民房，商用，农庄，建筑以及开发贷款服务，并与本地各大银行及信贷机构保持着良好的关系。"
        />
        <title>百家信贷 - 新西兰百姓身边的贷款专家！</title>
        <link rel="canonical" href="https://bicare.co.nz" />
      </head>
      <HeroCN />
      <ServicesCN />
      <WhyUsCN />
      <ReivewsCN />
      <FAQCN />
    </div>
  );
};

export default Home;
