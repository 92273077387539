import React, { useState } from "react";
import Lottie from "lottie-react";
import growth from "../../assets/calculator/growth.json";
import tw from "twin.macro";
import repayment from "../../assets/calculator/repayment.jpg";
import borrow from "../../assets/calculator/borrowing.jpg";

import rates from "../../assets/calculator/rates.jpg";

const Main = tw.div`relative`;
const Container = tw.div`mx-auto max-w-2xl py-24 px-4 sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8`;
const HeadingSection1 = tw.div`max-w-3xl`;
const HeadingSection2 = tw.div`max-w-3xl mt-12`;
const Heading = tw.h1`flex flex-col text-3xl font-bold text-gray-800 mb-2`;
const Heading2 = tw.h2`flex flex-col text-3xl font-bold text-gray-800 mb-2`;
const HeadingDescription1 = tw.p`flex flex-col text-base font-light text-gray-500 mt-3`;
const HeadingDescription2 = tw.p`flex flex-col text-xs font-light text-gray-500 italic mt-3`;
const TwoColumn = tw.div`grid grid-cols-1 gap-y-20 gap-x-8 lg:grid-cols-3`;
const TextColumn = tw.div`lg:col-span-2`;
const ImageColumn = tw.div`lg:col-span-1 h-full w-full object-cover object-center`;
const CalculatorSection = tw.div`mx-auto flex flex-col md:flex-row bg-gray-100 mt-8 shadow-xl rounded-2xl`;
const Calculator = tw.div`cursor-pointer flex flex-col border-r border-gray-200 md:w-2/5`;
const CalculatorRow1 = tw.div`relative mt-10 ml-10 mr-10 grid grid-cols-2 gap-2`;
const CalculatorRow2 = tw.div`relative mt-5 ml-10 mr-10 grid grid-cols-2 gap-2`;
const Div = tw.div``;
const Label = tw.label`text-base text-gray-500`;
const Select = tw.select`w-full rounded-md bg-white rounded border border-gray-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 h-12 px-3`;
const Input = tw.input`w-full rounded-md bg-white rounded border border-gray-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 h-12 px-3 leading-8 transition-colors duration-200 ease-in-out`;
const Button = tw.button`m-10 text-white bg-[#ed1c24] border-0 py-2 px-6  focus:outline-none rounded-lg text-base transform active:scale-x-90 active:scale-y-90 transition-transform`;
const ValueSection = tw.div`bg-white rounded-r-2xl md:w-3/5`;
const ValueHeading = tw.div`flex border-b justify-around bg-gray-100 rounded-tr-2xl text-gray-500 h-14 text-center`;
const ValueContent = tw.div`flex flex-col justify-center m-5`;
const ValueRow = tw.div`mt-3 mb-12 text-center text-4xl text-gray-800`;
const Value = tw.div`text-center text-8xl text-gray-800 decoration-2`;
const ValueDescription = tw.p`text-xs text-left font-light italic text-gray-500 mt-12 m-5`;
const MoreCalculators = tw.div`mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8`;
const MoreCalculatorsContainer = tw.div`aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-2xl shadow-xl bg-gray-100 group-hover:opacity-75 lg:aspect-none lg:h-80`;
const MoreCalculatorsImage = tw.img`object-cover object-center lg:h-full lg:w-full`;
const H3 = tw.h3`mt-4 text-base text-gray-700 text-center`;

const PayeCalculator = () => {
  const [grossIncome, setGrossIncome] = useState();
  const [grossIncomePeriod, setGrossIncomePeriod] = useState("week");
  const [kiwiSaver, setKiwiSaver] = useState(0);
  const [studentLoan, setStudentLoan] = useState("0");
  const [netAnnualPay, setAnnualNetPay] = useState(0);
  const [netMonthlyPay, setMonthlyNetPay] = useState(0);
  const [netFortnightlyPay, setFortnightlyNetPay] = useState(0);
  const [netWeeklyPay, setWeeklyNetPay] = useState(0);
  const calculateNetPay = (e) => {
    e.preventDefault();
    let taxableIncome = grossIncome;

    if (grossIncomePeriod === "week") {
      taxableIncome = taxableIncome * 52;
    } else if (grossIncomePeriod === "fortnight") {
      taxableIncome = taxableIncome * 26;
    } else if (grossIncomePeriod === "month") {
      taxableIncome = taxableIncome * 12;
    }

    let taxAmount;
    if (taxableIncome <= 14000) {
      taxAmount = taxableIncome * 0.105;
    } else if (taxableIncome <= 48000) {
      taxAmount = 14000 * 0.105 + (taxableIncome - 14000) * 0.175;
    } else if (taxableIncome <= 70000) {
      taxAmount =
        14000 * 0.105 + (48000 - 14000) * 0.175 + (taxableIncome - 48000) * 0.3;
    } else if (taxableIncome <= 180000) {
      taxAmount =
        14000 * 0.105 +
        (48000 - 14000) * 0.175 +
        (70000 - 48000) * 0.3 +
        (taxableIncome - 70000) * 0.33;
    } else {
      taxAmount =
        14000 * 0.105 +
        (48000 - 14000) * 0.175 +
        (70000 - 48000) * 0.3 +
        (180000 - 70000) * 0.33 +
        (taxableIncome - 180000) * 0.39;
    }

    let accAmount = taxableIncome * 0.016;
    // ACC limit fy22
    // if (accAmount > 1993.54) {
    //   accAmount = 1993.54;
    // }

    // // // ACC limit fy23
    // if (accAmount > 2132.57) {
    //   accAmount = 2132.57;
    // }

    // ACC limit fy24
    if (accAmount > 2276.52) {
      accAmount = 2276.52;
    }

    const kiwiSaverAmount = (taxableIncome * kiwiSaver) / 100;
    const studentLoanAmount = (taxableIncome - 21268) * 0.12 * studentLoan;
    setAnnualNetPay(
      taxableIncome -
        taxAmount -
        kiwiSaverAmount -
        accAmount -
        studentLoanAmount
    );
    setMonthlyNetPay(
      (
        (taxableIncome -
          taxAmount -
          kiwiSaverAmount -
          accAmount -
          studentLoanAmount) /
        12
      ).toFixed(2)
    );
    setFortnightlyNetPay(
      (
        (taxableIncome -
          taxAmount -
          kiwiSaverAmount -
          accAmount -
          studentLoanAmount) /
        26
      ).toFixed(2)
    );

    setWeeklyNetPay(
      (
        (taxableIncome -
          taxAmount -
          kiwiSaverAmount -
          accAmount -
          studentLoanAmount) /
        52
      ).toFixed(2)
    );
  };

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const data = [
    {
      label: "每年",
      value: "Annual",
      desc: `$${netAnnualPay}`,
    },
    {
      label: "每月",
      value: "Monthly",
      desc: `$${netMonthlyPay}`,
    },
    {
      label: "每两周",
      value: "Fortnightly",
      desc: `$${netFortnightlyPay}`,
    },
    {
      label: "每周",
      value: "Weekly",
      desc: `$${netWeeklyPay}`,
    },
  ];

  const calculators = [
    {
      id: 1,
      name: "还款计算器",
      href: "/repayment-calculator",
      imageSrc: repayment,
      imageAlt: "还款计算器，月供，奥克兰抵押经纪人",
    },
    {
      id: 2,
      name: "贷款计算器",
      href: "/borrowing-calculator",
      imageSrc: borrow,
      imageAlt: "借款计算器，我可以借多少钱？奥克兰抵押经纪人",
    },
    {
      id: 3,
      name: "各银行贷款利息比较",
      href: "/rates-compare",
      imageSrc: rates,
      imageAlt: "比较利率，比较利率，奥克兰抵押经纪人",
    },
  ];

  return (
    <Main>
      <head>
        <meta
          name="description"
          content="方便快捷的PAYE个人所得税计算器，只需输入您的税前收入，我们可以快速估算您在缴纳税款后能够带回家的净收入。"
        />
        <meta property="og:site_name" content="PAYE新西兰工资税计算器" />
        <meta property="og:title" content="PAYE新西兰工资税计算器" />
        <meta
          property="og:description"
          content="方便快捷的PAYE个人所得税计算器，只需输入您的税前收入，我们可以快速估算您在缴纳税款后能够带回家的净收入。"
        />
        <meta property="og:locale" content="zh-CN" />
        <meta name="twitter:title" content="PAYE新西兰工资税计算器" />
        <meta
          name="twitter:description"
          content="方便快捷的PAYE个人所得税计算器，只需输入您的税前收入，我们可以快速估算您在缴纳税款后能够带回家的净收入。"
        />
        <title>PAYE新西兰工资税计算器</title>
        <link rel="canonical" href="https://bicare.co.nz/paye-calculator" />
      </head>
      <Container>
        <HeadingSection1>
          <Heading>金融计算器 - PAYE新西兰工资税计算器</Heading>
          <HeadingDescription1>
            如何正确地计算PAYE工资税是雇主和员工都关心问题。您可以去新西兰税务局IRD网站搜索PAYE
            Calculator，我们也在这里为您提供了方便快捷的个人所得税计算器。通过输入您的税前收入，我们可以快速估算您扣除的税款以及在缴纳税款后能够带回家的净收入。
          </HeadingDescription1>
          <HeadingDescription2>
            此计算器使用2021年3月31日后的新纳税税率，并包括超过18万元的剩余收入的新39%个人所得税税率。
          </HeadingDescription2>
        </HeadingSection1>
        <TwoColumn>
          <TextColumn>
            <CalculatorSection>
              <Calculator>
                <CalculatorRow1>
                  {/*gross*/}
                  <Div>
                    <Label>税前收入：</Label>
                    <Input
                      type="number"
                      value={grossIncome}
                      onChange={(e) => setGrossIncome(e.target.value)}
                      min="0"
                      placeholder="$"
                    ></Input>
                  </Div>
                  {/*period*/}
                  <Div>
                    <Label>周期：</Label>
                    <Select
                      value={grossIncomePeriod}
                      onChange={(e) => setGrossIncomePeriod(e.target.value)}
                      className="w-full rounded-md bg-white rounded border border-gray-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 h-12 px-3"
                    >
                      <option value="week">每周</option>
                      <option value="fortnight">每两周</option>
                      <option value="month">每月</option>
                      <option value="year">每年</option>
                    </Select>
                  </Div>
                </CalculatorRow1>
                <CalculatorRow2>
                  {/*KiwiSaver*/}
                  <Div>
                    <Label>KiwiSaver:</Label>
                    <Select
                      value={kiwiSaver}
                      onChange={(e) => setKiwiSaver(e.target.value)}
                    >
                      <option value={0}>0%</option>
                      <option value={2}>2%</option>
                      <option value={3}>3%</option>
                      <option value={4}>4%</option>
                      <option value={6}>6%</option>
                      <option value={8}>8%</option>
                      <option value={10}>10%</option>
                    </Select>
                  </Div>
                  {/*studentLoan*/}
                  <Div>
                    <Label>学生贷款：</Label>
                    <Select
                      value={studentLoan}
                      onChange={(e) => setStudentLoan(e.target.value)}
                      className="w-full rounded-md bg-white rounded border border-gray-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 h-12 px-3"
                    >
                      <option value={0}>无</option>
                      <option value={1}>有</option>
                    </Select>
                  </Div>
                </CalculatorRow2>
                <Button onClick={calculateNetPay}>现在计算</Button>
              </Calculator>
              <ValueSection>
                <ValueHeading>
                  {data.map((label, idx) => {
                    return (
                      <div
                        key={idx}
                        className={`py-4 border-b-4 transition-colors duration-300 ${
                          idx === activeTabIndex
                            ? "border-[#ed1c24] border-b-2"
                            : "border-transparent hover:border-gray-400 hover:scale-105"
                        }`}
                        // Change the active tab on click.
                        onClick={() => setActiveTabIndex(idx)}
                      >
                        {label.label}
                      </div>
                    );
                  })}
                </ValueHeading>
                <ValueContent>
                  <ValueRow>您的税后工资为：</ValueRow>
                  <Value>{data[activeTabIndex].desc}</Value>
                </ValueContent>
                <ValueDescription>
                  此计算器仅供信息参考，不提供任何金融建议。我们建议您在选择金融产品之前，先与我们经验丰富的理财顾问就您的情况和目标进行沟通。
                </ValueDescription>
              </ValueSection>
            </CalculatorSection>
          </TextColumn>
          <ImageColumn>
            <Lottie
              animationData={growth}
              loop={true}
              height={500}
              mwidth={500}
            />
          </ImageColumn>
        </TwoColumn>
        <HeadingSection2>
          <Heading2>更多金融计算器.</Heading2>
          <HeadingDescription1>
            无论您需要使用抵押贷款计算器来计算您的借款能力、抵押贷款还款额或税后收入，我们都为您准备好了。
          </HeadingDescription1>
        </HeadingSection2>
        <MoreCalculators>
          {calculators.map((calculator) => (
            <a
              key={calculator.id}
              href={calculator.href}
              className="group relative"
            >
              <MoreCalculatorsContainer>
                <MoreCalculatorsImage
                  src={calculator.imageSrc}
                  alt={calculator.imageAlt}
                  title={calculator.name}
                  loading="lazy"
                ></MoreCalculatorsImage>
              </MoreCalculatorsContainer>
              <H3>{calculator.name}</H3>
            </a>
          ))}
        </MoreCalculators>
      </Container>
    </Main>
  );
};

export default PayeCalculator;
