import React from "react";
import Company from "./Company";
import Team from "./Team";

const AboutUs = () => {
  return (
    <div>
      <head>
        <meta
          name="description"
          content="Bicare Finance is a loan company located in Auckland, New Zealand. Loan services include: home loans, investment property loans, construction loans, business financing, real estate development, commercial property financing, personal loans."
        />
        <meta
          property="og:description"
          content="Bicare Finance is a loan company located in Auckland, New Zealand. Loan services include: home loans, investment property loans, construction loans, business financing, real estate development, commercial property financing, personal loans."
        />
        <meta property="og:site_name" content="About Us - Bicare Finance" />
        <meta property="og:title" content="About Us - Bicare Finance" />
        <meta property="og:locale" content="en-NZ" />
        <meta name="twitter:title" content="About Us - Bicare Finance" />
        <meta
          name="twitter:description"
          content="Bicare Finance is a loan company located in Auckland, New Zealand. Loan services include: home loans, investment property loans, construction loans, business financing, real estate development, commercial property financing, personal loans."
        />
        <title>About Us - Bicare Finance</title>
        <link rel="canonical" href="https://bicare.co.nz/about-us" />
      </head>
      <Company />
      <Team />
    </div>
  );
};

export default AboutUs;
