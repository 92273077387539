import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const Footer = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    user_email: "",
  });
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const [subscribe, setSubscribe] = useState(false);
  const sub = async (e) => {
    e.preventDefault();
    setSubscribe(true); // Set sending status to true

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_SUBSCRIBE_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          // clear the form
          setFormData({
            user_email: "",
          });
          console.log(result.status);
          setSubscribe(false); // Set sending status back to false
        },
        (error) => {
          console.log(error.text);
          setSubscribe(false); // Set sending status back to false
        }
      );
  };
  let buttonText = subscribe ? "Subscribed" : "Subscribe";

  return (
    <div className="w-full bg-slate-100 text-gray-900 py-y px-2">
      <div className="max-w-7xl mx-auto grid grid-cols-2 md:grid-cols-6 border-b border-gray-300 py-8">
        <div>
          <div className="font-bold text-lg uppercase px-4 pt-2 py-2">
            <a href="https://bicare.co.nz" aria-label="bicare">
              HOME
            </a>
          </div>
          <ul>
            <li className="py-1 font-light hover:underline">
              <a href="/about-us">About Us</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/repayment-calculator">Repayment Calculator</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/borrowing-calculator">Borrowing Calculator</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/paye-calculator">PAYE Calculator</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/rates-compare">Compare Rates</a>
            </li>
          </ul>
        </div>
        <div>
          <div className="font-bold text-lg uppercase px-4 py-2 pt-2">
            Services
          </div>
          <ul>
            <li className="py-1 font-light hover:underline">
              <a href="/home-loans">Home loans</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/commercial-loans">Commercial Loans</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/asset-finance">Asset Financing</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/construction-loans">Construction Loans</a>
            </li>
            {/*<li className="py-1 font-light hover:underline">*/}
            {/*  <a href="/kiwisaver">KiwiSaver</a>*/}
            {/*</li>*/}
            <li className="py-1 font-light hover:underline">
              <a href="/financial-advice">Financial Advice</a>
            </li>
          </ul>
        </div>
        <div>
          <div className="font-bold text-lg uppercase px-4 py-2 pt-2">
            Contact
          </div>
          <ul>
            <li className="py-1 font-light hover:underline">
              <a href="tel:096251234">09 625 1234</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="mailto:info@bicare.co.nz">info@bicare.co.nz</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a target="_blank" rel="noopener noreferrer" href="/booking">
                Book a Chat
              </a>
            </li>
          </ul>
        </div>
        <div>
          <div className="font-bold text-lg uppercase px-4 py-2 pt-2">
            Legal
          </div>
          <ul>
            <li className="py-1 font-light hover:underline">
              <a href="/privacy-policy">Privacy Policy</a>
            </li>
            <li className="py-1 font-light hover:underline">
              <a href="/disclosure">Disclosure</a>
            </li>
          </ul>
        </div>
        <div className="col-span-2 pt-8 px-4 md:pt-2">
          <p className="font-bold text-lg uppercase">
            Subscribe to our newsletter
          </p>
          <p className="py-4 font-light">
            We will update you with the latest news about interest rates,
            articles, and resources.
          </p>
          <form
            ref={form}
            onSubmit={sub}
            className="flex flex-col sm:flex-row items-center"
          >
            <input
              id="email"
              name="user_email"
              type="email"
              autoComplete="email"
              value={formData.user_email}
              onChange={handleInputChange}
              disabled={subscribe}
              className="w-full p-2 mr-4 font-light rounded-lg mb-4 border border-gray-200"
              placeholder="Enter your email..."
            />
            <input
              type="submit"
              value={buttonText}
              disabled={subscribe}
              onClick={() => this.setState({ fade: true })}
              onAnimationEnd={() => this.setState({ fade: false })}
              className="p-2 w-40 mb-4 bg-[#ed1c24] rounded-3xl text-white hover:scale-105"
            ></input>
          </form>
        </div>
      </div>
      <div className="flex flex-col max-w-7xl px-2 py-4 mx-auto justify-between sm:flex-row text-center font-light text-gray-900 ">
        <p className="py-4">Bicare Finance © 2024 All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
