import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components/macro";
import { Container, ContentWithPaddingSm } from "../components/Layouts.js";
import { SectionHeading } from "../components/Headings.js";
import { SectionDescription } from "../components/Typography.js";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
const HeadingContainer = tw.div``;
const Description = tw(
  SectionDescription
)`mx-auto font-light text-base text-center text-gray-500 py-4 px-2`;
const FaqsContainer = tw.div`px-2 sm:mt-8 sm:mb-12 w-full flex-1 lg:flex justify-between items-start max-w-screen-2xl mx-auto`;
const FaqsColumn = tw.div`w-full lg:max-w-7xl lg:mr-8 last:mr-0`;
const FAQs = tw.div`select-none cursor-pointer border-b border-stone-300 hover:border-[#ed1c24] transition-colors duration-300 py-6`;
const Question = tw.div`flex justify-between items-center px-2`;
const QuestionText = tw.div`text-base sm:text-lg font-medium text-gray-900 tracking-wide`;
const QuestionToggleIcon = styled(motion.span)`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Answer = tw(
  motion.div
)`hidden text-base font-light leading-7 text-gray-500 mt-4`;
const HighlightedText = tw.span`text-[#ed1c24]`;

const FAQ = ({
  faqs = [
    {
      question: "What is the process to apply for a home loan?",
      answer:
        "Yes, absolutely! We offer a variety of home loan options to suit your unique needs and budget. Our expert lenders can help guide you through the application process and find the right loan for you. Chat with us today to get started on your home buying journey.",
    },
    {
      question: "Can I get a loan for an investment property?",
      answer:
        "Yes, we offer a variety of loan options for investment properties. Whether you're looking to purchase a single rental property or multiple investment properties, our expert lenders can help guide you through the process and find the right loan for your needs. Chat with us today to explore your options.",
    },
    {
      question: "How much am I eligible to borrow for a home loan?",
      answer:
        "The amount you can borrow depends on several factors, including your income, credit score, debt-to-income ratio, and the value of the property you're purchasing or refinancing. Our expert lenders can help you evaluate your financial situation and determine how much you can borrow. Chat with us today to learn more about your borrowing options.",
    },
    {
      question: "What should I consider when refinancing my home loan?",
      answer:
        "We are here to help you navigate the refinancing process and will remind you when the refinancing date gets closed. Our expert lenders can help you explore your options and find ways to reduce your monthly payments, lower your interest rate, and improve your overall financial situation.",
    },
    {
      question:
        "What if I'm experiencing financial difficulties and can't make my mortgage payments?",
      answer:
        "If you're experiencing financial difficulties, it's important to reach out to us as soon as possible. We can work with you to explore options such as forbearance, loan modification, or refinancing to help you stay in your home and avoid foreclosure. Our team is here to help you navigate these difficult situations and find a solution that works for you.",
    },
    // {
    //   question: "Can you help me choose the right KiwiSaver provider for me?",
    //   answer:
    //     "Absolutely. Our team of experts can work with you to assess your investment goals, risk tolerance, and other factors to help you choose the right KiwiSaver provider for your needs. We'll take into account factors such as the fees, investment options, and track record of different providers, as well as any other considerations that are important to you.",
    // },
    {
      question: "How can you help me with my commercial loan?",
      answer:
        "Our team of experts can work with you to assess your commercial loan needs and help you choose the right loan product for your business. We can help you compare different loan options, negotiate with lenders on your behalf, and ensure that you get the best possible terms and rates. We'll also provide ongoing support throughout the loan process, to help you manage your loan effectively and ensure that your business stays on track.",
    },
    {
      question: "What kind of financial advice can I seek from you?",
      answer:
        "Our team of financial experts can provide you with a range of financial advice, tailored to your individual needs. We can help you with everything from budgeting and saving to investing and retirement planning. We'll work with you to assess your financial goals and develop a customized plan to help you achieve them.",
    },
  ],
}) => {
  const faqCol1 = [];
  const faqCol2 = [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (questionIndex) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  faqs.map((faq, index) => {
    const renderedFaq = (
      <FAQs key={index} onClick={() => toggleQuestion(index)}>
        <Question>
          <QuestionText>{faq.question}</QuestionText>
          <QuestionToggleIcon
            variants={{
              collapsed: { rotate: 0 },
              open: { rotate: -180 },
            }}
            initial="collapsed"
            animate={activeQuestionIndex === index ? "open" : "collapsed"}
            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ChevronDownIcon />
          </QuestionToggleIcon>
        </Question>
        <Answer
          variants={{
            open: {
              opacity: 1,
              height: "auto",
              marginTop: "16px",
              display: "block",
            },
            collapsed: {
              opacity: 0,
              height: 0,
              marginTop: "0px",
              display: "none",
            },
          }}
          initial="collapsed"
          animate={activeQuestionIndex === index ? "open" : "collapsed"}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {faq.answer}
        </Answer>
      </FAQs>
    );

    if (index % 2 === 0) faqCol1.push(renderedFaq);
    else faqCol2.push(renderedFaq);
    return null;
  });
  return (
    <Container>
      <ContentWithPaddingSm>
        <HeadingContainer>
          <SectionHeading>
            Frequently Asked <HighlightedText>Questions</HighlightedText>
          </SectionHeading>
          <Description>
            Can’t find the answer you’re looking for?
            <br />
            Reach out to our support team by
            <br />
            <a
              href="/contact-us"
              className="font-light text-base text-decoration: underline text-[#ed1c24]"
            >
              sending us an email.
            </a>
          </Description>
        </HeadingContainer>
        <FaqsContainer>
          <FaqsColumn>{faqCol1}</FaqsColumn>
          <FaqsColumn>{faqCol2}</FaqsColumn>
        </FaqsContainer>
      </ContentWithPaddingSm>
    </Container>
  );
};

export default FAQ;
