import React from "react";
import tw from "twin.macro";
import avatar1 from "../assets/team/arlen.png";
import avatar2 from "../assets/team/laowang.png";
import avatar3 from "../assets/team/claire.png";
import avatar4 from "../assets/team/roger.png";
import avatar5 from "../assets/team/bella.png";
import avatar6 from "../assets/team/alice.png";
import avatar7 from "../assets/team/grace.png";
import avatar8 from "../assets/team/eccles.png";
import avatar9 from "../assets/team/david.png";
import avatar10 from "../assets/team/eden.png";

const Container = tw.div`relative`;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto mb-8`;
const HighlightedText = tw.span`text-[#ed1c24]`;
const Heading = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center`;
const Subheading = tw.h3`text-gray-500 text-lg text-center font-light py-8 px-2`;
const TeamContainer = tw.div`flex flex-wrap justify-center grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-10 lg:gap-10 md:gap-8 sm:gap-2 lg:py-8 md:py-6 sm:py-4`;
const MemberContainer = tw.div`w-full max-w-sm bg-white border border-gray-200 rounded-2xl shadow`;
const MemberRow = tw.div`flex flex-col items-center py-6`;
const Image = tw.img`w-24 h-24 mb-4 rounded-full shadow-lg shadow-gray-700/50`;
const Name = tw.span`mb-1 text-xl font-medium text-gray-700 mb-2`;
const Position = tw.span`text-sm text-gray-700 mb-4`;
const Introduction = tw.span`px-6 text-sm text-gray-500 text-center`;
const Contacts = tw.div`flex mt-4 space-x-3 md:mt-6`;
const EmailBtn = tw.a`inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-[#ed1c24] rounded-xl hover:scale-105`;

const Team = ({
  members = [
    {
      imageSrc: avatar1,
      name: "Arlen Wang",
      position: "Founder, Director",
      introduction:
        "Arlen is one of the founders of a hundred credit companies in New Zealand. He holds a Bachelor's degree in Economics and Finance from the University of Auckland. In 2005, he joined the Bank of New Zealand. Over the eight years, Arlen worked in the credit departments of ASB Bank, The National Bank, and ANZ Bank, holding positions such as staff member, credit manager, and senior credit specialist. In 2013, Arlen established the brand  \"Bicare Loan\". Currently, he is responsible for the company's management, marketing, and credit business evaluation. We want to be financial and wealth advisors for the people in NZ.",
      email: "mailto:arlen.wang@bicare.co.nz",
      title: "Arlen Wang",
    },
    {
      imageSrc: avatar2,
      name: "Lao Wang",
      position: "Co Founder",
      introduction:
        "Everyone calls me Laowang Bicare! I have 30 years of experience in financial work and 10 years of experience in loan consultants and I understand the mysteries of money and understand the needs of customers! I’m familiar with the credit policies of major banks and lending institutions in New Zealand, can help you tailor your future financial planning, and solve your troubles and troubles in loan financing! I can also help you establish a good credit image, and enjoy the benefits and convenience of bank financing to the greatest extent from a long-term perspective!",
      email: "mailto:wanyue.wang@bicare.co.nz",
      title: "Lao Wang",
    },
    {
      imageSrc: avatar3,
      name: "Claire Cai",
      position: "Co Founder, Operations",
      introduction:
        "Claire Cai came to New Zealand in 1999. She graduated from The University of Auckland and majored in Finance Accounting. She joined Unifin International Limited as a foreign currency analyst. During this period, she accumulated a lot of foreign currency and stock analysis experiences and achieved a great results. In 2006, she joined Bank of New Zealand dealing with daily banking and mortgage business. together with two other company founders, they established the brand of Bicare Loan.",
      email: "mailto:admin@bicare.co.nz",
      title: "Claire Cai",
    },
    {
      imageSrc: avatar4,
      name: "Roger Shen",
      position: "Financial Advisor, Mortgage Broker",
      introduction:
        "Roger graduates from the Waikato University, Management School, major in Finance and Economics (with First Class Honours). His clients describe him as a friendly, positive and caring professional, with a great sense of humour. His motto is “strive to help clients be better-off, in terms of their financial needs, especially the home lending needs. As a Mortgage Adviser, Roger wishes to utilize his professional experience from the Bank of New Zealand (BNZ) and the Australia and New Zealand Bank (ANZ), to provide his clients with top class services and sound home lending options.",
      email: "mailto:roger.shen@bicare.co.nz",
      title: "Roger Shen",
    },
    {
      imageSrc: avatar5,
      name: "Bella Qiang",
      position: "Admin Assistant",
      introduction:
        "I graduated from Massey University with a Bachelor's degree in Finance and a Master's degree in Management. Has worked for domestic listed banks, and financial leasing companies, and has many years of experience in credit, risk control, and internal audit. Provides clients with the highest quality service with a sincere, rigorous, and professional attitude.",
      email: "mailto:bella.qiang@bicare.co.nz",
      title: "Bella Qiang",
    },
    {
      imageSrc: avatar6,
      name: "Alice Xu",
      position: "Financial Advisor, Mortgage Broker, Team Leader",
      introduction:
        "Help with first home buyer to own a dream home; Effective real estate investment loan consultation to help with increase your wealth portfolio, provide sufficient emergency solutions to help with financial hardship, 10 years experienced local bank mortgage manager, 16 years mortgages experience, 6 years team management experience. Specialising in home loan, subdivision land development and construction loans and commercial property lending.",
      email: "mailto:alice.xu@bicare.co.nz",
      title: "Alice Xu",
    },
    {
      imageSrc: avatar7,
      name: "Grace Wang",
      position: "Admin Assistant",
      introduction:
        "A former experienced banker, familiar with banking operations and customer management. Closely work with the Financial Advisor team to provide the sufficient support from loan pre-approval to loan drawn down. Regular tracking customer’s loan status and help with mortgage interest rate refinancing and restructure to meet customers’ needs. She pays great attention to the dynamics of the banking and financial industry, to provide the most appropriate solutions.",
      email: "mailto:admin.east@bicare.co.nz",
      title: "Grace Wang",
    },
    {
      imageSrc: avatar8,
      name: "Eccles Chan",
      position: "Mortgage Broker, Financial Advisor",
      introduction:
        "As a financial adviser specialised in mortgage, I have deep knowledge and resources from a wide range of lenders available on the market. I walk the journey with you right from the beginning of just the idea of owning your first home or buying your first investment property or maybe the third, to searching the market and recommend the best deal to you. In my 5 years’ experience as an adviser, with the support of the most trusted Bicare Finance team in the industry, I have work with many happy clients from all walks of life to archive their goal through home loans, construction loans, commercial loans.",
      email: "mailto:eccles.chan@bicare.co.nz",
      title: "Eccles Chan",
    },
    {
      imageSrc: avatar9,
      name: "David Wang",
      position: "Mortgage Broker, Financial Advisor",
      introduction:
        "I have been in New Zealand since 2001, and in 2015, I began working in the loan industry. Over the years, I have gained a profound understanding of New Zealand's economy and real estate. I graduated from the management department of AUT University and have accumulated good communication experience with both the local Kiwi community and the Chinese community. I have also gained a wealth of professional knowledge in real estate transactions, house construction, and other related areas, providing loan advice to many friends who are first-time homebuyers. I am committed to serving you with sincerity, humor, rigor, and professionalism.",
      email: "mailto:david.wang@bicare.co.nz",
      title: "David Wang",
    },
    {
      imageSrc: avatar10,
      name: "Eden Ross",
      position: "Mortgage Broker，Financial Advisor, Bay of Plenty",
      introduction:
        "Graduated with a Master's in Management from Massey University, and registered as a Financial Service Provider (FSP) for mortgage advisory in New Zealand. With nearly 30 years of living and working experience in various cities of New Zealand, coupled with extensive personal experience in loan investment, I possess a unique investment insight and loan strategy. Whether you are a first-time homebuyer or someone looking to broaden their horizons and invest in different markets for the short or long-term, I believe my professional knowledge and in-depth understanding of different cities can provide you with the most suitable loan options.",
      email: "mailto:eden.ross@bicare.co.nz",
      title: "Eden Ross",
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <Heading>
          Meet Our <HighlightedText>Team</HighlightedText>
        </Heading>
        <Subheading className="text-gray-500 text-lg text-center font-light py-6 px-2">
          Meet our team of experienced mortgage brokers, dedicated to helping
          you find the perfect mortgage solution for your needs.
        </Subheading>
        <TeamContainer>
          {members.map((data, index) => (
            <MemberContainer key={index}>
              <MemberRow>
                <Image
                  src={data.imageSrc}
                  alt={data.name}
                  title={data.title}
                  loading="lazy"
                ></Image>
                <Name> {data.name} </Name>
                <Position> {data.position} </Position>
                <Introduction> {data.introduction} </Introduction>
                <Contacts>
                  <EmailBtn href={data.email}>Message</EmailBtn>
                </Contacts>
              </MemberRow>
            </MemberContainer>
          ))}
        </TeamContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};

export default Team;
