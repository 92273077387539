import React from "react";
import TeamCN from "./TeamCN";
import CompanyCN from "./CompanyCN";

const AboutUsCN = () => {
  return (
    <div>
      <head>
        <meta
          name="description"
          content="百家信贷是新西兰金融服务集团（NZFSG）的会员单位，有资深的贷款专家专业为客户提供住宅，商铺，建筑及农场等各类抵押贷款服务。"
        />
        <meta
          name="description"
          content="百家信贷是新西兰金融服务集团（NZFSG）的会员单位，有资深的贷款专家专业为客户提供住宅，商铺，建筑及农场等各类抵押贷款服务。"
        />
        <meta
          property="og:description"
          content="百家信贷是新西兰金融服务集团（NZFSG）的会员单位，有资深的贷款专家专业为客户提供住宅，商铺，建筑及农场等各类抵押贷款服务。"
        />
        <meta property="og:site_name" content="关于我们 - 百家信贷" />
        <meta property="og:title" content="关于我们 - 百家信贷" />
        <meta property="og:locale" content="zh-CN" />
        <meta name="twitter:title" content="关于我们 - 百家信贷" />
        <meta
          name="twitter:description"
          content="百家信贷是新西兰金融服务集团（NZFSG）的会员单位，有资深的贷款专家专业为客户提供住宅，商铺，建筑及农场等各类抵押贷款服务。"
        />
        <title>关于我们 - 百家信贷</title>
        <link rel="canonical" href="https://bicare.co.nz/about-us" />
      </head>
      <CompanyCN />
      <TeamCN />
    </div>
  );
};

export default AboutUsCN;
