import React from "react";
import asset from "../../assets/services/asset.jpg";
import tw from "twin.macro";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

const Main = tw.div`relative`;
const Container = tw.div`mx-auto max-w-2xl py-24 px-4 sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8`;
const HeadingSection = tw.div`mx-auto max-w-3xl text-center`;
const Heading = tw.h1`text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl`;
const SubheadingSection = tw.div`border-b-2 border-gray-200 pb-4`;
const Subheading = tw.h2`text-lg tracking-tight text-gray-900`;
const TwoColumn = tw.div`grid grid-cols-1 gap-y-20 gap-x-8 lg:grid-cols-12 mt-16`;
const TextColumn = tw.div`lg:col-span-7`;
const ImageColumn = tw.div`lg:col-span-5`;
const DescriptionSection = tw.p`pt-6 space-y-2`;
const Description = tw.p`text-base tracking-tight text-gray-600`;
const Image = tw.img`h-full w-full object-cover object-center`;
const Link = tw.a`hover:text-[#ed1c24] underline decoration-solid underline-offset-2`;
const ApplyLink = tw.a`flex text-[#ed1c24] mt-10 font-light underline decoration-solid underline-offset-2`;
const AssetFinance = () => {
  return (
    <Main>
      <head>
        <meta
          name="description"
          content="Our professional team will provide you with the most suitable equipment loan solution to help you achieve your purchasing goals."
        />
        <meta
          property="og:site_name"
          content="Asset Finance - Bicare Finance"
        />
        <meta property="og:title" content="Asset Finance - Bicare Finance" />
        <meta
          property="og:description"
          content="Our professional team will provide you with the most suitable equipment loan solution to help you achieve your purchasing goals."
        />
        <meta property="og:locale" content="en-NZ" />
        <meta name="twitter:title" content="Asset Finance - Bicare Finance" />
        <meta
          name="twitter:description"
          content="Our professional team will provide you with the most suitable equipment loan solution to help you achieve your purchasing goals."
        />
        <title>Asset Finance - Bicare Finance</title>
        <link rel="canonical" href="https://bicare.co.nz/asset-finance" />
      </head>
      <Container>
        <HeadingSection>
          <Heading>Asset Finance</Heading>
        </HeadingSection>
        <TwoColumn>
          {/*image*/}
          <ImageColumn>
            <div className="aspect-w-8 aspect-h-9 overflow-hidden rounded-lg">
              <Image
                src={asset}
                alt="asset finance, construction equipment loan, industrial equipment mortgage, auckland, new zealand"
                className="h-full w-full object-cover object-center"
                loading="lazy"
                title="asset finance"
              />
            </div>
          </ImageColumn>
          {/*text*/}
          <TextColumn>
            <SubheadingSection>
              <Subheading>
                Do you need to purchase industrial & construction Equipment for
                your business？
              </Subheading>
            </SubheadingSection>
            <DescriptionSection>
              <Description>
                Industrial equipment is essential for many industries. In the
                era of rapid development of real estate, various types of
                special engineering vehicles are indispensable on construction
                sites. These machines can significantly improve your efficiency,
                and you can make money through them as well.
              </Description>
              <Description>
                In addition to the construction and real estate industries,
                there are many other industries that require special equipment,
                such as mining, manufacturing, agriculture, and logistics. These
                devices can help companies complete work more efficiently and
                improve production efficiency and quality.
              </Description>
              <Description>
                We suggest that you conduct some research and investigation to
                determine the type, brand, specifications, and price range of
                the equipment you need. Once you have this information, you can{" "}
                <Link href="/contact-us">contact Us</Link> at any time, and our
                professional team will provide you with the most suitable
                equipment loan solution to help you achieve your purchasing
                goals.
              </Description>
              <Description>
                Over the past few years, Bicare has successfully applied for a
                large number of equipment loans for customers, including trucks,
                excavators, cranes, cement tankers, and buses. We covered brands
                such as Hino, Scania, MAN, Komatsu, Volvo, and more.
              </Description>
              <Description>
                Our loan interest rates and repayment conditions are very
                flexible, and the repayment period can be extended up to five
                years. The highest loan ratio for new vehicles can reach 100%,
                which can meet your different needs.
              </Description>
            </DescriptionSection>
            <ApplyLink href="/application-form">
              Apply asset finance now
              <ChevronRightIcon className="w-6 h6 text-[#ed1c24]" />
            </ApplyLink>
          </TextColumn>
        </TwoColumn>
      </Container>
    </Main>
  );
};
export default AssetFinance;
