import React from "react";
import commercial from "../../assets/services/commercial.jpg";
import tw from "twin.macro";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

const Main = tw.div`relative`;
const Container = tw.div`mx-auto max-w-2xl py-24 px-4 sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8`;
const HeadingSection = tw.div`mx-auto max-w-3xl text-center`;
const Heading = tw.h1`text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl`;
const SubheadingSection = tw.div`border-b-2 border-gray-200 pb-4`;
const Subheading = tw.h2`text-2xl tracking-tight text-gray-900`;
const TwoColumn = tw.div`grid grid-cols-1 gap-y-20 gap-x-8 lg:grid-cols-12 mt-16`;
const TextColumn = tw.div`lg:col-span-7`;
const ImageColumn = tw.div`lg:col-span-5`;
const DescriptionSection = tw.p`pt-6 space-y-2`;
const Description = tw.p`text-base tracking-tight text-gray-600`;
const Image = tw.img`h-full w-full object-cover object-center`;
const Link = tw.a`hover:text-[#ed1c24] underline decoration-solid underline-offset-2`;
const ApplyLink = tw.a`flex text-[#ed1c24] mt-10 font-light underline decoration-solid underline-offset-2`;
const CommercialLoanCN = () => {
  return (
    <Main>
      <head>
        <meta
          name="description"
          content="需要新西兰商业物业贷款？百家信贷将为您提供全程服务"
        />
        <meta property="og:site_name" content="商业物业贷款 - 百家信贷" />
        <meta property="og:title" content="商业物业贷款 - 百家信贷" />
        <meta
          property="og:description"
          content="需要新西兰商业物业贷款？百家信贷将为您提供全程服务"
        />
        <meta property="og:locale" content="zh-CN" />
        <meta name="twitter:title" content="商业物业贷款 - 百家信贷" />
        <meta
          name="twitter:description"
          content="需要新西兰商业物业贷款？百家信贷将为您提供全程服务"
        />
        <title>商业物业贷款 - 百家信贷</title>
        <link rel="canonical" href="https://bicare.co.nz/commercial-loans" />
      </head>
      <Container>
        <HeadingSection>
          <Heading>商业物业贷款</Heading>
        </HeadingSection>
        <TwoColumn>
          {/*text*/}
          <TextColumn>
            <SubheadingSection>
              <Subheading>您想要建立一个在新西兰商业物业帝国吗？</Subheading>
            </SubheadingSection>
            <DescriptionSection>
              <Description>
                这主意不错。在新西兰的商业模式里，房东可不都是弱势的一方。比如，商业物业的房东。
              </Description>
              <Description>
                在新西兰的绝大多数lease agreement中，
                维修，水电，保险等一系列费用都是由方可承担的。
                不过，这并不意味着您就可以高枕无忧。
              </Description>
              <Description>
                您要熟悉什么样的物业是适合自己的。零售商铺？仓库厂房？办公大楼？幼儿园？还是期和旅馆和酒店？
              </Description>
              <Description>
                不一样的物业都有自己不一样的属性。为投资人带来不一样的回报率和风险。您首先要做的就是放弃自己在民房投资领域获得经验。
              </Description>
              <Description>
                因为商业房产从定价，投资，持有和销售都有自己的一套模式。比如商业物业的贷款标准年限是15年，最大贷款比例规定不的超过65%，是不是很不一样？
              </Description>
              <Description>
                如果您感到疑惑，请
                <Link href="/contact-us">联系百家信贷</Link>
                ，我们可以为您提供全程跟随服务。
              </Description>
              <Description>
                从最开始的意向到计划，从预算到贷款，从交割到管理，无死角覆盖您可能会遇到的每个问题。
              </Description>
            </DescriptionSection>
          </TextColumn>
          {/*image*/}
          <ImageColumn>
            <div className="aspect-w-5 aspect-h-4 overflow-hidden rounded-lg">
              <Image
                src={commercial}
                alt="商业物业贷款，商业贷款，奥克兰商业物业买卖贷款，新西兰"
                className="h-full w-full object-cover object-center"
                loading="lazy"
                title="商业物业贷款"
              />
            </div>
          </ImageColumn>
        </TwoColumn>
        <ApplyLink href="/application-form">
          点击申请商业物业贷款
          <ChevronRightIcon className="w-6 h6 text-[#ed1c24]" />
        </ApplyLink>
      </Container>
    </Main>
  );
};
export default CommercialLoanCN;
