import React from "react";
import pic from "../assets/form/form.json";
import tw from "twin.macro";
import Lottie from "lottie-react";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import applicationForm from "../assets/form/BicareLoanApplicationForm.pdf";

const Main = tw.div`relative`;
const Container = tw.div`mx-auto max-w-2xl py-24 px-4 sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8`;
const HeadingSection = tw.div`mx-auto max-w-3xl text-center`;
const Heading = tw.h1`text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl`;
const Subheading = tw.h2`text-base tracking-tight text-gray-600 mt-4`;
const TwoColumn = tw.div`grid grid-cols-1 gap-y-20 gap-x-8 lg:grid-cols-12 mt-16`;
const TextColumn = tw.div`lg:col-span-7`;
const ImageColumn = tw.div`lg:col-span-5`;
const ListBox = tw.div`mt-4 divide-y divide-gray-200 border-t border-b border-gray-200`;
const List = tw.div`relative flex items-start space-x-4 py-6`;
const Link = tw.a`text-lg text-gray-900 hover:text-[#ed1c24]`;
const IconDiv = tw.div`flex-shrink-0`;
const Icon = tw(DocumentTextIcon)`h-6 w-6 text-[#ed1c24]`;
const TextDiv = tw.div`min-w-0 flex-1`;
const Description = tw.div`mt-2 text-sm text-gray-500`;
const description =
  "Please download and fill out the form. Once completed, we will contact you with your application status and any further requirements.";

const items = [
  {
    id: 1,
    src: applicationForm,
    icon: DocumentTextIcon,
    title: "Bicare Loan Application Form",
    description: "Download the application form and fill it out.",
  },
  // {
  //   id: 2,
  //   src: applicationForm,
  //   icon: DocumentTextIcon,
  //   title: "Bicare another form",
  //   description: "anooooooooother description.",
  // },
];

const Form = () => {
  return (
    <Main>
      <head>
        <meta
          name="description"
          content="Please download and fill out the form. Once completed, we will contact you with your application status and any further requirements."
        />
        <meta
          property="og:site_name"
          content="Loan Application Form - Bicare Finance"
        />
        <meta
          property="og:title"
          content="Loan Application Form - Bicare Finance"
        />
        <meta
          property="og:description"
          content="Please download and fill out the form. Once completed, we will contact you with your application status and any further requirements."
        />
        <meta property="og:locale" content="en-NZ" />
        <meta
          name="twitter:title"
          content="Loan Application Form - Bicare Finance"
        />
        <meta
          name="twitter:description"
          content="Please download and fill out the form. Once completed, we will contact you with your application status and any further requirements."
        />
        <title>Loan Application Form - Bicare Finance</title>
        <link rel="canonical" href="https://bicare.co.nz/application-form" />
      </head>
      <Container>
        <HeadingSection>
          <Heading>Loan Application Form</Heading>
          <Subheading>{description}</Subheading>
        </HeadingSection>
        <TwoColumn>
          {/*text*/}
          <TextColumn>
            <ListBox role="list">
              {items.map((item) => (
                <List key={items.id}>
                  <IconDiv>
                    <Icon />
                  </IconDiv>
                  <TextDiv>
                    <div>
                      <Link target="_blank" href={item.src}>
                        {item.title}
                      </Link>
                    </div>
                    <Description>{item.description}</Description>
                  </TextDiv>
                </List>
              ))}
            </ListBox>
          </TextColumn>
          {/*image*/}
          <ImageColumn>
            <Lottie
              className="object-cover object-center"
              animationData={pic}
              loop={true}
              height={400}
              mwidth={400}
            />
          </ImageColumn>
        </TwoColumn>
      </Container>
    </Main>
  );
};
export default Form;
