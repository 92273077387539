import React from "react";
import tw from "twin.macro";
import office1 from "../assets/company/office1.jpg";
import office2 from "../assets/company/office2.jpg";
import award from "../assets/company/award.jpg";
const Main = tw.div`relative`;
const Container = tw.div`mx-auto max-w-2xl py-20 px-4 sm:py-28 sm:px-6 lg:max-w-7xl lg:px-8`;
const TwoColumn = tw.div`grid grid-cols-1 gap-y-20 gap-x-8 lg:grid-cols-12 mt-16`;
const TextColumn = tw.div`lg:col-span-7`;
const ImageColumn = tw.div`lg:col-span-5`;
const HighlightedText = tw.span`text-[#ed1c24]`;
const CompanyCN = () => {
  return (
    <Main>
      <Container>
        <TwoColumn>
          <TextColumn>
            <h1 className="text-4xl text-center sm:text-6xl sm:text-start font-bold px-6">
              关于<HighlightedText>百家信贷</HighlightedText>
            </h1>
            <h2 className="text-gray-500 text-lg py-10 mx-auto px-6">
              <p>
                百家信贷是新西兰金融服务集团（NZFSG）的会员单位，有资深的贷款专家专业为客户提供住宅，商铺，建筑及农场等各类抵押贷款服务。
              </p>
              <br />
              <p>
                我们是独立于银行及贷款机构的专业贷款中介服务公司，能够代表客户，从客户利益出发，帮助客户办理各项抵押贷款业务。
              </p>
              <br />
              <p>
                我们信贷业务网络广泛，同ANZ，ASB，Westpac,
                BNZ等新西兰主要银行及众多的二级金融机构保持良好的合作关系，能根据客户的具体情况为客户选择最为合适的银行或贷款机构。
              </p>
              <br />
              <p>
                我们也能够根据客户的财务状况，分析客户的贷款需求，量体裁衣，帮助客户制定和选择最适合的贷款方案，而不会刻意为银行或贷款机构推销某种金融产品。
              </p>
              <br />
              <p>
                我们与本地主流银行和贷款机构保有快捷的信息沟通渠道，能够及时获取相关银行和贷款机构的各种特价和打折信息，为客户争取最优惠的贷款条件。
              </p>
              <br />
              <p>
                经过十余年团队上下的齐心协力，百家信贷已经为在纽几千户的华人家庭提供专业了贷款服务。
              </p>
              <br />
              <p>
                我们从银行及贷款机构收取佣金，除有特殊约定外，各项服务一律免费！
              </p>
              <br />
              <p>
                感谢您选择百家信贷，我们将竭诚提供最专业的方案，期待能够与您携手共创财务成功！
              </p>
            </h2>
          </TextColumn>
          <ImageColumn>
            <div className="aspect-w-9 aspect-h-5 overflow-hidden rounded-lg bg-gray-100">
              <img
                src={office1}
                alt="百家信贷奥克兰新西兰值得信赖的贷款专家"
                className="h-full w-full object-cover object-center"
                title="Bicare trusted mortgage broker in auckland new zealand."
                loading="lazy"
              />
            </div>
            <div className="mt-4 grid grid-cols-7 gap-4 sm:mt-6 sm:gap-6 lg:mt-8 lg:gap-8">
              <div className="aspect-w-3 aspect-h-4 col-span-4 overflow-hidden rounded-lg bg-gray-100">
                <img
                  src={award}
                  alt="百家信贷获奖奖杯，奥克兰百姓值得信赖的贷款专家"
                  className="h-full w-full object-cover object-center"
                  title="Bicare award winning mortgage broker in auckland new zealand."
                  loading="lazy"
                />
              </div>
              <div className="aspect-w-1 aspect-h-2 col-span-3 overflow-hidden rounded-lg bg-gray-100">
                <img
                  src={office2}
                  alt="百家信贷北岸办公室奥克兰新西兰"
                  className="h-full w-full object-cover object-center"
                  title="Bicare north shore mortgage broker in auckland new zealand."
                  loading="lazy"
                />
              </div>
            </div>
          </ImageColumn>
        </TwoColumn>
      </Container>
    </Main>
  );
};

export default CompanyCN;
