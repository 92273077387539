import React, { useEffect, useState } from "react";
import { BiArrowFromBottom } from "react-icons/bi";

import { classNames } from "./Utils";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 500) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div className="fixed bottom-5 right-5">
      <button
        type="button"
        aria-label="Scroll to top"
        onClick={scrollToTop}
        className={classNames(
          isVisible ? "opacity-75" : "opacity-0",
          "bg-white hover:bg-gray-300 inline-flex items-center rounded-full p-3 shadow-gray-400 shadow transition-opacity"
        )}
      >
        <BiArrowFromBottom
          className="h-6 w-6 fill-gray-500"
          aria-hidden="true"
        />
      </button>
    </div>
  );
};

export default ScrollToTop;
