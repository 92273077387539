import rates from "./rates.json";

// Update image paths to lowercase
const Data = rates.map((item) => {
  return {
    ...item,
    img: require(`../../assets/partner/${item.img}.svg`), // Webpack specific way to require assets
  };
});

export default Data;
