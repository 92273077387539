import React, { useState } from "react";
import Lottie from "lottie-react";
import cookies from "../assets/gdpr/cookies.json";
import { XCircleIcon } from "@heroicons/react/24/outline";

const Cookies = () => {
  const [accepted, setAccepted] = useState(false);

  const handleAccept = () => {
    setAccepted(true);
    localStorage.setItem("cookieAccepted", "true");
  };

  const handleDecline = () => {
    setAccepted(true);
    localStorage.setItem("cookieAccepted", "true");
  };

  const handleClose = () => {
    if (!localStorage.getItem("cookieAccepted")) {
      localStorage.setItem("cookieAccepted", "false");
    }
    setAccepted(true);
  };

  if (accepted || localStorage.getItem("cookieAccepted") === "true") {
    return null;
  }

  return (
    <div className="p-6 sm:p-10 z-100 w-96">
      <div className="fixed bottom-0 right-0 mb-2 mr-2 bg-white sm:w-auto rounded-lg shadow-md p-6 border">
        <div className="flex items-center justify-end -mt-6 -mr-6">
          <button onClick={handleClose} aria-label="Close">
            <XCircleIcon className="h-6 w-6 text-gray-500" />
          </button>
        </div>
        <div className="w-16 mx-auto relative -mt-16">
          <Lottie animationData={cookies} loop={true} height={80} mwidth={80} />
        </div>
        <span className="cookie-consent__message w-full sm:w-72  block font-light leading-normal text-gray-700 text-xs mb-5">
          We care about your data, and we'd love to use cookies to make your
          experience better.
          <br />
          By clicking "Accept", you agree to our use of{" "}
          <a
            className="font-lf text-xs text-[#ed1c24] font-light underline"
            href="/privacy-policy"
          >
            Privacy Policy
          </a>
          .
        </span>
        <div className="flex items-center justify-between">
          <button
            onClick={handleDecline}
            aria-label="Decline"
            className="text-xs rounded-lg inline-block px-6 py-2 text-gray-400 font-lf-bold bg-gray-300 hover:bg-gray-200 text-gray-500 cursor-pointer"
          >
            Decline
          </button>

          <button
            onClick={handleAccept}
            aria-label="Accept"
            className="bg-[#ed1c24] hover:bg-[#ff656b] text-xs rounded-lg inline-block shadow-lg px-6 py-2 text-white font-lf-bold cursor-pointer"
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cookies;
