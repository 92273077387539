import React, { useState } from "react";
import tw from "twin.macro";
import Lottie from "lottie-react";
import time from "../../assets/calculator/time.json";
import borrow from "../../assets/calculator/borrowing.jpg";
import paye from "../../assets/calculator/paye.jpg";
import rates from "../../assets/calculator/rates.jpg";

const Main = tw.div`relative`;
const Container = tw.div`mx-auto max-w-2xl py-24 px-4 sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8`;
const HeadingSection1 = tw.div`max-w-3xl`;
const HeadingSection2 = tw.div`max-w-3xl mt-4`;
const Heading = tw.h1`text-3xl font-bold tracking-tight text-gray-800`;
const Heading2 = tw.h2`flex flex-col text-3xl font-bold text-gray-800 mb-2`;
const HeadingDescription = tw.p`mt-4 text-gray-500`;
const TwoColumn = tw.div`grid grid-cols-1 gap-y-20 gap-x-8 lg:grid-cols-3`;
const TextColumn = tw.div`lg:col-span-2`;
const ImageColumn = tw.div`lg:col-span-1 h-full w-full object-cover object-center`;
const CalculatorSection = tw.div`mx-auto flex flex-col md:flex-row bg-gray-100 mt-8 shadow-xl rounded-2xl`;
const Calculator = tw.div`cursor-pointer flex flex-col border-r border-gray-200 md:w-2/5`;
const CalculatorRow1 = tw.div`relative mt-10 ml-10 mr-10`;
const CalculatorRow2 = tw.div`relative mt-5 ml-10 mr-10`;
const Label = tw.label`text-base text-gray-500`;
const Input = tw.input`w-full rounded-md bg-white rounded border border-gray-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out`;
const Button = tw.button`m-10 text-white bg-[#ed1c24] border-0 py-2 px-6  focus:outline-none rounded-lg text-base transform active:scale-x-90 active:scale-y-90 transition-transform`;
const ValueSection = tw.div`bg-white rounded-r-2xl md:w-3/5`;
const ValueContent = tw.div`flex flex-col justify-center m-5`;
const ValueHeading = tw.div`flex border-b justify-around bg-gray-100 rounded-tr-2xl text-gray-500 h-14 text-center`;
const ValueRow = tw.div`mt-3 mb-12 text-center text-4xl text-gray-800`;
const Value = tw.div`text-center text-8xl text-gray-800 decoration-2`;
const ValueDescription = tw.p`text-xs text-left font-light italic text-gray-500 mt-12 m-5`;
const MoreCalculators = tw.div`mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8`;
const MoreCalculatorsContainer = tw.div`aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-2xl shadow-xl bg-gray-100 group-hover:opacity-75 lg:aspect-none lg:h-80`;
const MoreCalculatorsImage = tw.img`object-cover object-center lg:h-full lg:w-full`;
const H3 = tw.h3`mt-4 text-base text-gray-700 text-center`;

const RepaymentCalculator = () => {
  const [balance, setBalance] = useState("");
  const [rate, setRate] = useState("");
  const [term, setTerm] = useState("");
  const [paymentM, setPaymentM] = useState("$0");
  const [paymentF, setPaymentF] = useState("$0");
  const [paymentW, setPaymentW] = useState("$0");
  const calculateM = (balance, rate, term) => {
    const n = term * 12;
    const r = rate / 100 / 12;
    const numerator = r * (1 + r) ** n;
    const denominator = (1 + r) ** n - 1;
    return parseFloat(balance * (numerator / denominator)).toFixed(0);
  };
  const calculateF = (balance, rate, term) => {
    const n = term * 26;
    const r = rate / 100 / 26;
    const numerator = r * (1 + r) ** n;
    const denominator = (1 + r) ** n - 1;
    return parseFloat(balance * (numerator / denominator)).toFixed(0);
  };
  const calculateW = (balance, rate, term) => {
    const n = term * 52;
    const r = rate / 100 / 52;
    const numerator = r * (1 + r) ** n;
    const denominator = (1 + r) ** n - 1;
    return parseFloat(balance * (numerator / denominator)).toFixed(0);
  };
  const handleClick = (e) => {
    e.preventDefault();

    const paymentW = calculateW(balance, rate, term);
    setPaymentW(`$${paymentW}`);

    const paymentF = calculateF(balance, rate, term);
    setPaymentF(`$${paymentF}`);

    const paymentM = calculateM(balance, rate, term);
    setPaymentM(`$${paymentM}`);
  };

  const data = [
    {
      label: "Weekly",
      value: "Weekly",
      desc: `${paymentW}`,
    },
    {
      label: "Fortnightly",
      value: "Fortnightly",
      desc: `${paymentF}`,
    },
    {
      label: "Monthly",
      value: "Monthly",
      desc: `${paymentM}`,
    },
  ];

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const calculators = [
    {
      id: 1,
      name: "Borrowing Calculator",
      href: "/borrowing-calculator",
      imageSrc: borrow,
      imageAlt:
        "borrowing calculator, how much can I borrow? auckland mortgage broker",
    },
    {
      id: 2,
      name: "PAYE Calculator",
      href: "/paye-calculator",
      imageSrc: paye,
      imageAlt:
        "paye calculator, how much will I take home after tax? auckland mortgage broker",
    },
    {
      id: 3,
      name: "Compare Rates",
      href: "/rates-compare",
      imageSrc: rates,
      imageAlt:
        "compare rates, compare interest rates, auckland mortgage broker",
    },
  ];

  return (
    <Main>
      <head>
        <meta
          name="description"
          content="Estimate how much you could be paying for your repayment mortgage."
        />
        <meta
          property="og:site_name"
          content="Repayment Calculator - Bicare Finance"
        />
        <meta
          property="og:title"
          content="Repayment Calculator - Bicare Finance"
        />
        <meta
          property="og:description"
          content="Estimate how much you could be paying for your repayment mortgage."
        />
        <meta property="og:locale" content="en-NZ" />
        <meta
          name="twitter:title"
          content="Repayment Calculator - Bicare Finance"
        />
        <meta
          name="twitter:description"
          content="Estimate how much you could be paying for your repayment mortgage."
        />
        <title>Repayment Calculator - Bicare Finance</title>
        <link
          rel="canonical"
          href="https://bicare.co.nz/repayment-calculator"
        />
      </head>
      <Container>
        {/*title*/}
        <HeadingSection1>
          <Heading>Repayments Calculator</Heading>
          <HeadingDescription>
            Estimate how much you could be paying for your repayment mortgage.
          </HeadingDescription>
        </HeadingSection1>
        <TwoColumn>
          <TextColumn>
            {/*calculator*/}
            <CalculatorSection>
              <Calculator>
                <CalculatorRow1>
                  <Label>Loan Amount:</Label>
                  <Input
                    type="number"
                    value={balance}
                    onChange={(e) => setBalance(e.target.value)}
                    min="0"
                    placeholder="$"
                  ></Input>
                </CalculatorRow1>
                <CalculatorRow2>
                  <Label>Interest Rate:</Label>
                  <Input
                    value={rate}
                    onChange={(e) => setRate(e.target.value)}
                    type="number"
                    min="0"
                    step="0.01"
                    placeholder="%"
                  ></Input>
                </CalculatorRow2>
                <CalculatorRow2>
                  <Label>Loan Term (years):</Label>
                  <Input
                    value={term}
                    onChange={(e) => setTerm(e.target.value)}
                    type="number"
                    min="0"
                    placeholder="0"
                  ></Input>
                </CalculatorRow2>
                <Button onClick={handleClick}>Calculate</Button>
              </Calculator>
              <ValueSection>
                <ValueHeading>
                  {data.map((label, idx) => {
                    return (
                      <div
                        key={idx}
                        className={`py-4 border-b-4 transition-colors duration-300 ${
                          idx === activeTabIndex
                            ? "border-[#ed1c24] border-b-2"
                            : "border-transparent hover:border-gray-400 hover:scale-105"
                        }`}
                        // Change the active tab on click.
                        onClick={() => setActiveTabIndex(idx)}
                      >
                        {label.label}
                      </div>
                    );
                  })}
                </ValueHeading>
                {/* Show active tab content. */}
                <ValueContent>
                  <ValueRow>Your estimated repayments:</ValueRow>
                  <Value>{data[activeTabIndex].desc}</Value>
                </ValueContent>
                <ValueDescription>
                  This calculator is for information purposes only and does not
                  provide financial advice. We recommend you to talk to one of
                  our experienced financial advisors about your situation and
                  goals before getting a financial product.
                </ValueDescription>
              </ValueSection>
            </CalculatorSection>
          </TextColumn>
          <ImageColumn>
            <Lottie
              animationData={time}
              loop={true}
              height={500}
              mwidth={500}
            />
          </ImageColumn>
        </TwoColumn>
        <HeadingSection2>
          <Heading2>More helpful calculators.</Heading2>
          <HeadingDescription>
            Whether you need a mortgage calculator to work out your borrowing
            power, mortgage repayments or after tax income, we have got your
            covered.
          </HeadingDescription>
        </HeadingSection2>
        <MoreCalculators>
          {calculators.map((calculator) => (
            <a
              key={calculator.id}
              href={calculator.href}
              className="group relative"
            >
              <MoreCalculatorsContainer>
                <MoreCalculatorsImage
                  src={calculator.imageSrc}
                  alt={calculator.imageAlt}
                  title={calculator.name}
                  loading="lazy"
                ></MoreCalculatorsImage>
              </MoreCalculatorsContainer>
              <H3>{calculator.name}</H3>
            </a>
          ))}
        </MoreCalculators>
      </Container>
    </Main>
  );
};
export default RepaymentCalculator;
