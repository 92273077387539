import React from "react";
import advice from "../../assets/services/advice.jpg";
import tw from "twin.macro";

const Main = tw.div`relative`;
const Container = tw.div`mx-auto max-w-2xl py-24 px-4 sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8`;
const HeadingSection = tw.div`mx-auto max-w-3xl text-center`;
const Heading = tw.h1`text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl`;
const SubheadingSection = tw.div`border-b-2 border-gray-200 pb-4`;
const Subheading = tw.h2`text-lg tracking-tight text-gray-900`;
const TwoColumn = tw.div`grid grid-cols-1 gap-y-20 gap-x-8 lg:grid-cols-12 mt-16`;
const TextColumn = tw.div`lg:col-span-7`;
const ImageColumn = tw.div`lg:col-span-5`;
const DescriptionSection = tw.p`pt-6 space-y-2`;
const Description = tw.p`text-base tracking-tight text-gray-600`;
const Image = tw.img`h-full w-full object-cover object-center`;
const Link = tw.a`hover:text-[#ed1c24] underline decoration-solid underline-offset-2`;

const FinancialAdvice = () => {
  return (
    <Main>
      <head>
        <meta
          name="description"
          content="Our team members all hold relevant investment licenses and can provide professional investment advice and services to clients. We can help you evaluate potential investment opportunities and provide appropriate loan options for different investment types and risk levels."
        />
        <meta
          property="og:site_name"
          content="Financial Advice - Bicare Finance"
        />
        <meta property="og:title" content="Financial Advice - Bicare Finance" />
        <meta
          property="og:description"
          content="Our team members all hold relevant investment licenses and can provide professional investment advice and services to clients. We can help you evaluate potential investment opportunities and provide appropriate loan options for different investment types and risk levels."
        />
        <meta property="og:locale" content="en-NZ" />
        <meta
          name="twitter:title"
          content="Financial Advice - Bicare Finance"
        />
        <meta
          name="twitter:description"
          content="Our team members all hold relevant investment licenses and can provide professional investment advice and services to clients. We can help you evaluate potential investment opportunities and provide appropriate loan options for different investment types and risk levels."
        />
        <title>Financial Advice - Bicare Finance</title>
        <link rel="canonical" href="https://bicare.co.nz/financial-advice" />
      </head>
      <Container>
        <HeadingSection>
          <Heading>Professional Property Investment Advices</Heading>
        </HeadingSection>
        <TwoColumn>
          {/*image*/}
          <ImageColumn>
            <div className="aspect-w-7 aspect-h-8 overflow-hidden rounded-lg">
              <Image
                src={advice}
                alt="professional financial advice, investment advice, auckland, new zealand"
                className="h-full w-full object-cover object-center"
                loading="lazy"
                title="professional financial advice"
              />
            </div>
          </ImageColumn>
          {/*text*/}
          <TextColumn>
            <SubheadingSection>
              <Subheading>
                Do you need professional property investment advice?
              </Subheading>
            </SubheadingSection>
            <DescriptionSection>
              <Description>
                As a professional lending institution, Bicare not only has years
                of experience in property investment and a wide network of
                partners, but also provides comprehensive financial services to
                help clients succeed in various fields.
              </Description>
              <Description>
                Our professional property investment advice includes but is not
                limited to the following:
              </Description>
              <Description>
                First, understanding your investment goals and risk tolerance to
                develop the best investment strategy.
              </Description>
              <Description>
                Second, we will provide targeted investment advice based on
                current market trends and future growth prospects.
              </Description>
              <Description>
                Our team members all hold relevant investment licenses and can
                provide professional investment advice and services to clients.
                We can help you evaluate potential investment opportunities and
                provide appropriate loan options for different investment types
                and risk levels.
              </Description>
              <Description>
                In addition, we also provide comprehensive research and analysis
                of the real estate market for clients to ensure that your
                investment decisions are wise and feasible. We can also help you
                understand local laws and regulations to ensure that your
                investments comply with legal requirements.
              </Description>
              <Description>
                If you need more targeted financial advice, please feel free to{" "}
                <Link href="/contact-us">contact</Link> our team members at any
                time.
              </Description>
            </DescriptionSection>
          </TextColumn>
        </TwoColumn>
      </Container>
    </Main>
  );
};
export default FinancialAdvice;
